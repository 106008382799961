<template>
  <v-app>
    <v-row>
      <v-data-table :headers="headers" :items="platformownoffers" :items-per-page="itemsPerPage"
        class="platformownoffers-table" :server-items-length="totalPlatformownoffers" :loading="loading"
        :options.sync="options" locale="de" :footer-props="footerProps" multi-sort
        loading-text="Wird geladen..."
        >
        <template v-slot:header.platformname="{ header }">
          {{ header.text }}
          <v-menu v-model="platformfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="platform ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-autocomplete v-model="platform" :items="platformtemplates" item-text="name" item-value="id"
                label="Auswählen" persistent-hint single-line clearable autofocus
                @click:clear="platform = '', clearFilter()" @keydown.enter.prevent="filter"></v-autocomplete>
              <v-btn text @click="platformfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="platform = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.ARAINR="{ header }">
          {{ header.text }}
          <v-menu v-model="arainrfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="arainr ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="arainr" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                :autofocus="true" @keydown.enter.prevent="filter"></v-text-field>
              <v-btn text @click="arainrfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="arainr = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.ean="{ header }">
          {{ header.text }}
          <v-menu v-model="eanfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="ean ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="ean" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                :autofocus="true" @keydown.enter.prevent="filter"></v-text-field>
              <v-btn text @click="eanfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="ean = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.suppliername="{ header }">
          {{ header.text }}
          <v-menu v-model="lieferantfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="lieferant ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-autocomplete v-model="lieferant" :items="supplierstocktemplates" item-text="supplierName" item-value="id"
                label="Auswählen" persistent-hint single-line @keydown.enter.prevent="filter"></v-autocomplete>
              <v-btn text @click="lieferantfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="lieferant = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.ARWG="{ header }">
          {{ header.text }}
          <v-menu v-model="arwgfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="arwg ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="arwg" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                :autofocus="true" @keydown.enter.prevent="filter"></v-text-field>
              <v-btn text @click="arwgfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="arwg = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.hersteller="{ header }">
          {{ header.text }}
          <v-menu v-model="herstellerfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="hersteller ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="hersteller" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                :autofocus="true" @keydown.enter.prevent="filter"></v-text-field>
              <v-btn text @click="herstellerfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="hersteller = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.product_type="{ header }">
          {{ header.text }}
          <v-menu v-model="producttypefilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="producttype ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="producttype" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                :autofocus="true" @keydown.enter.prevent="filter"></v-text-field>
              <v-btn text @click="producttypefilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="producttype = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.islocked="{ header }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">Preistype</span>
              </template>
              <span>
                Preistypen 
                <v-chip color="cyan" text-color="white" small>
                  L
                </v-chip>
                  : Lieferantenpreis,
                <v-chip color="red" text-color="white" small>
                  S
                </v-chip>
                  : Sperrartikelpreis,
                <v-chip color="blue" text-color="white" small>
                  KB
                </v-chip>
                    : Kein Bestpreis,
                <v-chip color="orange" text-color="white" small>
                  W
                </v-chip>   
                  : Wiederverkaufspreis
              </span>
            </v-tooltip>
          <v-menu v-model="locktypefilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="locktype ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-autocomplete v-model="locktype" :items="locktypes" item-text="name" item-value="value"
                label="Preistype auswählen" persistent-hint single-line @keydown.enter.prevent="filter" @change="filter"></v-autocomplete>
              <v-btn text @click="locktypefilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                   <v-btn @click="locktype = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                      <v-icon dark>
                        mdi-database-search
                      </v-icon>
                    </v-btn>
                  </span>
                </template> 
                <span>Suchen</span>
              </v-tooltip>
            </div>
          </v-menu>
        </template>
        <template v-slot:item.ownprice="{ item }">
          <v-chip color="cyan" text-color="white" small>
            {{ convertDoubleToCurrency(item.ownprice) }}
          </v-chip>
        </template>
        <template v-slot:item.minprice="{ item }">
          <v-chip color="orange" text-color="white" small>
            {{ convertDoubleToCurrency(item.minprice) }}
          </v-chip>
        </template>
        <template v-slot:item.platformbestprice="{ item }">
          <v-chip color="blue" text-color="white" small>
            {{ convertDoubleToCurrency(item.platformbestprice) }}
          </v-chip>
        </template>
        <template v-slot:item.ownoffer="{ item }">
          <v-chip :color="getColor(item.ispriceexceeded)" text-color="white" small>
            {{ convertDoubleToCurrency(item.ownoffer) }}
          </v-chip>
        </template>
        <template v-slot:item.ispriceexceeded="{ item }">
          <v-icon small color='green' v-if="item.ispriceexceeded == 0">
            mdi-check-circle
          </v-icon>
          <v-icon small color='red' v-if="item.ispriceexceeded == 1">
            mdi-alert
          </v-icon>
        </template>
        <template v-slot:item.platformcharges="{ item }">
          {{ convertDoubleToCurrency(item.platformcharges) }}
        </template>
        <template v-slot:item.shippingcharge="{ item }">
          {{ convertDoubleToCurrency(item.shippingcharge) }}
        </template>
        <template v-slot:item.platformchargewithoutshipping="{ item }">
            {{ convertDoubleToCurrency(item.platformchargewithoutshipping) }}
        </template>
        <template v-slot:item.priceprofit="{ item }">
            {{ convertDoubleToCurrency(item.priceprofit) }}
        </template>
        <template v-slot:item.pricepercentage="{ item }">
            {{ convertDoubleToCurrency(item.pricepercentage) }}
        </template>
        <template v-slot:item.pricemarkdown="{ item }">
            {{ convertDoubleToCurrency(item.pricemarkdown) }}
        </template>
        <template v-slot:item.islocked="{ item }">
          <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-chip color="cyan" text-color="white" small v-if="item.islocked == 0 && item.locktype !== null && item.locktype.startsWith('LIEFERANTENPREIS')" >
                    L
                  </v-chip>
                  <v-chip color="blue" text-color="white" small v-if="item.islocked == 0 && item.locktype !== null && item.locktype.startsWith('KEINBESTPREIS')">
                    KB
                  </v-chip>
                  <v-chip color="red" text-color="white" small v-if="item.islocked == 1 && item.locktype !== null  && item.locktype.startsWith('SPERRARTIKEL')">
                    S
                  </v-chip>
                  <v-chip color="orange" text-color="white" small v-if="item.islocked == 0 && item.locktype !== null && item.locktype.startsWith('WIEDERVERKAUFSPREIS')">
                    W
                  </v-chip>
                </span>
              </template>
              <span>{{ item.locktype }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    value: Array,
    editdata: null,
    url: {
      type: String,
      default: '/admin/platformownoffers'
    },
    location_url: {
      type: String,
      default: '/admin/platformownoffers/'
    },
    callback: {
      type: Function,
      default: () => ({})
    },
    catch: {
      type: Function,
      default: () => ({})
    },
    finally: {
      type: Function,
      default: () => ({})
    },

    submitBtnText: {
      type: String,
      default: "Speichern"
    },
    buttonClass: {
      type: String,
      default: "btn btn-primary"
    },
    inputClass: {
      type: String,
      default: "form-control-file"
    },
    validation: {
      type: Boolean,
      default: true,
    },

    tableSelectClass: {
      type: String,
      default: 'form-control'
    },
  },

  data: () => ({
    disableOk: false,
    downloading: false,
    platformtemplates: [],
    supplierstocktemplates: [],
    platformownoffers: [],
    totalPlatformownoffers: 0,
    itemsPerPage: 50,
    loading: true,
    conditions: [],
    filters: [],
    options: {
      page: 1,
      itemsPerPage: 50,
      sortDesc: [false],
      mustSort: false,
      multiSort: false,
      sort: '',
      filters: null
    },
    platform: '',
    arainr: '',
    ean: '',
    lieferant: '',
    hersteller: '',
    arwg: '',
    producttype: '',
    locktype: '',
    platformfilter: false,
    arainrfilter: false,
    eanfilter: false,
    lieferantfilter: false,
    arwgfilter: false,
    herstellerfilter: false,
    producttypefilter: false,
    locktypefilter:false,
    locktypes: [
      { name:'Lieferantenpreis', value:'LIEFERANTENPREIS'},
      { name: 'Sperrartikelpreis', value: 'SPERRARTIKEL' },
      { name: 'Kein Bestpreis', value: 'KEINBESTPREIS' },
      { name: 'Wiederverkaufspreis', value: 'WIEDERVERKAUFSPREIS' }
    ],
    headers: [
      { text: 'id', value: 'id' },
      { text: 'Plattform', value: 'platformname' },
      { text: 'Lieferant', value: 'suppliername' },
      { text: 'ARAINR', value: 'ARAINR' },
      { text: 'EAN', value: 'ean' },
      { text: 'ARWG', value: 'ARWG' },
      { text: 'Hersteller', value: 'hersteller' },
      { text: 'Produkt', value: 'product_type' },
      { text: 'Min.Menge', value: 'minstock' },
      { text: 'Bestand', value: 'ownstock' },
      { text: 'Einkaufspreis', value: 'ownprice' },
      { text: 'Fracht', value: 'shippingcharge' },
      { text: 'Gebühren', value: 'onlyplatformcharge' },
      { text: 'Gewinn', value: 'priceprofit' },
       { text: 'Aufschlag gesamt', value: 'platformcharges' },
      { text: 'Min.Preis', value: 'minprice' },
      { text: 'Bestpreis', value: 'platformbestprice' },
      { text: 'Prozentsatz', value: 'pricepercentage' },
      { text: 'Abschlag', value: 'pricemarkdown' },
      { text: 'Angebot', value: 'ownoffer' },
      { text: 'Preistype', value: 'islocked' },
      { text: 'Günstiger bieten', value: 'ispriceexceeded' },
      { text: 'Vergliechen am', value: 'comparedate' },
      { text: 'Aktualiesiert am', value: 'updateddate' },
      { text: 'Gesendet am', value: 'senddate' },
      // { text: 'Vergl. Platform', value: 'compplatformtemplate.name' },
      // { text: 'Vergl. Lieferantenpos.', value: 'compsupplierposition' },
      // { text: 'Vergl. Lieferantsnr.', value: 'compsuppliernumber' },
      // { text: 'Vergl. Bestand', value: 'compsupplierstock' },
      // { text: 'Vergl. Angebot', value: 'compsupplierprice' },
      // { text: 'Vergl. Angebotsabschlag', value: 'compsupplierpricemarkdown' },
      // { text: 'Vergl. Land', value: 'compsuppliercountry' },
      // { text: 'Abh. Platform', value: 'depplatformtemplate.name' },
      // { text: 'Abh. Lieferantenpos.', value: 'depsupplierposition' },
      // { text: 'Abh. Lieferantennr.', value: 'depsuppliernumber' },
      // { text: 'Abh. Bestand', value: 'depsupplierstock' },
      // { text: 'Abh. Angebot', value: 'depsupplierprice' },
    ],
    footerProps: {
      'items-per-page-options': [50, 500, 1000, -1],
      'show-current-page': true,
      'show-first-last-page': true,
      disableItemsPerPage: false,
      itemsPerPageText: 'Einträge pro Seite',
      itemsPerPageAllText: 'Alle',
    }
  }),
  created() {
    this.filters = new Array();
    this.getPlatformtemplates();
    // this.getSupplierStockTemplates();
  },
  mounted() {
    window.platformownoffers = this;
  },
  methods: {
    async clearFilter() {
      this.filter();
    },
    convertDoubleToCurrency(value) {
      return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
    },
    getLocktypePrice(locktype) {
      let str = locktype.split(' ');
      //Object.is(st.length, null)
      return  str.length == 2 ? str[1] : '';
    },
    async getPlatformtemplates() {
      axios.get('/admin/getallplatformtemplates')
        .then(function (response) {
          this.platformtemplates = response.data;
        }.bind(this));
    },
    async getSupplierStockTemplates() {
      axios.get('/admin/getplatformownofferssuppliers/', { params: this.options })
        .then(function (response) {
          this.supplierstocktemplates = response.data;
        }.bind(this));
    },
    async getPlatformOwnOffers() {
      this.loading = true
      this.options.sort = this.options.sortBy[0];
      axios.get('/admin/getallplatformownoffers/', { params: this.options })
        .then(function (response) {
          this.platformownoffers = response.data.data;
          this.totalPlatformownoffers = response.data.total
          this.itemsPerPage = response.data.per_page
          this.loading = false
        }.bind(this));
      this.getSupplierStockTemplates();
    },
    getColor(value) {
      if (value > 0) return "red"
      else return "green"
    },
    showMsgBoxOne(callback, change) {
      this.$bvModal.msgBoxOk(callback.data)
        .then(value => {
          if (change) {
            window.location = location.protocol + "//" + location.hostname + this.location_url;
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      //this.nameState = valid
      return valid
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },

    filter() {
      this.filters.length = 0;
      if (this.arainr) {
        this.filters.push(new Array('tplatformownoffers.ARAINR', 'like', this.arainr));
      }
      if (this.ean) {
        this.filters.push(new Array('tplatformownoffers.ean', '=', this.ean));
      }
      if (this.platform) {
        this.filters.push(new Array('platformtemplate_id', '=', this.platform));
      }
      if (this.lieferant) {
        this.filters.push(new Array('tplatformownoffers.suppliername', 'like', '%'+ this.lieferant + '%'));
      }
      if (this.hersteller) {
        this.filters.push(new Array('hersteller', 'like', '%'+ this.hersteller + '%'));
      }
      if (this.arwg) {
        this.filters.push(new Array('tplatformownoffers.ARWG', '=', this.arwg));
      }
      if (this.producttype) {
        this.filters.push(new Array('tplatformownoffers.product_type', 'like', '%' + this.producttype + '%'));
      }
      if(this.locktype) {
        this.filters.push(new Array('tplatformownoffers.locktype', 'like', '%' + this.locktype + '%'));
      }
      this.options.filters = this.filters;
      this.getPlatformOwnOffers();
    }
  },
  watch: {
    downloading(val) {
      if (!val) return
        setTimeout(() => (this.downloading = false), 2000)
    },
    options(newValue, oldValue) {
      let cont = false
      if (oldValue.page !== newValue.page) cont = true
      if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
      if (oldValue.sortBy !== newValue.sortBy) cont = true
      if (oldValue.sortDesc !== newValue.sortDesc) cont = true
      if (cont) {
        this.getPlatformOwnOffers();
      }
    },
  },
};
</script>
