export const func = {
  
    getValueTypeOptions: () => {
        return [{ key: 'Preis', type: 'Preis' }, { key: 'Prozent', type: 'Prozent' },];
    },
    getSurchargeTypeOptions: () => {
        return [{ key: 0, type: 'Ohne Zeitfenster' }, { key: 1, type: 'Mit Zeitfenster' },];
    },
    getSurchargeUpdateTypeOptions: () => {
        return [{ key: 0, type: 'Manual' }, { key: 1, type: 'Automatisch' },];
    },

    getSupplierTypeOptions: () => {
        return [{ value: 0, text: 'Großhändler' }, { value: 1, text: 'Hersteller' }, { value: 2, text: 'Platform' }];
    }
}