<template>
    <p>{{ convertDoubleToCurrency() }}</p>
</template>

<script>
export default {
    props: ['value'],
    emits: ['update:value'],

    data: () => ({
        convertedvalue: 0
    }),

    created() {
        this.convertDoubleToCurrency();
    },
    methods: {
        convertDoubleToCurrency() {
            return this.value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.value);
        },
    },
}
</script>
