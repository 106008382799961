<template>
    <div class="row">
        <div class="card-body table-responsive">
            <table class="table table-bordered table-striped supplierstockmindestpreis-table"  width="100%" cellspacing="0">
                <thead>
                <tr>
                    <th>id</th>
                    <th>Lieferantenname</th>
                     <th>Lieferantennr.</th>
                    <th>EAN</th>
                    <th>Warengruppe</th>
                    <th>Einkaufspreis</th>
                    <th>Menge</th>
                    <th>tyresInParcel</th>
                    <th>Versandlandcode</th>
                    <th>Versandland</th>
                    <th>Fracht</th>
                    <th>Mindestpreis</th>
                    <th>Gültigkeit</th>
                    <th>Erstellt</th>
                    <th>Aktionen</th>
                </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            value: Array,
            editdata: null,
        },
        data: () => ({
            url: '/admin/getsupplierstockmindestpreise',
        }),
        created() {
            this.getAllMindestpreise(this.url)
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods:{
             async getAllMindestpreise(address) {
                $(document).ready(function() {
                    var collapsedGroups = {};
                var groupColumn = 1;
                var table = $('.supplierstockmindestpreis-table').DataTable({
                    processing: true,
                    serverSide: true,
                    searching: true,
                    searchable:true,
                    responsive: true,
                    columnDefs: [
                        { visible: true, targets: groupColumn }
                    ],
                    order: [[ groupColumn, 'asc' ]],
                    language: {
                        url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                    },

                    /*
                   drawCallback: function ( settings ) {
                    var api = this.api();
                    var rows = api.rows( {page:'current'} ).nodes();
                    var last=null;

                        api.column(groupColumn, {page:'current'} ).data().each( function ( group, i ) {
                            if ( last !== group ) {
                                $(rows).eq( i ).before(
                                    '<tr class="group"><td colspan="7" style="background: #FFF8DC; color: blue;">Kunde: <b>'+group+ ' (' + rows.count() + ')</b></td></tr>'
                                );
                                last = group;
                            }
                        } );
                    },
    */

                    ajax: address,
                    columns: [
                        { data: 'id', name: 'id' },
                        { data: 'supplierName', name: 'supplierName'},
                        { data: 'supplierNumber', name: 'supplierNumber' },
                        { data: 'EAN', name: 'EAN' },
                        { data: 'warengruppe', name: 'warengruppe', width: '5%'  },
                        { data: 'einkaufspreis', name: 'einkaufspreis', width: '2%' },
                        { data: 'menge', name: 'Menge' },
                        { data: 'tyresInParcel', name: 'tyresInParcel' },
                        { data: 'versandlandcode', name: 'versandlandcode' },
                        { data: 'versandland', name: 'versandland' },
                        { data: 'versandkosten', name: 'versandkosten' },
                        { data: 'mindestpreis', name: 'mindestpreis' },
                        { data: 'validitytime', name: 'validitytime' },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'action', name: 'Aktionen', orderable: false, searchable: false }
                    ],
                    rowGroup: {
                    // Uses the 'row group' plugin
                    dataSrc: "supplierName",
                        startRender: function(rows, group) {
                            var collapsed = !!collapsedGroups[group];
                            rows.nodes().each(function(r) {
                                r.style.display = 'none';
                                if (collapsed) {
                                    r.style.display = '';
                                }
                            });

                            return $('<tr/>')
                            .append('<td colspan="8" style="background: #FFF8DC; color: blue;">' + group + '</td>')
                            .attr('data-name', group)
                            .toggleClass('collapsed', collapsed);
                        }
                    }
                });

                    $('.supplierstockmindestpreis-table tbody').on( 'click', 'tr.dtrg-group', function () {
                            var name = $(this).data('name');
                            collapsedGroups[name] = !collapsedGroups[name];
                            table.draw(false);

                    } );

                });
            },
        },
    }
</script>
