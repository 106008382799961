<template>
    <div class="row">
        <!--b-container fluid -->
        <b-form-group label="Versanddienst:" label-for="providerName-input" label-cols="4" label-cols-lg="4"
            invalid-feedback="Versanddienst ist pflicht">
            <b-form-input id="providerName-input" v-model="editdata.versanddienst" required readonly></b-form-input>

        </b-form-group>
        <!--b-button id="new-input" class="float-right" variant="success" v-b-tooltip.hover title="Neuen Versandkostenzuschlag hinzufügen" v-b-modal.modal-prevent-closing><i class="fa fa-plus-circle"></i> </b-button -->
        <b-form-group label=" " label-for="new-input" label-cols="1" label-cols-lg="1" class="float-right">
            <b-button pill id="autoupdate-btn" variant="success" v-b-tooltip.hover
                title="Automatische Aktualisierungen ausführen" @click="handleAutoUpdate"
                v-bind:disabled="disableAutoupdateBtn">
                <i class="fas fa-sync" v-if="!disableAutoupdateBtn"></i>
                <b-spinner small v-if="disableAutoupdateBtn"></b-spinner>
            </b-button>
        </b-form-group>
        <!--/b-container -->
        <b-modal id="modal-prevent-closing" :header-bg-variant="headerBgVariant" :header-text-variant="headerTextVariant"
            ref="modal" :title="modalname" @show="resetModal" @hidden="resetModal" @ok="handleOk" ok-title-html="Speichern"
            cancel-title="Abbrechen" :ok-disabled="disableOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Versanddienst" label-for="versanddienst-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Versanddienst ist pflicht">
                    <b-form-input id="versanddienst-input" v-model="editdata.versanddienst" required
                        readonly></b-form-input>
                </b-form-group>
                <b-form-group label="Zuschlag" label-for="name-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Zuschlag ist pflicht">
                    <b-form-input id="name-input" v-model="shippingservicesurchage.name" :state="nameState"
                        required></b-form-input>
                </b-form-group>
                <b-form-group label="Produkt-Typ" label-for="ptype-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Produkt-Typ ist pflicht">
                    <b-form-select id="ptype-input" v-model="shippingservicesurchage.producttype"
                        :options="producttypeoptions" value-field="name" text-field="name" disabled-field="notEnabled"
                        :state="producttypeState" required></b-form-select>
                </b-form-group>
                <b-form-group label="Land" label-for="country-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Land ist pflicht">
                    <b-form-select id="country-input" v-model="shippingservicesurchage.countrycode"
                        :options="countryoptions" value-field="code" text-field="country" disabled-field="notEnabled"
                        v-on:change="getCountryname" required></b-form-select>
                </b-form-group>
                <b-form-group label="Werttype" label-for="type-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Werttype ist pflicht">
                    <b-form-select id="type-input" v-model="shippingservicesurchage.type"
                        :options="$func.getValueTypeOptions()" value-field="key" text-field="type"
                        disabled-field="notEnabled"></b-form-select>
                </b-form-group>
                <b-form-group label="Wert" label-for="value-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Der Wert ist pflicht">
                    <b-form-input id="value-input" v-model="shippingservicesurchage.value" type="number" min="0" value="0"
                        step="0.01" pattern="[0-9]+([\\,|\\.][0-9]+)?" required></b-form-input>
                </b-form-group>
                <b-form-group label="Type" label-for="type-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Type des Preisaufschlages ist pflicht">
                    <b-form-select id="type-input" v-model="shippingservicesurchage.surchargetype"
                        :options="$func.getSurchargeTypeOptions()" value-field="key" text-field="type"
                        disabled-field="notEnabled"></b-form-select>
                </b-form-group>
                <b-form-group label="Startdatum" label-for="surchargestartdate-input" label-cols="8" label-cols-lg="4"
                    v-if="shippingservicesurchage.surchargetype == 1">
                    <b-form-input id="surchargestartdate-input" v-model="shippingservicesurchage.surchargestartdate"
                        type="date"></b-form-input>
                </b-form-group>
                <b-form-group label="Enddatum" label-for="surchargestopdate-input" label-cols="8" label-cols-lg="4"
                    v-if="shippingservicesurchage.surchargetype == 1">
                    <b-form-input id="surchargestopdate-input" v-model="shippingservicesurchage.surchargestopdate"
                        type="date"></b-form-input>
                </b-form-group>
                <b-form-group label="Aktualisierung" label-for="type-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Type des Preisaufschlages ist pflicht">
                    <b-form-select id="type-input" v-model="shippingservicesurchage.updatetype"
                        :options="$func.getSurchargeUpdateTypeOptions()" value-field="key" text-field="type"
                        disabled-field="notEnabled"></b-form-select>
                </b-form-group>
                <b-form-group label="Beschreibung" label-for="description-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Name is required">
                    <b-form-input id="description-input" v-model="shippingservicesurchage.description"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped shippingservicesurcharge-table" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Name</th>
                        <th>Produkt-Type</th>
                        <th>Landcode</th>
                        <th>Land</th>
                        <th>Werttype</th>
                        <th>Wert</th>
                        <th>Type</th>
                        <th>Startdatum</th>
                        <th>Endatum</th>
                        <th>Aktualisierung</th>
                        <th>Beschreibung</th>
                        <th>Aktualisiert am</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { drop, every, forEach, get, isArray, map, set } from 'lodash';

export default {

    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/shippingservicesurcharges'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        modalname: "Zuschlag hinzufügen",
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        disableOk: true,
        enableSubmit: false,
        countryoptions: {},
        editshippingservicesurchage: {},
        disableAutoupdateBtn: false,
        shippingservicesurchage: {
            id: 0,
            shippingserviceprovider_id: 0,
            name: null,
            producttype: 'Reifen',
            country: '',
            countrycode: 'DE',
            type: '',
            value: 0,
            surchargetype: 0,
            surchargestartdate: null,
            surchargestopdate: null,
            description: null,
            updatetype: 0,
        },
        producttypeoptions: [],
    }),

    created() {
        this.getShippingPackagingTypes();
        this.getCountries();
        this.shippingservicesurchage.shippingserviceprovider_id = this.editdata.id;
        this.getShippingServiceSurcharges('/admin/shippingservicesurcharges/getshippingservicesurcharges/' + this.editdata.id);
    },
    mounted() {
        window.surcharge = this;
    },
    methods: {
        getShippingPackagingTypes: function () {
            axios.get('/admin/getshippingpackagingtypes')
                .then(function (response) {
                    this.producttypeoptions = response.data;
                }.bind(this));
        },
        async getShippingServiceSurcharges(address) {
            $(document).ready(function () {
                var collapsedGroups = {};
                var table = $('.shippingservicesurcharge-table').DataTable({
                    processing: false,
                    serverSide: true,
                    searching: true,
                    searchable: true,
                    responsive: true,
                    select: true,
                    pageLength: 50,
                    language: {
                        url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                    },
                    ajax: address,
                    columns: [
                        { data: 'id', name: 'id' },
                        { data: 'name', name: 'name' },
                        { data: 'producttype', name: 'producttype' },
                        { data: 'countrycode', name: 'countrycode' },
                        { data: 'country', name: 'country' },
                        { data: 'type', name: 'type' },
                        { data: 'value', name: 'value' },
                        { data: 'surchargetype', name: 'surchargetype' },
                        { data: 'surchargestartdate', name: 'surchargestartdate' },
                        { data: 'surchargestopdate', name: 'surchargestopdate' },
                        { data: 'updatetype', name: 'Aktualisierung' },
                        { data: 'description', name: 'Beschreibung' },
                        { data: 'updated_at', name: 'updated_at' },
                        { data: 'LastAction', name: 'LastAction' },
                        { data: 'action', name: 'Aktionen' },
                    ],
                    //order: [[ 4, 'asc' ]],
                });
                $('.shippingservicesurcharge-table tbody').on('click', 'tr.dtrg-group', function () {
                    var name = $(this).data('name');
                    collapsedGroups[name] = !collapsedGroups[name];
                    table.draw(false);
                });
            });
        },
        getCountryname: function (countrycode) {
            var item = this.countryoptions.find(item => item.code === countrycode);
            this.shippingservicesurchage.country = item.country;
        },
        getCountries: function () {
            axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
        },
        getEditSurcharge(edit_id) {
            axios.get('/admin/shippingservicesurcharges/' + edit_id + '/edit')
                .then(function (response) {
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Zuschlag bearbeiten"
                    this.shippingservicesurchage = response.data;
                    this.headerBgVariant = 'primary';
                    console.log(this.shippingservicesurchage);
                    // this.$root.$emit("bv::show::modal", "your-modal-id");
                }.bind(this));
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + "/admin/shippingserviceprovider/getshippingservicesurcharges/" + this.editdata.id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        getDeleteSurcharge(edit_id) {
            const _this = this;
            console.log("Delete id: " + edit_id);
            this.$bvModal.msgBoxConfirm('dass Sie den Versandzuschlag wirklich löschen möchten?', {
                title: 'Bitte bestätigen Sie',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        axios.delete(this.url + '/' + edit_id
                        ).then(response => {
                            // _this.callback(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        resetModal() {
            this.headerBgVariant = 'success';
            this.modalname = "Neuen Zuschlag hinzufügen";
            this.shippingservicesurchage.id = 0;
            this.shippingservicesurchage.name = null;
            this.shippingservicesurchage.value = 0;
            this.shippingservicesurchage.type = 0;
            this.shippingservicesurchage.country = '',
                this.shippingservicesurchage.countrycode = 'DE',
                this.shippingservicesurchage.surchargetype = 0;
            this.shippingservicesurchage.surchargestartdate = null;
            this.shippingservicesurchage.surchargestopdate = null;
            this.shippingservicesurchage.description = null;
            this.shippingservicesurchage.updatetype = 0;
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            const _this = this;
            if (this.shippingservicesurchage.id > 0) {
                axios.put(this.url + '/' + this.shippingservicesurchage.id,
                    this.shippingservicesurchage,
                ).then(response => {
                    // _this.callback(response);
                    console.log(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.shippingservicesurchage,
                ).then(response => {
                    // _this.callback(response);
                    console.log(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }

            // Hide the modal manually
            this.$nextTick(() => {
                this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        handleAutoUpdate() {
            const _this = this;
            _this.disableAutoupdateBtn = true;
            axios.get('/admin/shippingservicesurcharges/' + this.editdata.versanddienst + '/autoupdate',
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                console.log("Fehler");
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
                _this.disableAutoupdateBtn = false;
            });
        }
    },
    watch: {

    },
    computed: {
        nameState() {
            if (this.shippingservicesurchage.name == null || this.shippingservicesurchage.name == "") {
                this.disableOk = true;
                return false;
            }
            this.disableOk = false;
            return true;
        },
        producttypeState() {
            return (this.shippingservicesurchage.producttype == null || this.shippingservicesurchage.producttype == "") ? false : true;
        },
    },
};
</script>
