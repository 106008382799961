var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-row',[_c('v-data-table',{staticClass:"suppliermanualpurchasingprices-table",attrs:{"headers":_vm.headers,"items":_vm.suppliermanualpurchasingprices,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.total,"loading":_vm.loading,"options":_vm.options,"locale":"de","footer-props":_vm.footerProps,"multi-sort":"","group-by":"ARAINR"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":headers.length}},[_c('v-icon',{on:{"click":toggle}},[_vm._v("\n                    "+_vm._s(isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down')+"\n                ")]),_vm._v("\n                "+_vm._s("ARAINR: "+items[0].ARAINR)+"\n                ")],1)]}},{key:"header.ARWG",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arwg ? 'primary' : ''}},[_vm._v("\n                                mdi-filter\n                            ")])],1)]}}],null,true),model:{value:(_vm.arwgfilter),callback:function ($$v) {_vm.arwgfilter=$$v},expression:"arwgfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arwg),callback:function ($$v) {_vm.arwg=$$v},expression:"arwg"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arwgfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-close\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arwg = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-filter-off\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-database-search\n                            ")])],1)],1)])]}},{key:"header.AREAN",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arean ? 'primary' : ''}},[_vm._v("\n                                mdi-filter\n                            ")])],1)]}}],null,true),model:{value:(_vm.areanfilter),callback:function ($$v) {_vm.areanfilter=$$v},expression:"areanfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arean),callback:function ($$v) {_vm.arean=$$v},expression:"arean"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.areanfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-close\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arean = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-filter-off\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-database-search\n                            ")])],1)],1)])]}},{key:"header.AINR",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arainr ? 'primary' : ''}},[_vm._v("\n                                mdi-filter\n                            ")])],1)]}}],null,true),model:{value:(_vm.arainrfilter),callback:function ($$v) {_vm.arainrfilter=$$v},expression:"arainrfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arainr),callback:function ($$v) {_vm.arainr=$$v},expression:"arainr"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arainrfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-close\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arainr = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-filter-off\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-database-search\n                            ")])],1)],1)])]}},{key:"item.ARHKE",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.ARHKE == null ? "" : item.ARHKE.replace(".", ","))+"\n            ")]}},{key:"item.ARDEL",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.ARDEL == null ? "" : item.ARDEL.replace(".", ","))+"\n            ")]}},{key:"item.ARGW",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.ARGW))+"\n            ")]}},{key:"item.shippingcharge",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.shippingcharge))+"\n            ")]}},{key:"item.T24_PRICE",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                 "+_vm._s(_vm.convertDoubleToCurrency(item.T24_PRICE))+"\n            ")]}},{key:"item.T24_SURCHARGES",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.T24_SURCHARGES))+"\n            ")]}},{key:"item.T100_PRICE",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.T100_PRICE))+"\n            ")]}},{key:"item.T100_SURCHARGES",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.T100_SURCHARGES))+"\n            ")]}},{key:"item.GETTYGO_PRICE",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.GETTYGO_PRICE))+"\n            ")]}},{key:"item.GETTYGO_SURCHARGES",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.GETTYGO_SURCHARGES))+"\n            ")]}},{key:"item.MIN_BESTPRICE",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.MIN_BESTPRICE))+"\n            ")]}},{key:"item.MAX_SURCHARGES",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.MAX_SURCHARGES))+"\n            ")]}},{key:"item.artikelmanualpurchaseprice",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.artikelmanualpurchaseprice))+"\n            ")]}},{key:"item.imported_artikelpurchasingprice",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.artikelmanualpurchaseprice > 0 ? item.imported_artikelpurchasingprice : '')+"\n            ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }