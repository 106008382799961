<template>
    <div class="form">
        <b-form-group role="form-group">
            <label for="input-name">Name:</label>
            <b-form-input
            id="input-name"
            v-model="platformexportfilesendconfig.name"
            :state="nameState"
            aria-describedby="input-name-help input-name-feedback"
            placeholder="Name"
            trim
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback">
                Erforderlich
            </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group role="form-group">
            <label for="input-name">Type:</label>
            <b-form-select
                v-model="platformexportfilesendconfig.targettype"
                :options="platformsendtypes"
                value-field="id"
                text-field="name"
                disabled-field="notEnabled"
                required
                ></b-form-select>
        </b-form-group>        
        <b-form-group role="form-group" v-if="platformexportfilesendconfig.targettype < 3">
            <label for="input-targetaddress">FTP-Adresse:</label>
            <!--label for="input-targetaddress" v-else-if="platformexportfilesendconfig.targettype == 3">E-Mail:</label -->
            <b-form-input
            id="input-targetaddress"
            v-model="platformexportfilesendconfig.targetaddress"
            :state="targetaddressState"
            aria-describedby="input-targetaddress-help input-targetaddress-feedback"
            trim
            ></b-form-input>
            <b-form-invalid-feedback id="input-targetaddress-feedback">
                Erforderlich
            </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group role="form-group" v-if="platformexportfilesendconfig.targettype < 3">
            <label for="input-targetpath">FTP-Dateipfad:</label>
            <b-form-input
            id="input-targetpath"
            v-model="platformexportfilesendconfig.targetpath"
            aria-describedby="input-targetpath-help input-targetpath-feedback"
            placeholder="Ziel-Dateipfad"
            trim
            ></b-form-input>
        </b-form-group>
        <b-form-group role="form-group">
            <label for="input-targetfilename">Dateiname:</label>
            <b-form-input
            id="input-targetfilename"
            v-model="platformexportfilesendconfig.targetfilename"
            :state="targetfilenameState"
            aria-describedby="input-targetfilename-help input-targetfilename-feedback"
            placeholder="Dateiname"
            trim
            ></b-form-input>
            <b-form-invalid-feedback id="input-targetfilename-feedback">
                Erforderlich
            </b-form-invalid-feedback>
        </b-form-group >
        <b-form-group role="form-group" v-if="platformexportfilesendconfig.targettype < 3">
            <label for="input-targetusername">FTP-Benutzername:</label>
            <b-form-input
            id="input-targetusername"
            v-model="platformexportfilesendconfig.targetusername"
            :state="targetusernameState"
            aria-describedby="input-targetusername-help input-targetusername-feedback"
            placeholder="Benutzername"
            trim
            ></b-form-input>
            <b-form-invalid-feedback id="input-targetusername-feedback">
                Erforderlich
            </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group role="form-group" v-if="platformexportfilesendconfig.targettype < 3">
            <label for="input-targetpassword">FTP-Password:</label>
            <b-form-input
            id="input-targetpassword"
            v-model="platformexportfilesendconfig.targetpassword"
            :state="targetpasswordState"
            aria-describedby="input-targetpassword-help input-targetpassword-feedback"
            placeholder="Password"
            trim
            ></b-form-input>
            <b-form-invalid-feedback id="input-targetpassword-feedback">
                Erforderlich
            </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id="input-group-userandomstocks"
            label="Zufallsbestände anwenden:"
            label-for="checkbox-userandomstocks"
            description=""
            style="margin-top: 10px;"
        >
            <b-form-checkbox v-model="platformexportfilesendconfig.userandomstocks" name="check-userandomstocks" switch value="1"
                unchecked-value="0">
             Die Zufallsbestände werden {{ platformexportfilesendconfig.userandomstocks ? "angewendet" : "nicht angewendet!" }}
            </b-form-checkbox>
        </b-form-group>
        <b-form-group
            id="input-group-maxmainstock"
            label="Maximaler Hauptbestand:"
            label-for="input-maxmainstock"
            description="Maximaler Bestand des Hauplieferantes Inter-Drive."
            style="margin-top: 10px;"
        >
            <!-- <label for="input-maxmainstock">Maximaler Bestand des Hauplieferantes Inter-Drive:</label> -->
            <b-form-input
            id="input-maxmainstock"
            v-model="platformexportfilesendconfig.maxmainstock"
            aria-describedby="input-maxmainstock-help input-maxmainstock-feedback"
            placeholder="Maximaler Hauptbestand"
            :state="maxmainstockState"
            trim
            type="number"
            min=1
            number
            class="mb-2 mr-sm-2 mb-sm-0"
            required
            ></b-form-input>
            <b-form-invalid-feedback id="input-maxmainstock-feedback">
                    Erforderlich
            </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
        id="input-group-desc"
        label="Beschreibung:"
        label-for="input-desc"
        description=""
        style="margin-top: 10px;"
        >
            <b-form-input
            id="input-desc"
            v-model="platformexportfilesendconfig.description"
            placeholder="Beschreibung"
            trim
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-lastaction" label="Status:" label-for="input-desc" description="" style="margin-top: 10px;">
            <b-form-select v-model="platformexportfilesendconfig.LastAction" :options="lastActionOptions"></b-form-select>
        </b-form-group>
        <b-form-group>
            <slot name="submit" :submit="submit">
                <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText" :disabled="!validated" style="margin-top:10px;">
            </slot>
        </b-form-group>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/platformexportfilesendconfig'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            lastActionOptions: [
                { value: 0, text: 'Deaktiviert' },
                { value: 1, text: 'Aktiviert' },
                { value: 2, text: 'Scheinangebote' },
            ],
            platformsendtypes:{},
            platformexportfilesendconfig: {
                platform_id:0,
                name: null,
                targettype: 1,
                targetaddress: null,
                targetpath: null,
                targetfilename: null,
                targetusername: null,
                targetpassword: null,
                userandomstocks: 0,
                maxmainstock: 20,
                description: null,
                LastAction: 1
            },
        }),

        created() {
            this.platformexportfilesendconfig.platform_id = this.editdata.id;
            this.getPlatformSendTypes();
        },

        methods: {
            getPlatformSendTypes: function(){
                axios.get('/admin/getPlatformSendTypes')
                .then(function (response) {
                    this.platformsendtypes = response.data;
                }.bind(this));
            },
            submit() {
                const _this = this;
               
                axios.post(this.url,
                    this.platformexportfilesendconfig,
                ).then(response => {
                   // _this.callback(response);
                   console.log(response);
                   this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });

            },
            showMsgBoxOne(callback) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   window.location = location.protocol + "//" + location.hostname + "/admin/getplatformexportfilesendconfigs/"+this.platformexportfilesendconfig.platform_id;
                })
                .catch(err => {
                })
            },
        },
        watch: {

        },
        computed: {
            nameState() {
                if(this.platformexportfilesendconfig.name == null || this.platformexportfilesendconfig.name == '')
                    return false
                return true;
            },

            targetaddressState() {
                if(this.platformexportfilesendconfig.targetaddress == null || this.platformexportfilesendconfig.targetaddress == '')
                    return false
                return true;
            },

            targetfilenameState() {
                if(this.platformexportfilesendconfig.targetfilename == null || this.platformexportfilesendconfig.targetfilename == '')
                    return false;
                return true;
            },

            targetusernameState() {
                if(this.platformexportfilesendconfig.targetusername == null || this.platformexportfilesendconfig.targetusername == '')
                    return false;
                return true;
            },

            targetpasswordState(){
                if(this.platformexportfilesendconfig.targetpassword == null || this.platformexportfilesendconfig.targetpassword == '')
                    return false;
                return true;
            },

            maxmainstockState(){
                    if (this.platformexportfilesendconfig.maxmainstock == null || this.platformexportfilesendconfig.maxmainstock == '')
                    return false;
                return true;
            },

            validated(){
                if(this.platformexportfilesendconfig.targettype == 1 &&
                 (this.platformexportfilesendconfig.targetusername == null ||
                  this.platformexportfilesendconfig.targetpassword == null) || 
                  (this.platformexportfilesendconfig.targetusername == '' ||
                  this.platformexportfilesendconfig.targetpassword == ''))
                {
                    return false;
                }
                return (
                    this.platformexportfilesendconfig.name != null &&
                    this.platformexportfilesendconfig.targetaddress != null &&
                    this.platformexportfilesendconfig.targetfilename != null
                )
            }

        },
    };
</script>
