<template>
    <v-app>
        <v-row>
            <v-data-table
            :headers="headers"
            :items="suppliermanualpurchasingprices" 
            :items-per-page="itemsPerPage"
            class="suppliermanualpurchasingprices-table" 
            :server-items-length="total" :loading="loading"
            :options.sync="options" 
            locale="de" 
            :footer-props="footerProps" 
            multi-sort
            group-by="ARAINR"
            >
                <template v-slot:group.header="{ headers, items, isOpen, toggle }">
                    <th :colspan="headers.length">
                    <v-icon @click="toggle">
                        {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                    {{ "ARAINR: "+items[0].ARAINR }}
                    </th>
                </template>
                <template v-slot:header.ARWG="{ header }">
                    {{ header.text }}
                    <v-menu v-model="arwgfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="arwg ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="arwg" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="arwgfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="arwg = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.AREAN="{ header }">
                    {{ header.text }}
                    <v-menu v-model="areanfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="arean ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="arean" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="areanfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="arean = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.AINR="{ header }">
                    {{ header.text }}
                    <v-menu v-model="arainrfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="arainr ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                           <v-text-field v-model="arainr" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                    :autofocus="true"></v-text-field>
                            <v-btn text @click="arainrfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="arainr = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:item.ARHKE="{ item }">
                    {{ item.ARHKE == null ? "" : item.ARHKE.replace(".", ",") }}
                </template>
                <template v-slot:item.ARDEL="{ item }">
                    {{ item.ARDEL == null ? "" : item.ARDEL.replace(".", ",") }}
                </template>
                <template v-slot:item.ARGW="{ item }">
                    {{ convertDoubleToCurrency(item.ARGW) }}
                </template>
                <template v-slot:item.shippingcharge="{ item }">
                    {{ convertDoubleToCurrency(item.shippingcharge) }}
                </template>
                <template v-slot:item.T24_PRICE="{ item }">
                     {{ convertDoubleToCurrency(item.T24_PRICE) }}
                </template>
                <template v-slot:item.T24_SURCHARGES="{ item }">
                    {{ convertDoubleToCurrency(item.T24_SURCHARGES) }}
                </template>
                <template v-slot:item.T100_PRICE="{ item }">
                    {{ convertDoubleToCurrency(item.T100_PRICE) }}
                </template>
                <template v-slot:item.T100_SURCHARGES="{ item }">
                    {{ convertDoubleToCurrency(item.T100_SURCHARGES) }}
                </template>
                <template v-slot:item.GETTYGO_PRICE="{ item }">
                    {{ convertDoubleToCurrency(item.GETTYGO_PRICE) }}
                </template>
                <template v-slot:item.GETTYGO_SURCHARGES="{ item }">
                    {{ convertDoubleToCurrency(item.GETTYGO_SURCHARGES) }}
                </template>

                 <template v-slot:item.MIN_BESTPRICE="{ item }">
                    {{ convertDoubleToCurrency(item.MIN_BESTPRICE) }}
                </template>
                    <template v-slot:item.MAX_SURCHARGES="{ item }">
                    {{ convertDoubleToCurrency(item.MAX_SURCHARGES) }}
                </template>
                    <template v-slot:item.artikelmanualpurchaseprice="{ item }">
                    {{ convertDoubleToCurrency(item.artikelmanualpurchaseprice) }}
                </template>
                <template v-slot:item.imported_artikelpurchasingprice="{ item }">
                    {{ item.artikelmanualpurchaseprice > 0 ? item.imported_artikelpurchasingprice : '' }}
                </template>
            </v-data-table>
        </v-row>
    </v-app>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/suppliermanualpurchasingprices'
        },
        location_url: {
            type: String,
            default: '/admin/suppliermanualpurchasingprices/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        disableOk: false,
        suppliermanualpurchasingprices: [],
        total: 0,
        itemsPerPage: 50,
        loading: true,
        conditions: [],
        filters: [],
        groupBy: [{ key: 'tsuppliermanualpurchasingprice.ARAINR' }],
        options: {
            page: 1,
            itemsPerPage: 50,
            // sortBy: 'DESC',
            sortDesc: [false],
            mustSort: false,
            multiSort: false,
            sort: '',
            filters: null
        },
        
        arean: '',
        areanfilter: false,
        arainr:'',
        arainrfilter:false,
        arwg: '',
        arwgfilter:false,
        headers: [
            { text: 'id', value: 'id' },
            { text: 'ARANR', value: 'ARANR' },
            { text: 'ARAPR', value: 'ARAPR' },
            { text: 'ARBZ1', value: 'ARBZ1' },
            { text: 'ARBZ2', value: 'ARBZ2' },
            { text: 'ARBZ3', value: 'ARBZ3' },
            { text: 'ARWG', value: 'ARWG' },
            { text: 'ARHE', value: 'ARHE' },
            { text: 'ARHKE', value: 'ARHKE' },
            { text: 'ARDEL', value: 'ARDEL' },
            { text: 'ARALB', value: 'ARALB' },
            { text: 'ARHAN', value: 'ARHAN' },
            { text: 'AREAN', value: 'AREAN' },
            { text: 'ARAFPK', value: 'ARAFPK' },
            { text: 'ARAINR', value: 'ARAINR' },
            { text: 'ARAINR', value: 'AINR' },
            { text: 'ARGW', value: 'ARGW' },
            { text: 'Versandkosten', value: 'shippingcharge' },
            { text: 'T24-Preis', value: 'T24_PRICE' },
            { text: 'T24_Gebühren', value: 'T24_SURCHARGES' },
            { text: 'T100-Preis', value: 'T100_PRICE' },
            { text: 'T100-Gebühren', value: 'T100_SURCHARGES' },
            { text: 'GETTYGO-Preis', value: 'GETTYGO_PRICE' },
            { text: 'GETTYGO-Gebühren', value: 'GETTYGO_SURCHARGES' },
            { text: 'MIN-Bestpreis', value: 'MIN_BESTPRICE' },
            { text: 'MAX-Gebühren', value: 'MAX_SURCHARGES' },
            { text: 'Lieferant', value: 'supplierName' },
            { text: 'Bestand', value: 'stock' },
            { text: 'Manueller Einkaufspreis', value: 'artikelmanualpurchaseprice' },
            { text: 'M. Einkaufspreis importiert am', value: 'imported_artikelpurchasingprice' },

        ],
        footerProps: {
            'items-per-page-options': [50, 500, 1000, -1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
        }

    }),
    created() {
        this.filters = new Array();
        this.getSupplierManualPurchasingPrices();
    },
    mounted() {
        window.suppliermanualpurchasingprices = this;
    },
    methods: {
        convertDoubleToCurrency(value) {
           return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value) //value.toString().replace('.',',');
        },

        async clearFilter() {
            this.filter();
        },
       
        async getSupplierManualPurchasingPrices() {
            this.loading = true
            if (typeof this.options.sortBy !== 'undefined') {
                 this.options.sort = this.options.sortBy[0];
            }
            
        axios.get('/admin/getsuppliermanualpurchasingprices/', { params: this.options })
            .then(function (response) {
                this.suppliermanualpurchasingprices = response.data.data;
                this.total = response.data.total
                this.itemsPerPage = response.data.per_page
                this.loading = false
            }.bind(this));
        },
        getColor(value) {
            if (value > 0) return "red"
            else return "green"
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + this.location_url;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
       
        filter() {
            this.filters.length = 0;
            if (this.arwg) {
                this.filters.push(new Array('tsuppliermanualpurchasingprice.ARWG', 'LIKE', this.arwg + '%'));
            }
            if (this.arean) {
                this.filters.push(new Array('tsuppliermanualpurchasingprice.AREAN', 'LIKE', this.arean + '%'));
            }
            if (this.arainr) {
                this.filters.push(new Array('tsuppliermanualpurchasingprice.ARAINR', 'LIKE', this.arainr + '%'));
            }
            this.options.filters = this.filters;
            this.getSupplierManualPurchasingPrices();
        }
    },
    watch: {
        options(newValue, oldValue) {
            let cont = false
            if (oldValue.page !== newValue.page) cont = true
            if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
            if (oldValue.sortBy !== newValue.sortBy) cont = true
            if (oldValue.sortDesc !== newValue.sortDesc) cont = true
            if (cont) {
                this.getSupplierManualPurchasingPrices();
            }
        },
    },
    computed: {
    },
};
</script>
