<template>
    <div class="row">
        <b-modal
            id="modal-supplierseasonsurcharge"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
                label="Zuschlag"
                label-for="name-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Zuschlag ist pflicht" 
            >
                <b-form-input
                    id="name-input"
                    v-model="supplierseasonsurcharge.name" 
                    :state="nameState"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Produkt-Typ"
                label-for="ptype-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Produkt-Typ ist pflicht" 
            >
                <b-form-select
                id="ptype-input"
                v-model="supplierseasonsurcharge.producttype"
                :options="producttypeoptions"
                value-field="name"
                text-field="name"
                disabled-field="notEnabled"
                :state="producttypeState"
                required
                ></b-form-select>
            </b-form-group>
            <b-form-group
                label="Werttype"
                label-for="type-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Werttype ist pflicht" 
            >
                <b-form-select
                 id="type-input"
                v-model="supplierseasonsurcharge.type"
                :options="$func.getValueTypeOptions()"
                value-field="key"
                text-field="type"
                disabled-field="notEnabled"
                ></b-form-select>
            </b-form-group>
            <b-form-group
            label="Wert"
            label-for="value-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Der Wert ist pflicht" 
            >
            <b-form-input
                id="value-input"
                v-model="supplierseasonsurcharge.value" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Type"
                label-for="type-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Type des Preisaufschlages ist pflicht" 
            >
                <b-form-select
                 id="type-input"
                v-model="supplierseasonsurcharge.surchargetype"
                :options="surchargetypeoptions"
                value-field="key"
                text-field="type"
                disabled-field="notEnabled"
                ></b-form-select>
            </b-form-group>
            <b-form-group
            label="Startdatum"
            label-for="surchargestartdate-input"
             label-cols="8"
            label-cols-lg="4"
             v-if="supplierseasonsurcharge.surchargetype == 1" 
            >
            <b-form-input
                id="surchargestartdate-input"
                v-model="supplierseasonsurcharge.surchargestartdate" 
                type="date"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Enddatum"
            label-for="surchargestopdate-input"
             label-cols="8"
            label-cols-lg="4"
             v-if="supplierseasonsurcharge.surchargetype == 1" 
            >
            <b-form-input
                id="surchargestopdate-input"
                v-model="supplierseasonsurcharge.surchargestopdate"
                type="date"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Beschreibung"
            label-for="description-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Name is required" 
            >
            <b-form-input
                id="description-input"
                v-model="supplierseasonsurcharge.description" 
            ></b-form-input>
            </b-form-group>
        </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped supplierseasonsurcharge-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Name</th>
                        <th>Produkt-Type</th>
                        <th>Werttype</th>
                        <th>Wert</th>
                        <th>Type</th>
                        <th>Startdatum</th>
                        <th>Endatum</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                         <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/supplierseasonsurcharges'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Saisonzuschlag hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:true,
                enableSubmit: false,
                countryoptions:{},
                editsupplierseasonsurcharge:{},
                supplierseasonsurcharge: {
                    id: 0,
                    name: null,
                    producttype:'Reifen',
                    type:'',
                    value: 0,
                    surchargetype:0,
                    surchargestartdate:null,
                    surchargestopdate:null,
                    description: null
                },
                producttypeoptions:[],
                surchargetypeoptions:[{key: 0, type: 'Ohne Zeitfenster'},{key: 1, type: 'Mit Zeitfenster'},]
        }),

        created() {
            this.getShippingPackagingTypes();
            this.getSupplierSeasonSurcharge('/admin/getsupplierseasonsurcharges');
        },
        mounted() {
             window.saisonsurcharge=this;
        },
        methods: {
            getShippingPackagingTypes: function () {
            axios.get('/admin/getshippingpackagingtypes')
                .then(function (response) {
                    this.producttypeoptions = response.data;
                }.bind(this));
            },
            async getSupplierSeasonSurcharge(address) {
                $(document).ready(function() {
                    var collapsedGroups = {};
                    var table = $('.supplierseasonsurcharge-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'name', name: 'name'},
                            {data: 'producttype', name: 'producttype'},
                            {data: 'type', name: 'type'},
                            {data: 'value', name: 'value'},
                            {data: 'surchargetype', name: 'surchargetype'},
                            {data: 'surchargestartdate', name: 'surchargestartdate'},
                            {data: 'surchargestopdate', name: 'surchargestopdate'},
                            {data: 'description', name: 'Beschreibung'},
                            {data: 'LastAction', name: 'LastAction'},
                            {data: 'action', name: 'Aktionen'},
                        ],
                        //order: [[ 4, 'asc' ]],
                    });
                $('.supplierseasonsurcharge-table tbody').on( 'click', 'tr.dtrg-group', function () {
                            var name = $(this).data('name');
                        collapsedGroups[name] = !collapsedGroups[name];
                        table.draw(false);
                } );
                });
            },
            getEditSurcharge(edit_id){
                axios.get('/admin/supplierseasonsurcharges/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-supplierseasonsurcharge');
                    this.modalname = "Saisonzuschlag bearbeiten"
                    this.supplierseasonsurcharge = response.data;
                    this.headerBgVariant = 'primary';
                   // this.$root.$emit("bv::show::modal", "your-modal-id");
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        window.location = location.protocol + "//" + location.hostname + "/admin/supplierseasonsurcharges";
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            getDeleteSurcharge(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('dass Sie den Saisonzuschlag wirklich löschen möchten?', {
                title: 'Bitte bestätigen Sie',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        return this.$refs.form.checkValidity()
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Saisonzuschlag hinzufügen";
        this.supplierseasonsurcharge.id = 0;
        this.supplierseasonsurcharge.name = null;
        this.supplierseasonsurcharge.value = 0;
        this.supplierseasonsurcharge.type = 0;
        this.supplierseasonsurcharge.surchargetype = 0;
        this.supplierseasonsurcharge.surchargestartdate = null;
        this.supplierseasonsurcharge.surchargestopdate = null;
        this.supplierseasonsurcharge.description = null;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.supplierseasonsurcharge.id > 0){
            axios.put(this.url+'/'+this.supplierseasonsurcharge.id,
                this.supplierseasonsurcharge,
            ).then(response => {
            // _this.callback(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.supplierseasonsurcharge,
            ).then(response => {
                // _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-supplierseasonsurcharge')
        })
      }
        },
        watch: {

        },
        computed: {
            nameState() {
                if(this.supplierseasonsurcharge.name == null || this.supplierseasonsurcharge.name == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },
            producttypeState() {
                return (this.supplierseasonsurcharge.producttype == null || this.supplierseasonsurcharge.producttype == "") ? false : true;
            },
        },
    };
</script>
