<template>
    <div class="row">
        <b-modal
            id="modal-prevent-closing"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                label="Plattformvorlage"
                label-for="platform-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Platform ist pflicht" 
                >
                    <b-form-input
                        id="platform-input"
                        v-model="editdata.name" 
                        type="text"
                        disabled
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="ARWG-Von"
                label-for="arwg-from-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-Von ist pflicht" 
                >
                   <b-form-input
                        id="arwg-from-input"
                        v-model="platformtemplatepricepercentage.ARWG_from" 
                        :state="arwgFromState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="^(?:[0-9]\d*|\d)$"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="ARWG-bis"
                label-for="arwg-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-bis ist pflicht" 
                >
                    <b-form-input
                        id="arwg-bis-input"
                        v-model="platformtemplatepricepercentage.ARWG_to" 
                        :state="arwgToState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Prozentsatz"
                label-for="pricepercentage-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Prozentsatz ist pflicht" 
                >
                    <b-form-input
                        id="pricepercentage-input"
                        v-model="platformtemplatepricepercentage.pricepercentage" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        :state="pricepercentageState"
                        required
                        :formatter="numberFormat"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Abweichung %"
                    label-for="percentagedeviation-input"
                    label-cols="8"
                    label-cols-lg="4" 
                    invalid-feedback="Prozentsatzabweichung ist pflicht" 
                    >
                        <b-form-input
                            id="percentagedeviation-input"
                            v-model="platformtemplatepricepercentage.percentagedeviation" 
                            type="number"
                            min="0"
                            value="0"
                            step="0.01" 
                            pattern="[0-9]+([\\,|\\.][0-9]+)?"
                            :state="percentagedeviationState"
                            required
                            :formatter="numberFormat"
                        ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Berechneter %"
                    label-for="calculatedpercentage-input"
                    label-cols="8"
                    label-cols-lg="4"
                    >
                    <b-form-input
                        id="calculatedpercentage-input"
                        type="number"
                        v-model="platformtemplatepricepercentage.calculatedpercentage" 
                        :formatter="numberFormat"
                        disabled
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="% Berechnet am"
                    label-for="calculatedpercentage_at-input"
                    label-cols="8"
                    label-cols-lg="4"
                    invalid-feedback="Name is required" 
                    >
                    <b-form-input
                        id="calculatedpercentage_at-input"
                        v-model="platformtemplatepricepercentage.calculatedpercentage_at" 
                        disabled
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Beschreibung"
                label-for="description-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Name is required" 
                >
                <b-form-input
                    id="description-input"
                    v-model="platformtemplatepricepercentage.description" 
                ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped platformtemplatepricepercentage-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Plattformvorlage</th>
                        <th>ARWG-Von</th>
                        <th>ARWG-Bis</th>
                        <th>Prozentsatz</th>
                        <th>% Abweichung</th>
                        <th>Berechneter %</th>
                        <th>% berechnet am</th>
                        <th>Beschreibung</th>
                        <th>Erstellt_am</th>
                        <th>Aktualisiert_am</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/platformtemplatepricepercentages'
            },
            location_url: {
                type: String,
                default: '/admin/platformtemplatepricepercentage/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Abschlag hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            herstellers:{},
            wholesalers:{},
            platformtemplates:{},
            editshippingservicesurchage:{},
            onlynumberspattern : /^[0-9]*$/,
            platformtemplatepricepercentage: {
                id: 0,
                platformtemplate_id: 0,
                ARWG_from : null,
                ARWG_to: null,
                pricepercentage: null,
                description: null,
                LastAction: 1
            },
        }),

        created() {
            this.platformtemplatepricepercentage.platformtemplate_id = this.editdata.id;
            this.getPlatformtemplatePricePercentage('/admin/platformtemplatepricepercentage/getplatformtemplatepricepercentages/'+ this.editdata.id);
        },
        mounted() {
             window.platformtemplatepricepercentages=this;
        },
        methods: {
            numberFormat(value) {
                return value.toLocaleString();
            },
            async getPlatformtemplatePricePercentage(address) {
                 $(document).ready(function() {
                    var table = $('.platformtemplatepricepercentage-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'platformtemplatename', name: 'platformtemplatename'},
                            {data: 'ARWG_from', name: 'ARWG_from'},
                            {data: 'ARWG_to', name: 'ARWG_to'},
                            {data: 'pricepercentage', name: 'pricepercentage'},
                            { data: 'percentagedeviation', name: 'percentagedeviation' },
                            { data: 'calculatedpercentage', name: 'calculatedpercentage' },
                            { data: 'calculatedpercentage_at', name: 'calculatedpercentage_at' },
                            {data: 'description', name: 'description'},
                            {data: 'created_at', name: 'created_at'},
                            {data: 'updated_at', name: 'updated_at'},
                            {data: 'action', name: 'action'},
                        ],
                    });
                });
            },

            getEditPlatformtemplatePricePercentage(edit_id){
                axios.get('/admin/platformtemplatepricepercentages/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Prozentsatz bearbeiten"
                    this.platformtemplatepricepercentage = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        $('.platformtemplatepricepercentage-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getDeletePlatformtemplatePricePercentage(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie diesen Prozentsatz wirklich löschen?', {
                title: 'WARNUNG',
                headerBgVariant: "warning",
                headerTextVariant: "dark",
                size: 'md',
                buttonSize: 'sm',
                cancelVariant:'success',
                okVariant: 'danger',
                okTitle: 'JA',
                okTitleVariant:"white",
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            console.log(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        //this.nameState = valid
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Prozentsatz hinzufügen";
        this.platformtemplatepricepercentage.id = 0;
        this.platformtemplatepricepercentage.platformtemplate_id = this.editdata.id;
        this.platformtemplatepricepercentage.ARWG_from = null;
        this.platformtemplatepricepercentage.ARWG_to = null;
        this.platformtemplatepricepercentage.pricepercentage = null;
        this.platformtemplatepricepercentage.percentagedeviation = null;
        this.platformtemplatepricepercentage.description = null;
        this.platformtemplatepricepercentage.LastAction = 1;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.platformtemplatepricepercentage.id > 0){
            axios.put(this.url+'/'+this.platformtemplatepricepercentage.id,
                this.platformtemplatepricepercentage,
            ).then(response => {
            // _this.callback(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.platformtemplatepricepercentage,
            ).then(response => {
                // _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

                // Hide the modal manually
                this.$nextTick(() => {
                    this.resetModal();
                    this.$bvModal.hide('modal-prevent-closing')
                })
            }
        },
        watch: {

        },
        computed: {
            arwgFromState() {
                return this.platformtemplatepricepercentage.ARWG_from == null ? false : true;
            },

            arwgToState() {
                return this.platformtemplatepricepercentage.ARWG_to == null ? false : true;
            },

            pricepercentageState(){
                return (this.platformtemplatepricepercentage.pricepercentage == null || this.platformtemplatepricepercentage.pricepercentage == "") ? false : true;
            },
            percentagedeviationState(){
                return (this.platformtemplatepricepercentage.percentagedeviation == null || this.platformtemplatepricepercentage.percentagedeviation == "") ? false : true;;
            }
        },
    };
</script>
