<template>
    <v-app>
        <v-row>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="platformexportfileconfigs"
                item-key="id"
                show-select
                class="ma-3"
                group-by="platform.name"
                
            >
                <template v-slot:top>
                    <div class="ma-3" v-if="selected.length > 0">
                        <p>Selektierte</p>
                        <v-btn
                            color="success"
                            class="mr-2"
                            @click="activateAll"
                        >
                            Aktivieren
                        </v-btn>
                        <v-btn
                            color="red"
                            class = "text-white"
                            @click="deactivateAll"
                        >
                            Deaktivieren
                        </v-btn>
                    </div>
                </template>
            <template v-slot:item.name="{ item }">
                    {{ item.platform.name+" "+ item.name }}
            </template>
            <template v-slot:item.LastAction="{ item }">
              <v-icon color='green' v-if="item.LastAction == 2"  @click="deactivateItem(item)">
                mdi-play-circle
              </v-icon>
              <v-icon color='red' v-if="item.LastAction == 1"  @click="activateItem(item)" >
                mdi-stop-circle
              </v-icon>
            </template>
            </v-data-table>
        </v-row>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/dummyplatformownoffers'
        },
    },
    data() {
        return {
            singleSelect: false,
            selected: [],
            updateselected: [],
            headers: [
                {
                    text: 'Plattform',
                    align: 'start',
                    sortable: true,
                    value: 'platform.name',
                },
                { text: 'Name', value: 'name',  align: 'start', },
                { text: 'Status', sortable: true, value: 'LastAction' },  
            ],
            platformexportfileconfigs:[],
            dummyplatformownofferscount:0,
        }
    },
    created() {
        this.getPlatformExportfileSendConfigs();
    },
    methods: {
        getPlatformExportfileSendConfigs: function () {
            axios.get('/admin/getplatformexportfileconfigs')
                .then(function (response) {
                    this.platformexportfileconfigs = response.data;
                }.bind(this));
        }, 
        getDummyPlatformOwnoffersCount: function () {
            axios.get('/admin/getdummyplatformownofferscount')
                .then(function (response) {
                    this.dummyplatformownofferscount = response.data;
                }.bind(this));
        },
        activateItem(item) {
            if (this.checkDummyPlatformOffers()) {
                return;
            }
            item.LastAction = 2;
            this.updateItem(item);
        },
        activateAll() {
            if (this.checkDummyPlatformOffers()) {
                return;
            }
            this.updateselected = [];
            this.selected.forEach(element => {
            this.updateselected.push({id: element.id, LastAction: 2});
            });
            this.updateAll();
        },
        checkDummyPlatformOffers()
        {
            this.getDummyPlatformOwnoffersCount();
            if (this.dummyplatformownofferscount == 0) {
                this.$bvModal.msgBoxOk("Bevor Sie die Scheinangebote aktivieren müssen Sie erst die Scheinangebotedatei Scheinangebote.csv in den Ordner 'pricing_tool/Scheinangebote' bereitstellen!");
                this.getPlatformExportfileSendConfigs();
                return true;
            }
            return false;
        },
        deactivateItem(item)
        {
            item.LastAction = 1;
            this.updateItem(item);
        },
        deactivateAll() {
            this.updateselected = [];
            this.selected.forEach(element => {
                this.updateselected.push({ id: element.id, LastAction: 1 });
            });
            this.updateAll();
        },
        updateItem(item) {
            axios.put(this.url + '/' + item.id,
                item,
            ).then(response => {
                this.$bvModal.msgBoxOk(response.data);
                this.selected = [];
                this.getPlatformExportfileSendConfigs();
            }).catch(response => {
                
            }).finally(response => {
              
            });
        },
        updateAll(){
            axios.post(this.url,
                this.updateselected,
            ).then(response => {
                this.$bvModal.msgBoxOk(response.data);
                this.selected = [];
                this.getPlatformExportfileSendConfigs();
            }).catch(response => {

            }).finally(response => {

            });
        },
    },
     watch: {
        
    },
    computed: {
        
    }
}
</script>
