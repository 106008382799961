<template>
    <div class="row">
        <b-modal
            id="modal-prevent-closing"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                
                <b-form-group
                label="Plattform"
                label-for="platform-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback=">Pflicht Feld" 
                >
                   <b-form-input
                        id="platform-input"
                        v-model="platformignoresupplierstock.platform.name" 
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Lieferant"
                label-for="supplier-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Pflicht Feld" 
                >
                    <b-form-select
                    id="platform-input"
                    v-model="platformignoresupplierstock.supplier_id"
                    :options="suppliers"
                    value-field="id"
                    text-field="supplierName"
                    disabled-field="notEnabled"
                    :state="supplierState"
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Beschreibung"
                label-for="description-input"
                label-cols="8"
                label-cols-lg="4"
                >
                    <b-form-input
                        id="description-input"
                        v-model="platformignoresupplierstock.description" 
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <div class="table-responsive">
            <table class="table table-bordered table-striped platformignoresupplierstock-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Plattform</th>
                        <th>Lieferant</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/platformignoresupplierstocks'
            },
            location_url: {
                type: String,
                default: '/admin/platformignoresupplierstock/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Lieferant hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            suppliers:{},
            platformignoresupplierstock: {
                id: 0,
                platforms_id: 0,
                supplier_id: null,
                description: null,
                lastaction: 1
            },
            
        }),

        created() {
            //console.log(JSON.stringify(this.editdata));
            this.platformignoresupplierstock = this.editdata;
            this.getPlatformStockTemplates();
            this.getPlatformIgnoreSupplierstocks('/admin/platformignoresupplierstock/getplatformignoresupplierstocks/'+ this.platformignoresupplierstock.platforms_id);
            
        },
        mounted() {
             window.platformignoresupplierstocks=this;
        },
        methods: {
            async getPlatformIgnoreSupplierstocks(address) {
                 $(document).ready(function() {
                    var table = $('.platformignoresupplierstock-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'platform', name: 'platform'},
                            {data: 'supplier', name: 'supplier'},
                            {data: 'description', name: 'description'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'action'},
                        ],
                    });
                });
            },

            getPlatformStockTemplates: function(){
                axios.get('/admin/getsuppliers')
                .then(function (response) {
                    this.suppliers = response.data;
                }.bind(this));
            },

            getEditPlatformIgnoreSupplierStock(edit_id){
                axios.get('/admin/platformignoresupplierstocks/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Ignorierter Lieferant bearbeiten"
                    this.platformignoresupplierstock = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                       window.location = location.protocol + "//" + location.hostname + this.location_url + this.platformignoresupplierstock.platforms_id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getDeletePlatformIgnoreSupplierstock(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie dieser ignorierter Lieferant wirklich löschen?', {
                title: 'WARNUNG',
                headerVariant: "warning",
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            console.log(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        //this.nameState = valid
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuer ignorierten Lieferant hinzufügen";
        this.platformignoresupplierstock.id = 0;
        this.platformignoresupplierstock.platforms_id = this.editdata.platforms_id;
        this.platformignoresupplierstock.supplierstocktemplate_id = 0;
        this.platformignoresupplierstock.description = null;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
         console.log(JSON.stringify(this.platformignoresupplierstock));
        if(this.platformignoresupplierstock.id > 0){
            axios.put(this.url+'/'+this.platformignoresupplierstock.id,
                this.platformignoresupplierstock,
            ).then(response => {
            // _this.callback(response);
            console.log(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            
            axios.post(this.url,
                this.platformignoresupplierstock,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
      }
        },
        watch: {

        },
        computed: {
            supplierState(){
                if(this.platformignoresupplierstock.supplier_id > 0){
                    this.disableOk = false;
                    return true;
                }
                this.disableOk = true;
                return false;
            },
        },
    };
</script>
