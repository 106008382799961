var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-data-table',{staticClass:"dummyownoffer-table",attrs:{"headers":_vm.headers,"items":_vm.artikels,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalartikels,"loading":_vm.loading,"options":_vm.options,"locale":"de","footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.ownprice",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ownprice, 'decimal'))+"\n        ")]}},{key:"item.ownoffer",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ownoffer, 'decimal'))+"\n        ")]}},{key:"header.ARAINR",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arainr ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.arainrfilter),callback:function ($$v) {_vm.arainrfilter=$$v},expression:"arainrfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arainr),callback:function ($$v) {_vm.arainr=$$v},expression:"arainr"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arainrfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arainr = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.AREAN",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arean ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.areanfilter),callback:function ($$v) {_vm.areanfilter=$$v},expression:"areanfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arean),callback:function ($$v) {_vm.arean=$$v},expression:"arean"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.areanfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arean = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.ARWG",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arean ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.arwgfilter),callback:function ($$v) {_vm.arwgfilter=$$v},expression:"arwgfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arwg),callback:function ($$v) {_vm.arwg=$$v},expression:"arwg"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arwgfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arwg = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.ARHE",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arhe ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.arhefilter),callback:function ($$v) {_vm.arhefilter=$$v},expression:"arhefilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arhe),callback:function ($$v) {_vm.arhe=$$v},expression:"arhe"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arhefilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.arhe = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.ARALB",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.aralb ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.aralbfilter),callback:function ($$v) {_vm.aralbfilter=$$v},expression:"aralbfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.aralb),callback:function ($$v) {_vm.aralb=$$v},expression:"aralb"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.aralbfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.arhe = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.ARSVK",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arsvk ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.arsvkfilter),callback:function ($$v) {_vm.arsvkfilter=$$v},expression:"arsvkfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arsvk),callback:function ($$v) {_vm.arsvk=$$v},expression:"arsvk"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arsvkfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.arsvk = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"item.ARGW",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARGW, 'decimal'))+"\n        ")]}},{key:"item.ARDEL",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARDEL, 'currency'))+"\n        ")]}},{key:"item.ARVK1",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK1, 'currency'))+"\n        ")]}},{key:"item.ARVK2",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK2, 'currency'))+"\n        ")]}},{key:"item.ARVK3",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK3, 'currency'))+"\n        ")]}},{key:"item.ARVK4",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK4, 'currency'))+"\n        ")]}},{key:"item.ARVK5",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK5, 'currency'))+"\n        ")]}},{key:"item.ARVK6",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK6, 'currency'))+"\n        ")]}},{key:"item.ARVK7",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK7, 'currency'))+"\n        ")]}},{key:"item.ARVK8",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK8, 'currency'))+"\n        ")]}},{key:"item.ARVK9",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK9, 'currency'))+"\n        ")]}},{key:"item.ARVK10",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK10, 'currency'))+"\n        ")]}},{key:"item.ARVK11",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK11, 'currency'))+"\n        ")]}},{key:"item.ARVK12",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK12, 'currency'))+"\n        ")]}},{key:"item.ARVK13",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK13, 'currency'))+"\n        ")]}},{key:"item.ARVK14",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK14, 'currency'))+"\n        ")]}},{key:"item.ARVK15",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK15, 'currency'))+"\n        ")]}},{key:"item.ARVK16",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK16, 'currency'))+"\n        ")]}},{key:"item.ARVK17",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK17, 'currency'))+"\n        ")]}},{key:"item.ARVK18",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARVK18, 'currency'))+"\n        ")]}}])}),_vm._v(" "),[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"elevation":"2","rounded":"","small":""},on:{"click":_vm.back}},[_vm._v("Zurück")])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }