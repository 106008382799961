<template>
    <div class="row">
        <b-modal
            id="modal-hersteller"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
            label="Herstellernummer"
            label-for="number-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Herstellernummer ist pflicht"
            >
            <b-form-input
                id="number-input"
                v-model="hersteller.number" 
                type="number"
                min="0"
                value="0"
                step="1" 
                :state="numberState"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Name"
            label-for="name-input"
            label-cols="8"
            label-cols-lg="4" 
            invalid-feedback="Name ist pflicht" 
            >
            <b-form-input
                id="name-input"
                v-model="hersteller.name"
                :state="nameState"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Beschreibung"
            label-for="description-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="description-input"
                v-model="hersteller.description" 
            ></b-form-input>
            </b-form-group>
        </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped hersteller-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Kürzel</th>
                        <th>Name</th>
                        <th>Beschreibung</th>
                        <th>Erstellt am</th>
                        <th>Aktualisiert am</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/hersteller'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Hersteller hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            enableSubmit: false,
            hersteller: {
                id: 0,
                number:null,
                name: null,
                description: null,
            },
           
        }),

        created() {
            this.getHersteller('/admin/gethersteller');
        },
        mounted() {
            window.hersteller=this;
        },
        methods: {
                async getHersteller(address) {
                 $(document).ready(function() {
                    var table = $('.hersteller-table').DataTable({
                        processing: true,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        orderable: true,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'number', name: 'number'},
                            {data: 'name', name: 'name'},
                            {data: 'description', name: 'description'},
                            {data: 'created_at', name: 'created_at'},
                            {data: 'updated_at', name: 'updated_at'},
                            {data: 'action', name: 'action'},
                        ],
                        order: [[ 4, 'asc' ]],
                    });
                });
            },
            getEditHersteller(edit_id){
                console.log("id: "+edit_id);
                axios.get('/admin/hersteller/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-hersteller');
                    this.modalname = "Hersteller bearbeiten"
                    this.hersteller = response.data;
                    this.headerBgVariant = 'primary';
                    console.log(this.hersteller);
                }.bind(this));
            },

            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                  /* if(change)
                    {
                        window.location = location.protocol + "//" + location.hostname + "/admin/gethersteller/"+this.editdata.id;
                    }*/
                })
                .catch(err => {
                    // An error occurred
                })
            },

            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                //this.nameState = valid
                return valid
            },
            resetModal() {
                this.headerBgVariant = 'success';
                this.modalname = "Hersteller hinzufügen";
                this.hersteller.id = 0;
                this.hersteller.number = null;
                this.hersteller.name = null;
                this.hersteller.description = null;
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                const _this = this;
                if(this.hersteller.id > 0){
                    axios.put(this.url+'/'+this.hersteller.id,
                        this.hersteller,
                    ).then(response => {
                    // _this.callback(response);
                    console.log(response);
                    this.showMsgBoxOne(response, true);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }else{
                    axios.post(this.url,
                        this.hersteller,
                    ).then(response => {
                        // _this.callback(response);
                        console.log(response);
                        this.showMsgBoxOne(response, true);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }

                // Hide the modal manually
                this.$nextTick(() => {
                    this.resetModal();
                    this.$bvModal.hide('modal-hersteller')
                })
            },

            checkFormValidity(){
                if(this.hersteller.number == null || this.hersteller.number == "" || this.hersteller.name == null || this.hersteller.name == "")
                {
                    this.disableOk = true;
                }else{
                    this.disableOk = false;
                }
            }
        },
        watch: {

        },
        computed: {
            numberState() {
                this.checkFormValidity();
                if(this.hersteller.number == null || this.hersteller.number == "")
                {
                    return false;
                }
                return true;
            },
            nameState() {
                this.checkFormValidity();
                if(this.hersteller.name == null || this.hersteller.name == "")
                {
                    return false;
                }

                return true;
            }
        },
    };
</script>
