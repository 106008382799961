<template>
    <v-app>
        <v-data-table :headers="headers" :items="supplierstocks" :items-per-page="itemsPerPage" class="supplierstock-table"
            :server-items-length="totalsupplierstocks" :loading="loading" :options.sync="options" locale="de"
            :footer-props="footerProps" multi-sort>
            <template v-slot:header.supplierName="{ header }">
                {{ header.text }}
                <v-menu v-model="suppliernamefilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="suppliername ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="suppliername" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="suppliernamefilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="suppliername = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.supplierNumber="{ header }">
                {{ header.text }}
                <v-menu v-model="suppliernrfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="suppliernr ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="suppliernr" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="suppliernrfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="suppliernr = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ARAINR="{ header }">
                {{ header.text }}
                <v-menu v-model="arainrfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arainr ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arainr" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="arainrfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arainr = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.EAN="{ header }">
                {{ header.text }}
                <v-menu v-model="areanfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arean ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arean" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="areanfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arean = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ARWG="{ header }">
                {{ header.text }}
                <v-menu v-model="arwgfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arwg ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arwg" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="arwgfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arwg = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ARALB="{ header }">
                {{ header.text }}
                <v-menu v-model="aralbfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="aralb ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="aralb" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="aralbfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="aralb = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.ARSVK="{ header }">
                {{ header.text }}
                <v-menu v-model="arsvkfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="arsvk ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="arsvk" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="arsvkfilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="arsvk = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:item.price="{ item }">
                {{ convertToDecimal(item.price, 'currency') }}
            </template>
            <template v-slot:item.ARDEL="{ item }">
                    {{ convertToDecimal(item.ARDEL, 'currency') }}
                </template>
            <template v-slot:item.ARGW="{ item }">
                {{ convertToDecimal(item.ARGW, 'decimal') }}
            </template>
            <template v-slot:item.ARHKE="{ item }">
                    {{ convertToDecimal(item.ARHKE, 'decimal') }}
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/supplierstocks'
        },
        location_url: {
            type: String,
            default: '/admin/supplierstocks/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        disableOk: false,
        platformtemplates: [],
        supplierstocks: [],
        totalsupplierstocks: 0,
        itemsPerPage: 50,
        loading: true,
        conditions: [],
        filters: [],
        statusactions: [{ key: -1, name: 'ARWG Fehlt' }, { key: -2, name: 'ARGW Fehlt' }, { key: -3, name: 'AREAN Fehlerhaft' },
        { key: 1, name: 'ARALB = 0' }, { key: 2, name: 'Importiert' }, { key: 3, name: 'aktualisiert' }],
        options: {
            platform_id: 0,
            page: 1,
            itemsPerPage: 50,
            // sortBy: 'DESC',
            sortDesc: [false],
            mustSort: false,
            multiSort: false,
            sort: '',
            filters: null
        },
        suppliername: '',
        suppliernr: '',
        arainr: '',
        arean: '',
        arwg: '',
        arhe: '',
        aralb: '',
        arsvk: '',
        lastaction: '',

        suppliernamefilter: false,
        suppliernrfilter: false,
        arainrfilter: false,
        areanfilter: false,
        arwgfilter: false,
        arhefilter: false,
        aralbfilter: false,
        arsvkfilter: false,
        lastactionfilter: false,
        headers: [
            { text: 'id', value: 'id' },
            { text: 'Lieferant', value: 'supplierName' },
            { text: 'Lieferantennr.', value: 'supplierNumber' },
            { text: 'Artikelnr.', value: 'supplierArticleNumber' },
            { text: 'ARAINR', value: 'ARAINR' },
            { text: 'AREAN', value: 'EAN' },
            { text: 'Preis', value: 'price' },
            { text: 'Bestand', value: 'stock' },
            { text: 'ARWG', value: 'ARWG' },
            { text: 'ARGW', value: 'ARGW' },
            { text: 'ARHE', value: 'ARHE' },
            { text: 'ARAFPK', value: 'ARAFPK' },
            { text: 'ARDEL', value: 'ARDEL' },
            { text: 'ARALB', value: 'ARALB' },
            { text: 'ARUEGRGW', value: 'ARUEGRGW' },
            { text: 'ARUEGW', value: 'ARUEGW' },
            { text: 'ARSVK', value: 'ARSVK' },
            { text: 'ARHAN', value: 'ARHAN' },
            { text: 'ARHKE', value: 'ARHKE' },
            { text: 'ARDOT', value: 'ARDOT' },
            { text: 'Erstellt', value: 'created_at' },
        ],
        footerProps: {
            'items-per-page-options': [50, 500, 1000, -1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
        }
    }),
    created() {
        this.filters = new Array();
    },
    mounted() {
        window.supplierstocks = this;
    },
    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin";
        },
        getStatus: function (value) {
            ArtikelStatus
        },
        convertToDecimal(value, type) {
            if (type == 'currency') {
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
            }

            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
                .replace("€", "")
                .trim();

        },
        async clearFilter() {
            this.filter();
        },
        async getSupplierstocks() {
            this.loading = true
            this.options.sort = this.options.sortBy[0];
            axios.get('/admin/getsupplierstocks/', { params: this.options })
                .then(function (response) {
                    this.supplierstocks = response.data.data;
                    this.totalsupplierstocks = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                }.bind(this));
        },
        getColor(value) {
            if (value > 0) return "red"
            else return "green"
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + this.location_url;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        filter() {
            this.filters.length = 0;
            if (this.suppliername) {
                this.filters.push(new Array('supplierName', 'LIKE', '%'+this.suppliername+'%'));
            }
            if (this.suppliernr) {
                this.filters.push(new Array('supplierNumber', 'LIKE', '%' + this.suppliernr + '%'));
            }
            if (this.arainr) {
                this.filters.push(new Array('ARAINR', '=', this.arainr));
            }
            if (this.arean) {
                this.filters.push(new Array('EAN', 'LIKE', '%' + this.arean + '%'));
            }
            if (this.arwg) {
                this.filters.push(new Array('ARWG', '=', this.arwg));
            }
            if (this.arhe) {
                this.filters.push(new Array('ARHE', '=', this.arhe));
            }
            if (this.aralb) {
                this.filters.push(new Array('ARALB', '=', this.aralb));
            }
            if (this.arsvk) {
                this.filters.push(new Array('ARSVK', '=', this.arsvk));
            }
            if (this.lastaction) {
                this.filters.push(new Array('LastAction', '=', this.lastaction));
            }
            this.options.filters = this.filters;
            this.getSupplierstocks();
        },
        onResize() {
            if (window.innerWidth < 769)
                this.isMobile = true;
            else
                this.isMobile = false;
        },
    },
    watch: {
        options(newValue, oldValue) {
            let cont = false
            if (oldValue.page !== newValue.page) cont = true
            if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
            if (oldValue.sortBy !== newValue.sortBy) cont = true
            if (oldValue.sortDesc !== newValue.sortDesc) cont = true
            if (cont) {
                this.getSupplierstocks();
            }
        },
    },
    computed: {

    },
};
</script>