<template>
    <div class="vue-csv-uploader">
        <div class="form">
            <div class="form">
                <b-form-group role="form-group">
                    <label for="input-name">Lieferantenname:</label>
                    <b-form-input
                    id="input-name"
                    v-model="supplierName"
                    disabled
                    ></b-form-input>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-supplierNumber">Lieferantennummer:</label>
                    <b-form-input
                    id="input-supplierNumber"
                    v-model="supplierNumber"
                    disabled
                    ></b-form-input>
                </b-form-group>
                <div class="table-responsive">
                    <!-- https://smarttutorials.net/dynamically-add-or-remove-table-row-using-vuejs/ -->
                    <button type='button' class="btn btn-info" @click="addNewRow">
                        <i class="fas fa-plus-circle"></i>
                        Mindestpreis Hinzufügen
                    </button>

                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Anzahl</th>
                                <th scope="col">Warengruppe von</th>
                                <th scope="col">Warengruppe bis</th>
                                <th scope="col text-right">Preis</th>
                                <th scope="col">Prozent</th>
                                <th scope="col text-right">Preis (Gewinn)</th>
                                <th scope="col">Startdatum</th>
                                <th scope="col">Enddatum</th>
                                 <th scope="col">Land</th>
                                <th scope="col">Beschreibung</th>
                                <th scope="col">#</th>
                            </tr>
                        </thead>
                        <tr v-for="(mindestpreis, k) in mindestpreise" :key="k">
                            <td>
                                <input class="form-control" type="number" min="1" step="1" v-model="mindestpreis.anzahl" required />
                            </td>
                            <td>
                                <input class="form-control" type="number"  v-model="mindestpreis.warengruppeVon" required />
                            </td>
                            <td>
                                <input class="form-control" type="number"  v-model="mindestpreis.warengruppeBis" required />
                            </td>
                            <td>
                                <input class="form-control" type="number"  v-model="mindestpreis.preis" :state="preisState" required />
                            </td>
                             <td>
                                <input class="form-control" type="number"  v-model="mindestpreis.prozent" :state="prozentState" required/>
                            </td>
                            <td>
                                <input class="form-control" type="number"  v-model="mindestpreis.preisgewinn" required />
                            </td>
                            <td>
                                <input class="form-control" type="text"  v-model="mindestpreis.validDateFrom" />
                            </td>
                            <td>
                                <input class="form-control" type="text"  v-model="mindestpreis.validDateUntil" />
                            </td>
                            <td>
                                <b-form-select
                                v-model="mindestpreis.country"
                                :options="countryoptions"
                                class="mb-5"
                                value-field="code"
                                text-field="country"
                                disabled-field="notEnabled"
                                required
                                ></b-form-select>
                            </td>
                             <td>
                                 <input class="form-control" type="text"  v-model="mindestpreis.description" />
                            </td>
                            <td scope="row" class="trashIconContainer">
                                <i class="far fa-trash-alt fa-2x" style="color: red;" @click="deleteRow(k, mindestpreis)"></i>
                            </td>
                        </tr>
                    </table>
                </div>
                <b-form-group>
                    <b-button href='#' variant='secondary' v-on:click="back">Zurück</b-button>
                    <slot name="submit" :submit="submit">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/supplierstockmindestpreisconf'
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            enableSubmit: false,
            countryoptions:{},
            supplierstocktemplate_id:0,
            supplierName: '',
            supplierNumber: '',
            mindestpreise: [{
                supplierstocktemplate_id:'',
                anzahl: 0,
                warengruppeVon: '',
                warengruppeBis: '',
                preis: 0,
                prozent: 0,
                preisgewinn: 0,
                validDateFrom:'',
                validDateUntil: '',
                country: '',
                description: ''
            }],
        }),

        created() {
            this.getCountries();
            this.mindestpreise = [];
            this.supplierName = this.editdata.supplierName;
            this.supplierstocktemplate_id = this.editdata.id;
            this.supplierNumber = this.editdata.supplierNumber;
            this.getSupplierStockMindestpreise();
        },

        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
            },
            getSupplierStockMindestpreise: function(){
                axios.get('/admin/supplierstockmindestpreisconf/getsupplierstockmindestpreise/'+this.supplierstocktemplate_id)
                .then(function (response) {
                    this.mindestpreise = response.data;
                }.bind(this));
            },
            getCountries: function(){
                axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
            },
            addNewRow() {
                this.mindestpreise.push({
                    id:0,
                    supplierstocktemplate_id: this.supplierstocktemplate_id,
                    anzahl: 0,
                    warengruppeVon: '',
                    warengruppeBis: '',
                    preis: 0.00,
                    prozent: 0,
                    preisgewinn: 0.00,
                    validDateFrom:'',
                    validDateUntil: '',
                    country: '',
                    description: ''
                });
            },
            deleteRow(index, mindestpreis) {
                var idx = this.mindestpreise.indexOf(mindestpreis);
                console.log(idx, index);
                if (idx > -1) {
                    this.mindestpreise.splice(idx, 1);
                   if(mindestpreis.id > 0)
                   {
                       axios.delete('/admin/supplierstockmindestpreisconf/'+mindestpreis.id
                       ).then(response => {
                        console.log(response);
                        this.showMsgBoxOne(response, false);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                   }
                }
            },

            submit() {
                 const _this = this;
                 Array.from(this.mindestpreise).forEach(mp =>
                        this.showError(mp)
                );
                axios.post(this.url,
                    this.mindestpreise,
                ).then(response => {
                   this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                    console.log(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            showMsgBoxOne(callback, change) {
                if(callback.data.data.code > 200){
                        this.$bvModal.msgBoxOk(callback.data.data.message, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                    return;
                }
                //this.$bvModal.msgBoxOk(callback.data)
                this.$bvModal.msgBoxOk(callback.data.data.message, {
                        title: 'MELDUNG',
                        headerBgVariant: 'success',
                        headerTextVariant: 'light',
                        titleVariant: 'success',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'success',
                        size:'lg',
                        okVariant: 'success'
                         })
                .then(value => {
                    if(change)
                    {
                        window.location = location.protocol + "//" + location.hostname + "/admin/supplierstockmindestpreisconf/"+this.supplierstocktemplate_id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            checkPrice(price){
                return /^\d+(\.\d+)*$/.test(price);
            },

            checkDate(date){
                return /^\d+(\.\d\d)*$/.test(date);
            },
            makeErrorToast() {
                this.$bvToast.toast('Test', {
                title: 'Fehler'
                })
            },
            showError(mp) {
                if(mp.prozent == null)
                 {
                     this.$bvModal.msgBoxOk('Prozent darf nicht leer sein!', {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                 }
            }
        },
        watch: {

        },
        computed: {
            preisState() {
                 if(this.mindestpreise.preis == null)
                    return false;
                 return this.mindestpreise.preis.length > 0 ? this.checkPrice(this.mindestpreise.preis) : false;
            },
            prozentState() {
                 if(this.mindestpreise.prozent == null)
                    return false;
                 return this.mindestpreise.prozent.length > 0 ? this.checkPrice(this.mindestpreise.prozent) : false;
            },
            validDateFromState() {
                if(this.mindestpreise.validDateFrom == null)
                    return true;
                return this.mindestpreise.validDateFrom.length > 0 ? this.checkDate(this.mindestpreise.validDateFrom) : false;
            },
            validDateUntilState() {
                if(this.mindestpreise.validDateUntil == null)
                    return true;
                return this.mindestpreise.validDateUntil.length > 0 ? this.checkDate(this.mindestpreise.validDateUntil) : false;
            }
        },
    };
</script>
