<template>
    <v-app>
        <div class="vue-csv-uploader">
        <v-form>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    >
                        <v-autocomplete
                        v-model="ptemplatemapping.filepath"
                        :items="directories"
                        label="Pfad"
                        persistent-hint
                        required
                        ></v-autocomplete>
                    </v-col> 
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                        v-model="ptemplatemapping.filename"
                        :rules="filenameRules"
                        hint="Dateiname der Plattform"
                        label="Dateiname"
                        required
                        ></v-text-field>
                    </v-col> 
            </v-row>
            <v-row>
                <v-col
                cols="12"
                md="8"
                >
                <v-text-field
                    v-model="ptemplatemapping.description"
                    label="Beschreibung"
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row>
                <v-col
                cols="12"
                md="4"
                >
                <v-text-field
                    v-model="ptemplatemapping.templatefile"
                    label="Vorlagedatei"
                    readonly
                    ></v-text-field>
                </v-col> 
            </v-row>
            <v-row>
                <v-col
                cols="12"
                md="4"
                >   
                    <v-file-input
                        v-model="selectedtemplatefile"
                        show-size
                        label="Vorlagedatei ändern"
                        @change="onUpload"
                        @click:clear="clearSelectedTemplate"
                        prepend-icon="mdi-cloud-upload"
                    > 
                    </v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                cols="12"
                md="4"
                >
                <div class="form-group">
                    <b-form-checkbox
                        id="checkbox-startRow"
                        v-model="ptemplatemapping.startRow"
                        name="checkbox-startRow"
                        value="1"
                        unchecked-value="2"
                        >
                        Ohne Header
                    </b-form-checkbox>
                </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    md="4"
                    >
                    <div class="form-group">
                        <v-autocomplete
                        v-model="ptemplatemapping.artikelmatchingcolumn"
                        :items="matchingscolumns"
                        label="Stammartikelabgleich mit der Spalte"
                        persistent-hint
                        required
                        ></v-autocomplete>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <!--div class="vue-csv-uploader-part-two"-->
                        <!--div class="vue-csv-mapping"-->
                            <table :class="tableClass">
                                <slot name="thead">
                                    <thead>
                                    <tr>
                                        <th width="20">Erforderliche Felder</th>
                                        <th width="30">Artikel-Felder</th>
                                        <th width="50">CSV-Felder</th>
                                    </tr>
                                    </thead>
                                </slot>
                                <tbody>
                                <tr v-for="(field, key) in fieldsToMap" :key="key" v-if="disabledNextButton == false">
                                    <td>{{ field.label }}:</td>
                                    <td>
                                        <v-autocomplete v-if="field.key == 'product_id'"
                                        v-model="ptemplatemapping.product_id_artikel_column"
                                        :items="pcart04mapFields"
                                        item-value="key"
                                        item-text="label"
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <!---v-autocomplete
                                        v-model="map[field.key]"
                                        :items="firstRow"
                                        item-value="key"
                                        item-text="column"
                                        ></v-autocomplete-->
                                        <select :class="tableSelectClass" :name="`csv_uploader_map_${key}`" v-model="map[field.key]">
                                            <option :value="-1" >Ignorieren</option>
                                            <option v-for="(column, key) in firstRow" :key="key" :value="key">{{ column }}</option>
                                        </select>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        <!--/div-->
                    <!--/div -->
                </v-row>
            <v-row>
                <b-form-group>
                        <b-button href='#' variant='secondary' v-on:click="back">Zurück</b-button>
                        <slot name="submit" :submit="submit" v-if="ptemplatemapping.templatefile && sample && form.csv && ptemplatemapping.filename && ptemplatemapping.filepath">
                            <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                        </slot>
                    </b-form-group>
            </v-row>
        </v-form>
    </div>
    </v-app>
</template>
<script>
    import { drop, every, forEach, get, isArray, map, set } from 'lodash';
    import axios from 'axios';
    import Papa from 'papaparse';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/platformtemplatemapping'
            },
            mapFields: {
                //required: true
                type: Array,
                default: () => {
                    return [
                    {"key":"product_id", "label":"Artikelnummer", "columnnr":"product_id_columnNr"},
                    {"key":"tyre24_id", "label":"Tyre24-ID", "columnnr":"tyre24_id_columnNr"},
                    {"key":"ean", "label":"EAN", "columnnr":"ean_columnNr"},
                    {"key":"hersteller", "label":"Hersteller", "columnnr":"hersteller_columnNr"},
                    {"key":"suppliernumber1", "label":"Suppliernumber1", "columnnr":"suppliernumber1_columnNr"},
                    {"key":"suppliercountry1", "label":"Suppliercountry1", "columnnr":"suppliercountry1_columnNr"},
                    {"key":"stock1", "label":"Bestand1", "columnnr":"stock1_columnNr"},
                    {"key":"price1", "label":"Preis1", "columnnr":"price1_columnNr"},
                    {"key":"suppliernumber2", "label":"Suppliernumber2", "columnnr":"suppliernumber2_columnNr"},
                    {"key":"suppliercountry2", "label":"Suppliercountry2", "columnnr":"suppliercountry2_columnNr"},
                    {"key":"stock2", "label":"Bestand2", "columnnr":"stock2_columnNr"},
                    {"key":"price2", "label":"Preis2", "columnnr":"price2_columnNr"},
                    {"key":"suppliernumber3", "label":"Suppliernumber3", "columnnr":"suppliernumber3_columnNr"},
                    {"key":"suppliercountry3", "label":"Suppliercountry3", "columnnr":"suppliercountry3_columnNr"},
                    {"key":"stock3", "label":"Bestand3", "columnnr":"stock3_columnNr"},
                    {"key":"price3", "label":"Preis3", "columnnr":"price3_columnNr"},
                    {"key":"suppliernumber4", "label":"Suppliernumber4", "columnnr":"suppliernumber4_columnNr"},
                    {"key":"suppliercountry4", "label":"Suppliercountry4", "columnnr":"suppliercountry4_columnNr"},
                    {"key":"stock4", "label":"Bestand4", "columnnr":"stock4_columnNr"},
                    {"key":"price4", "label":"Preis4", "columnnr":"price4_columnNr"},
                    {"key":"suppliernumber5", "label":"Suppliernumber5", "columnnr":"suppliernumber5_columnNr"},
                    {"key":"suppliercountry5", "label":"Suppliercountry5", "columnnr":"suppliercountry5_columnNr"},
                    {"key":"stock5", "label":"Bestand5", "columnnr":"stock5_columnNr"},
                    {"key":"price5", "label":"Preis5", "columnnr":"price5_columnNr"},
                    {"key":"suppliernumber6", "label":"Suppliernumber6", "columnnr":"suppliernumber6_columnNr"},
                    {"key":"suppliercountry6", "label":"Suppliercountry6", "columnnr":"suppliercountry6_columnNr"},
                    {"key":"stock6", "label":"Bestand6", "columnnr":"stock6_columnNr"},
                    {"key":"price6", "label":"Preis6", "columnnr":"price6_columnNr"},
                    {"key":"suppliernumber7", "label":"Suppliernumber7", "columnnr":"suppliernumber7_columnNr"},
                    {"key":"suppliercountry7", "label":"Suppliercountry7", "columnnr":"suppliercountry7_columnNr"},
                    {"key":"stock7", "label":"Bestand7", "columnnr":"stock7_columnNr"},
                    {"key":"price7", "label":"Preis7", "columnnr":"price7_columnNr"},
                    {"key":"suppliernumber8", "label":"Suppliernumber8", "columnnr":"suppliernumber8_columnNr"},
                    {"key":"suppliercountry8", "label":"Suppliercountry8", "columnnr":"suppliercountry8_columnNr"},
                    {"key":"stock8", "label":"Bestand8", "columnnr":"stock8_columnNr"},
                    {"key":"price8", "label":"Preis8", "columnnr":"price8_columnNr"},
                    {"key":"suppliernumber9", "label":"Suppliernumber9", "columnnr":"suppliernumber9_columnNr"},
                    {"key":"suppliercountry9", "label":"Suppliercountry9", "columnnr":"suppliercountry9_columnNr"},
                    {"key":"stock9", "label":"Bestand9", "columnnr":"stock9_columnNr"},
                    {"key":"price9", "label":"Preis9", "columnnr":"price9_columnNr"},
                    {"key":"suppliernumber10", "label":"Suppliernumber10", "columnnr":"suppliernumber10_columnNr"},
                    {"key":"suppliercountry10", "label":"Suppliercountry10", "columnnr":"suppliercountry10_columnNr"},
                    {"key":"stock10", "label":"Bestand10", "columnnr":"stock10_columnNr"},
                    {"key":"price10", "label":"Preis10", "columnnr":"price10_columnNr"}]
                }
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            parseConfig: {
                type: Object,
                default() {
                    return {};
                }
            },
            headers: {
                default: null
            },
            loadBtnText: {
                type: String,
                default: "Laden"
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            autoMatchFields: {
                type: Boolean,
                default: false
            },
            autoMatchIgnoreCase: {
                type: Boolean,
                default: false
            },
            tableClass: {
                type: String,
                default: "table"
            },
            checkboxClass: {
                type: String,
                default: "form-check-input"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            fileMimeTypes: {
                type: Array,
                default: () => {
                    return ['text/csv','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
                }
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
            canIgnore: {
               type: Boolean,
               default: false,
            }
        },

        data:() => ({
            form: {
                  csv: null,
                },
            filenameRules: [
                v => !!v || 'Dateiname ist erforderlich',
            ],
            pcart04mapFields:[],
            fieldsToMap: [],
            pcart04fieldsToMap: [],
            map: {},
            hasHeaders: true,
            csv: null,
            sample: null,
            isValidFileMimeType: false,
            selectedtemplatefile:null,
            directories: [],
            templatefile: null,
            filedata:null,
            matchingscolumns: ['AREAN', 'ART24ID'],
            ptemplatemapping:{
                id: 0,
                platformtemplate_id: 0,
                templatefile: null,
                templateFile_MimeType: '',
                delimiter:';',
                startRow: 1,
                artikelmatchingcolumn:'AREAN',
                templateheader:null,
                csvdata: null
            }
        }),

        created(){
            this.fieldsToMap = this.mapFields;
            this.ptemplatemapping = this.editdata;
            this.ptemplatemapping.product_id_artikel_column = this.editdata.product_id_artikel_column;
            this.getArtikelColumns();
            this.getDirectories();
            this.updateData(true);
            this.sample = this.ptemplatemapping.templateheader;
            this.csv = this.sample;
            this.form.csv = this.buildMappedCsv();
        },

        methods: {

            updateData(isinit) {
                    this.map['product_id'] = isinit ? this.editdata.product_id_columnNr  : -1;
                    this.map['tyre24_id'] = isinit ? this.editdata.tyre24_id_columnNr  : -1;
                    this.map['ean'] = isinit ? this.editdata.ean_columnNr : -1;
                    this.map['hersteller'] = isinit ? this.editdata.hersteller_columnNr : -1;
                    for (let index = 1; index < 11; index++) {
                        this.map['suppliernumber'+index] = isinit ? this.editdata['suppliernumber'+index+'_columnNr']: -1;
                        this.map['suppliercountry'+index] = isinit ? this.editdata['suppliercountry'+index+'_columnNr'] : -1;
                        this.map['stock'+index] = isinit ? this.editdata['stock'+index+'_columnNr'] : -1;
                        this.map['price'+index] = isinit ? this.editdata['price'+index+'_columnNr'] : -1;
                    }
                },
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/platforms/"+this.ptemplatemapping.platformtemplate.platforms_id+"/platformtemplates";
                //window.location = location.protocol + "//" + location.hostname + "/admin/platformtemplates/"+this.ptemplatemapping.platformtemplate.platforms_id;
                //window.location = location.protocol + "//" + location.hostname + "/admin/platformtemplate/"+this.ptemplatemapping.platformtemplate_id+"/edit";
            },

            buildMappedCsv() {
                    const _this = this;
                    let csv = this.csv;
                    let newRow = {};
                    let i = 0;
                    return map(_this.map, (column, field) => {
                        if(column < 0){
                            set(newRow, field, 'Ignorieren');
                        }
                        else {
                            // get(csv, column);
                            set(newRow, field, get(_this.ptemplatemapping.templateheader, column));
                        }
                        set(newRow, get(this.fieldsToMap, i).columnnr, column);
                        i++;
                        return newRow;
                    });
                },

            async clearSelectedTemplate(){
                const _this = this;
                _this.ptemplatemapping.templatefile = null;
                _this.sample = null;
                _this.csv = null;
                _this.updateData(false);
                _this.ptemplatemapping.templateheader=null;

            },
            getArtikelColumns: function(){
                axios.get('/admin/getallartikelcolumns')
                .then(function (response) {
                    this.pcart04mapFields = response.data;
                }.bind(this));
            },
            getDirectories: function(){
                axios.get('/admin/supplierstocktemplatedirectories')
                .then(function (response) {
                    this.directories = response.data;
                }.bind(this));
            },
            onUpload() {
                const _this = this;
                try {
                    if (_this.selectedtemplatefile) {
                       
                        _this.clearSelectedTemplate();
                        _this.ptemplatemapping.templatefile = _this.selectedtemplatefile.name
                        Papa.parse(_this.selectedtemplatefile, {
                        header : false,
                        preview : 5,
                        skipEmptyLines: true,
                        delimiter: "", // auto-detect
                        newline: "",	 // auto-detect
                        quoteChar: '"',
                        escapeChar: '"',
                        delimitersToGuess: [';', ',', '\t', '|', Papa.RECORD_SEP, Papa.UNIT_SEP],
                        beforeFirstChunk: function(chunk) {
                            return chunk;
                        },
                        complete : function(results,parser) {
                            let list = [];
                            _this.sample = set(list, 0, get(get(results, "data"), 0));
                            _this.csv = _this.sample;
                        },
                        });
                    }
                } catch (error) {
                    
                }
            },
            
            toast(toaster, append = false) {
                this.$bvToast.toast(`Bitte selektieren Sie die Felder`, {
                title: `Toaster ${toaster}`,
                toaster: toaster,
                solid: true,
                appendToast: append
                })
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if(change)
                    {
                        window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            submit() {
                const _this = this;
                this.form.csv = this.buildMappedCsv();
                let csvdata = this.form.csv.shift();
                this.$emit('input', this.form.csv);
                this.ptemplatemapping.csvdata = csvdata;
                axios.post(this.url,
                    this.ptemplatemapping,
                ).then(response => {
                   // _this.callback(response);
                   this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            validFileMimeType() {
               let file  = this.$refs.csv.files[0];
                //const mimeType = file.type === "" ? mimeTypes.lookup(file.name) : file.type;
                if (file) {
                    this.fileSelected = true;
                    //this.isValidFileMimeType = this.validation ? this.validateMimeType(mimeType) : true;
                    this.isValidFileMimeType = true;
                } else {
                    this.isValidFileMimeType = !this.validation;
                    this.fileSelected = false;
                }
            },
        },
        watch: {

        },
        computed: {
            firstRow() {
                if(this.ptemplatemapping.templateheader == null){
                        this.ptemplatemapping.templateheader = get(this, "sample.0");
                    }
                return this.ptemplatemapping.templateheader;
            },

            pcart04Row() {
                return get(this, "sample.0");
            },
            showErrorMessage() {
                return this.fileSelected && !this.isValidFileMimeType;
            },

            disabledNextButton() {
                return this.ptemplatemapping.templatefile == null;
            },
        },
    };
</script>