<template>
    <div class="vue-csv-uploader">
        <v-app>
        <v-form
            ref="form"
        >
            <v-row>
                <v-col
                cols="12"
                sm="6"
                md="3"
                >
                    <v-text-field
                    v-model="platformName"
                    label="Platform"
                    disabled
                    ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                sm="6"
                md="3"
                >
                    <v-text-field
                    v-model="platformtemplateName"
                    label="Vorlagename"
                    disabled
                    ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                sm="6"
                md="3"
                >  
                    <v-text-field
                    v-model="platformtemplateCountry"
                    label="Land"
                    disabled
                    ></v-text-field>
                </v-col>    
            </v-row>
                <div class="table-responsive">
                    <!-- https://smarttutorials.net/dynamically-add-or-remove-table-row-using-vuejs/ -->
                    <button type='button' class="btn btn-info" @click="addNewRow">
                        <i class="fas fa-plus-circle"></i>
                        Mindestpreis Hinzufügen
                    </button>

                    <table class="table" ref="mindestpreistable">
                        <thead>
                            <tr>
                                <th scope="col">Min. Menge</th>
                                <th scope="col">Preisabschlag pro Std.</th>
                                <th scope="col">Preisabschlag Typ</th>
                                <th scope="col">Preisaufschlag wenn Bestpreis nicht unterboten werden kann</th>
                                <th scope="col">Warengr. von</th>
                                <th scope="col">Warengr. bis</th>
                                <th scope="col text-right">Gebühren in €</th>
                                <th scope="col">Gebühren in %</th>
                                <th scope="col text-right">Preis (Gewinn)</th>
                                 <th scope="col text-right">Einzelpreis (Gewinn)</th>
                                <th scope="col">Startdatum</th>
                                <th scope="col">Enddatum</th>
                                 <!--th scope="col">Land</th-->
                                <th scope="col">Beschreibung</th>
                                <th scope="col">#</th>
                            </tr>
                        </thead>
                        <tr v-for="(mindestpreis, k) in mindestpreise" :key="k">
                            <td>
                                <v-text-field
                                hide-details="auto"
                                type="number" min="1"
                                 step="1" 
                                 v-model="mindestpreis.anzahl"
                                 :rules="[rules.required]"
                                 required
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                hide-details="auto"
                                type="number" min="1"
                                 step="0.01" 
                                 v-model="mindestpreis.pricemarkdown"
                                 :rules="[rules.required]"
                                 required
                                ></v-text-field>
                            </td>
                            <td>
                                <v-select
                                v-model="mindestpreis.pricemarkdowntyp"
                                :items="pricemarkdowntypeoptions"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                hide-details="auto"
                                type="number" min="1"
                                 step="0.01" 
                                 v-model="mindestpreis.pricemarkup"
                                 :rules="[rules.required, rules.price]"
                                 required
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                hide-details="auto"
                                type="number" min="1"
                                 step="1" 
                                 v-model="mindestpreis.warengruppeVon"
                                 required
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                hide-details="auto"
                                type="number" min="1"
                                 step="1" 
                                 v-model="mindestpreis.warengruppeBis"
                                 required
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                :rules="[rules.required, rules.price]"
                                hide-details="auto"
                                type="number" min="1"
                                 step="1" 
                                 v-model="mindestpreis.preis"
                                 required
                                ></v-text-field>
                            </td>
                             <td>
                                <v-text-field
                                :rules="[rules.required, rules.price]"
                                hide-details="auto"
                                type="number" min="1"
                                 step="1" 
                                 v-model="mindestpreis.prozent"
                                 required
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                :rules="[rules.price]"
                                hide-details="auto"
                                type="number" min="1"
                                 step="1" 
                                 v-model="mindestpreis.preisgewinn"
                                 required
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                :rules="[rules.price]"
                                hide-details="auto"
                                type="number" min="1"
                                    step="1" 
                                    v-model="mindestpreis.einzelpreisgewinn"
                                    required
                                ></v-text-field>
                                </td>
                            <td>
                                <v-menu
                                    ref="validdatefrommenu_"
                                    v-model="mindestpreis.validdatefrommenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="mindestpreis.display_validdatefrom"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @blur="mindestpreis.display_validdatefrom = parseDate(mindestpreis.validDateFrom)"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="mindestpreis.validDateFrom"
                                        no-title
                                        @input="mindestpreis.validdatefrommenu = false"
                                        @change="mindestpreis.display_validdatefrom = parseDate(mindestpreis.validDateFrom)"
                                    >
                                        <v-btn text @click="mindestpreis = onClear(mindestpreis)">Löschen</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </td>
                            <td>
                                <v-menu
                                    ref="validdateuntilmenu"
                                    v-model="mindestpreis.validdateuntilmenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="mindestpreis.display_validdateuntil"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @blur="mindestpreis.display_validdateuntil = parseDate(mindestpreis.validDateUntil)"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="mindestpreis.validDateUntil"
                                        no-title
                                        @input="mindestpreis.validdateuntilmenu = false"
                                        @change="mindestpreis.display_validdateuntil = parseDate(mindestpreis.validDateUntil)"
                                    ></v-date-picker>
                                </v-menu>
                                <!--v-text-field
                                hide-details="auto"
                                 v-model="mindestpreis.validDateUntil"
                                ></v-text-field-->
                            </td>
                            <!--td>
                                <b-form-select
                                v-model="mindestpreis.country"
                                :options="countryoptions"
                               
                                value-field="code"
                                text-field="country"
                                disabled-field="notEnabled"
                                required
                                ></b-form-select>
                            </td-->
                             <td>
                                <v-text-field
                                hide-details="auto"
                                 v-model="mindestpreis.description"
                                ></v-text-field>
                                 <!--input class="form-control" type="text"  v-model="mindestpreis.description" /-->
                            </td>
                            <td scope="row" class="trashIconContainer">
                                <i class="far fa-trash-alt fa-2x" style="color: red;" @click="deleteRow(k, mindestpreis)"></i>
                            </td>
                        </tr>
                    </table>
                </div>
                <v-btn-toggle
                    borderless
                >
                    <v-btn value="left" @click="back">
                        Zurück
                    </v-btn>
                    <slot name="submit" :submit="submit">
                        <v-btn
                            color="success"
                            @click="submit"
                        >
                            Speichern
                        </v-btn>
                    </slot>
                </v-btn-toggle>
        </v-form>
        </v-app>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/platformtemplatemindestpreis'
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            pricemarkdowntypeoptions:['PREIS', 'PROZENT'],
            rules: {
                required: value => !!value || 'Erforderlich',
                counter: value => value.length <= 20 || 'Max 20 characters',
                price: value => {
                    return /^\d+(\.\d+)*$/.test(value) || 'Ungültig'
                },
            },
            enableSubmit: false,
            countryoptions:{},
            platformtemplate_id:0,
            platformName: '',
            platformtemplateName: '',
            platformtemplateCountry:'',
            mindestpreise: [{
                platformtemplate_id:'',
                anzahl: 0,
                pricemarkdown:'',
                pricemarkdowntyp:'',
                pricemarkup: '',
                warengruppeVon: '',
                warengruppeBis: '',
                preis: 0,
                prozent: 0,
                preisgewinn: 0,
                einzelpreisgewinn: 0,
                validDateFrom:'',
                validDateUntil: '',
                country: '',
                description: '',
                display_validdatefrom:'',
                validdatefrommenu:false,
                display_validdateuntil:'',
                validdateuntilmenu:false,
            }],
        }),

        created() {
            this.getCountries();
            this.mindestpreise = [];
            this.platformName = this.editdata.platform.name;
            this.platformtemplate_id = this.editdata.id;
            this.platformtemplateName = this.editdata.name;
            this.platformtemplateCountry = this.editdata.country;
            this.getPlatformtemplateMindestpreise();
        },

        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/platforms/"+this.editdata.platforms_id+"/platformtemplates";
            },
            getPlatformtemplateMindestpreise: function(){
                axios.get('/admin/platformtemplatemindestpreis/getplatformtemplatemindestpreise/'+this.platformtemplate_id)
                .then(function (response) {
                    this.mindestpreise = response.data;
                }.bind(this));
            },
            getCountries: function(){
                axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
            },
            addNewRow() {
                this.mindestpreise.unshift({
                    id:0,
                    platformtemplate_id: this.platformtemplate_id,
                    anzahl: 0,
                    pricemarkdown: '',
                    pricemarkdowntyp: '',
                    pricemarkup: '',
                    warengruppeVon: '',
                    warengruppeBis: '',
                    preis: 0.00,
                    prozent: 0,
                    preisgewinn: 0.00,
                    einzelpreisgewinn: 0.00,
                    validDateFrom:'',
                    validDateUntil: '',
                    country: '',
                    description: '',
                    display_validdatefrom:'',
                    validdatefrommenu:false,
                    display_validdateuntil:'',
                    validdateuntilmenu:false,
                });
            },
            deleteRow(index, mindestpreis) {
                var idx = this.mindestpreise.indexOf(mindestpreis);
                console.log(idx, index);
                if (idx > -1) {
                    this.mindestpreise.splice(idx, 1);
                   if(mindestpreis.id > 0)
                   {
                       axios.delete('/admin/platformtemplatemindestpreis/'+mindestpreis.id
                       ).then(response => {
                        console.log(response);
                        this.showMsgBoxOne(response, false);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                   }
                }
            },

            submit() {
                const _this = this;
                axios.post(this.url,
                    this.mindestpreise,
                ).then(response => {
                   this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                    console.log(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            showMsgBoxOne(callback, change) {
                if(callback.data.data.code > 200){
                        this.$bvModal.msgBoxOk(callback.data.data.message, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                    return;
                }
                //this.$bvModal.msgBoxOk(callback.data)
                this.$bvModal.msgBoxOk(callback.data.data.message, {
                        title: 'MELDUNG',
                        headerBgVariant: 'success',
                        headerTextVariant: 'light',
                        titleVariant: 'success',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'success',
                        size:'lg',
                        okVariant: 'success'
                         })
                .then(value => {
                    if(change)
                    {
                        window.location = location.protocol + "//" + location.hostname + "/admin/platformtemplatemindestpreis/"+this.platformtemplate_id;
                    }
                })
                .catch(err => {
                   
                })
            },

            checkPrice(price){
                return /^\d+(\.\d+)*$/.test(price);
            },

            checkDate(date){
                return /^\d+(\.\d\d)*$/.test(date);
            },
            makeErrorToast() {
                this.$bvToast.toast('Test', {
                title: 'Fehler'
                })
            },
            showError(mp) {
                if(mp.prozent == null)
                 {
                     this.$bvModal.msgBoxOk('Prozent darf nicht leer sein!'+ mp, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                 }
            },

            formatDate (date) {
                    if (!date) return null
                    const [year, month, day] = date.split('-')
                    return `${month}/${day}/${year}`
                },
               parseDate (date) {
                console.log(date);
                    if (!date) return null
                    const [year, month, day] = date.split('-')
                    return `${day}.${month}.${year}`
                   // const [month, day, year] = date.split('/')
                    //return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
                },
                onClear(mindestpreis) {
                    mindestpreis.validDateFrom = null;
                    mindestpreis.validDateUntil = null;
                    mindestpreis.display_validdatefrom ='';
                    mindestpreis.validdatefrommenu=false;
                    mindestpreis.display_validdateuntil='';
                    mindestpreis.validdateuntilmenu=false;
                    return mindestpreis;
                },

        },
        watch: {

        },
        computed: {

            preisState() {
                 if(this.mindestpreise.preis == null)
                    return false;
                 return this.mindestpreise.preis.length > 0 ? this.checkPrice(this.mindestpreise.preis) : false;
            },
            prozentState() {
                 if(this.mindestpreise.prozent == null)
                    return false;
                 return this.mindestpreise.prozent.length > 0 ? this.checkPrice(this.mindestpreise.prozent) : false;
            },
            validDateFromState() {
                if(this.mindestpreise.validDateFrom == null)
                    return true;
                return this.mindestpreise.validDateFrom.length > 0 ? this.checkDate(this.mindestpreise.validDateFrom) : false;
            },
            validDateUntilState() {
                if(this.mindestpreise.validDateUntil == null)
                    return true;
                return this.mindestpreise.validDateUntil.length > 0 ? this.checkDate(this.mindestpreise.validDateUntil) : false;
            }
        },
    };
</script>
