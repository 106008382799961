<template>
    <v-tooltip bottom >
      <template v-slot:activator="{ on }">
        <v-btn
        v-on="on"
        :loading="exporting"
        :disabled="exporting"
        
        class="ma-2 white--text"
        fab
        @click="exporter = 'exporting'"
        x-small
        color="blue-grey"
        >
            <v-icon>
            mdi-file-export
            </v-icon>
        </v-btn>
      </template>
      <span>Als Excel-Datei exportieren</span>
    </v-tooltip>
    
</template>
<script>
export default {
    props: {
        url: null,
        exporturl: {
            type: String,
            default: ''
        },
    },
    data: () => ({
        exporter: null,
        exporting: false,
    }),
    methods:{
        getExport() {
            axios.get(this.exporturl)
                .then(function (response) {
                    this.exporting = false
                    this.exporter = null;
                    this.$bvModal.msgBoxOk(response.data.msg, {
                        title: response.data.title,
                        headerBgVariant: response.data.typ,
                        headerTextVariant: 'light',
                        titleVariant: 'success',
                        size: 'lg',
                    })
                }
                .bind(this));
        },
    },
    watch: {
        exporter() {
            const e = this.exporter
            this[e] = !this[e]
            if (!this.exporting) return
            this.getExport();
        },
    }
}

</script>