<template>
    <v-app>
        <v-row>
            <v-data-table :headers="headers" :items="platformstocks" :items-per-page="itemsPerPage"
                class="platformstock-table" :server-items-length="totalPlatformstocks" :loading="loading"
                :options.sync="options" locale="de" :footer-props="footerProps" multi-sort>
                <template v-slot:header.articlenumber="{ header }">
                        {{ header.text }}
                        <v-menu v-model="articlenumberfilter" offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon small :color="articlenumber ? 'primary' : ''">
                                        mdi-filter
                                    </v-icon>
                                </v-btn>
                            </template>
                            <div style="background-color: white; width: 280px">
                                <v-text-field v-model="articlenumber" class="pa-4" type="text"
                                    label="Geben Sie den Suchbegriff ein" :autofocus="true"></v-text-field>
                                <v-btn text @click="articlenumberfilter = false">
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                                <v-btn @click="articlenumber = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                                    <v-icon dark>
                                        mdi-filter-off
                                    </v-icon>
                                </v-btn>
                                <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                    <v-icon dark>
                                        mdi-database-search
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-menu>
                    </template>
                <template v-slot:header.ean="{ header }">
                    {{ header.text }}
                    <v-menu v-model="eanfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="ean ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="ean" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="eanfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="ean = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.arwg="{ header }">
                    {{ header.text }}
                    <v-menu v-model="arwgfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="arwg ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="arwg" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="arwgfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="arwg = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.ARHE="{ header }">
                    {{ header.text }}
                    <v-menu v-model="herstellerfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="hersteller ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="hersteller" class="pa-4" type="text"
                                label="Geben Sie den Suchbegriff ein" :autofocus="true"></v-text-field>
                            <v-btn text @click="herstellerfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="hersteller = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:item.price="{ item }">
                        {{ convertDoubleToCurrency(item.price) }}
                </template>
                <template v-slot:item.compareprice="{ item }">
                    {{ convertDoubleToCurrency(item.compareprice) }}
                </template>
                <template v-slot:item.pricepercentage="{ item }">
                    {{ item.pricepercentage + ' %' }}
                </template>
            </v-data-table>
        </v-row>
    </v-app>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformstocks'
        },
        location_url: {
            type: String,
            default: '/admin/platformstocks/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        disableOk: false,
        platformtemplates: [],
        supplierstocktemplates: [],
        platformstocks: [],
        totalPlatformstocks: 0,
        itemsPerPage: 50,
        loading: true,
        conditions: [],
        filters: [],
        options: {
            page: 1,
            itemsPerPage: 50,
            // sortBy: 'DESC',
            sortDesc: [false],
            mustSort: false,
            multiSort: false,
            sort: '',
            filters: null
        },
        articlenumber: '',
        platform: '',
        ean: '',
        hersteller: '',
        arwg: '',
        articlenumberfilter: false,
        platformfilter: false,
        eanfilter: false,
        arwgfilter: false,
        herstellerfilter: false,
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Plattformvorlage', value: 'platformtemplatename' },
            { text: 'ARAINR', value: 'articlenumber' },
            { text: 'EAN', value: 'ean' },
            { text: 'ARHE', value: 'ARHE' },
            { text: 'ARWG', value: 'arwg' },
            { text: 'Bestand', value: 'stock' },
            { text: 'Preis', value: 'price' },
            { text: 'Vergleichspreis', value: 'compareprice' },
            { text: 'Preisprozentsatz', value: 'pricepercentage' },
            { text: 'Bestandsdatei', value: 'platformstocktemplatefilename' },
            { text: 'Bestand-Importiert am', value: 'createddate' },
            { text: 'Vergleichdatei', value: 'platformstockcomparetemplatefilename' },
            { text: 'vergleich-Importiert am', value: 'comparecreateddate' },
        ],
        footerProps: {
            'items-per-page-options': [50, 500, 1000, -1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
        }
    }),
    created() {
        this.filters = new Array();
        this.getPlatformtemplates();
        this.getSupplierStockTemplates();
    },
    mounted() {
        window.platformstocks = this;
    },
    methods: {
        async clearFilter() {
            this.filter();
        },
        convertDoubleToCurrency(value) {
            return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
        },
        async getPlatformtemplates() {
            axios.get('/admin/getallplatformtemplates')
                .then(function (response) {
                    this.platformtemplates = response.data;
                }.bind(this));
        },
        async getSupplierStockTemplates() {
            axios.get('/admin/getsuppliers')
                .then(function (response) {
                    this.supplierstocktemplates = response.data;
                }.bind(this));
        },
        async getPlatformStocks() {
            this.loading = true
            this.options.sort = this.options.sortBy[0];
            axios.get('/admin/getallplatformstocks/', { params: this.options })
                .then(function (response) {
                    this.platformstocks = response.data.data;
                    this.totalPlatformstocks = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                }.bind(this));
        },
        getColor(value) {
            if (value > 0) return "red"
            else return "green"
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + this.location_url;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        filter() {
            this.filters.length = 0;
            if(this.articlenumber){
                this.filters.push(new Array('tplatformstock.articlenumber', '=', this.articlenumber));
            }
            if (this.ean) {
                this.filters.push(new Array('tplatformstock.ean', '=', this.ean));
            }
            if (this.hersteller) {
                this.filters.push(new Array('tplatformstock.ARHE', 'like', this.hersteller));
            }
            if (this.arwg) {
                this.filters.push(new Array('tplatformstock.arwg', '=', this.arwg));
            }
            this.options.filters = this.filters;
            this.getPlatformStocks();
        }
    },
    watch: {
        options(newValue, oldValue) {
            let cont = false
            if (oldValue.page !== newValue.page) cont = true
            if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
            if (oldValue.sortBy !== newValue.sortBy) cont = true
            if (oldValue.sortDesc !== newValue.sortDesc) cont = true
            if (cont) {
                this.getPlatformStocks();
            }
        },
    },
    computed: {
       
    },
};
</script>
