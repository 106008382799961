<template>
    <div class="vue-csv-uploader">
        <div class="form">
            <div class="form">
                <b-form-group role="form-group">
                    <label for="input-path">Pfad:</label>
                    <b-form-input
                    id="input-path"
                    v-model="targetpath"
                    :state="targetpathState"
                    aria-describedby="input-path-help input-path-feedback"
                    placeholder="Pfad"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-path-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-live">Dateiname:</label>
                    <b-form-input
                    id="input-live"
                    v-model="targetfilename"
                    :state="filenameState"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Stock Dateiname"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <b-form-group role="form-group">
                        <label for="input-ftpAdress">FTP-Adresse:</label>
                        <b-form-input
                        id="input-ftpAdress"
                        v-model="ftpserver"
                        :state="ftpServerState"
                        aria-describedby="input-ftpAdress-help input-ftpAdress-feedback"
                        placeholder="FTP-Adresse"
                        trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-ftpAdress-feedback">
                        Erforderlich
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group role="form-group">
                        <label for="input-ftpUser">FTP-Benutzername:</label>
                        <b-form-input
                        id="input-ftpUser"
                        v-model="ftpuser"
                        :state="ftpUserState"
                        aria-describedby="input-ftpAdress-help input-ftpUser-feedback"
                        placeholder="FTP-Benutzername"
                        trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-ftpUser-feedback">
                        Erforderlich
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group role="form-group" sm="3">
                        <label for="input-ftpPassword">FTP-Passwort:</label>
                        <b-row>
                            <b-col sm="6">
                                <b-form-input
                                id="input-ftpPassword"
                                :type="visibility"
                                v-model="ftppassword"
                                :state="ftpPasswordState"
                                aria-describedby="input-ftpPassword-help input-ftpPassword-feedback"
                                placeholder="FTP-Passwort"
                                trim
                                ></b-form-input>
                            </b-col>
                                <span class="input-group-text" @click="changeVisibility" sm="1">
                                    <i class="fa" :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']" aria-hidden="true"></i>
                                </span>
                        </b-row>
                        <b-form-invalid-feedback id="input-ftpPassword-feedback" >
                        Erforderlich
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group role="form-group">
                        <label for="input-ftpPath">FTP-Verzeichnis:</label>
                        <b-form-input
                        id="input-ftpPath"
                        v-model="ftpdirectory"
                        aria-describedby="input-ftpPath-help input-ftpPath-feedback"
                        placeholder="FTP-Verzeichnis"
                        trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-ftpPath-feedback">
                        Erforderlich
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group role="form-group">
                        <label for="input-ftpFilename">FTP-Dateiname:</label>
                        <b-form-input
                        id="input-ftpFilename"
                        v-model="ftpfilename"
                        aria-describedby="input-ftpFilename-help input-ftpFilename-feedback"
                        placeholder="FTP-Dateiname"
                        trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-ftpFilename-feedback">
                        Erforderlich
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-desc">Beschreibung:</label>
                    <b-form-input
                    id="input-desc"
                    v-model="description"
                    placeholder="Beschreibung"
                    trim
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <b-button href='#' variant='secondary' v-on:click="back">Zurück</b-button>
                    <slot name="submit" :submit="submit" v-if="ftpserver && ftpuser && ftpfilename">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
            </div>
        </div>
    </div>
</template>
<script>
 import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/cartomakstocksupplierconfig'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
                id: null,
                ftpserver: null,
                ftpuser: null,
                ftppassword: null,
                ftpdirectory: null,
                ftpfilename: null,
                targetpath: null,
                targetfilename: null,
                description: '',
                enableSubmit: false,
                showPassword: false,
                visibility:'password',
        }),

        created() {
            this.id = this.editdata.id;
            this.ftpserver = this.editdata.ftpserver;
            this.ftpuser = this.editdata.ftpuser;
            this.ftppassword = this.editdata.ftppassword;
            this.ftpdirectory = this.editdata.ftpdirectory;
            this.ftpfilename = this.editdata.ftpfilename;
            this.targetpath = this.editdata.targetpath;
            this.targetfilename = this.editdata.targetfilename;
            this.description = this.editdata.description;
        },

        methods: {
             back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/platforms";
            },
            submit() {
                const _this = this;
                 let formData = new FormData();

                formData.append('id', this.id);
                formData.append('ftpserver',  this.ftpserver);
                formData.append('ftpuser',  this.ftpuser);
                formData.append('ftppassword',  this.ftppassword);
                formData.append('ftpdirectory',  this.ftpdirectory);
                formData.append('ftpfilename',  this.ftpfilename);
                formData.append('targetpath',  this.targetpath);
                formData.append('targetfilename', this.targetfilename);
                formData.append('description', this.description );

                var cartomakconfig = {
                    'id': this.id,
                    'ftpserver':  this.ftpserver,
                    'ftpuser':  this.ftpuser,
                    'ftppassword':  this.ftppassword,
                    'ftpdirectory':  this.ftpdirectory,
                    'ftpfilename':  this.ftpfilename,
                    'targetpath':  this.targetpath,
                    'targetfilename': this.targetfilename,
                    'description': this.description
                }

                axios.post(this.url,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                   console.log(response);
                   this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            showMsgBoxOne(callback) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    window.location = location.protocol + "//" + location.hostname + "/admin/cartomakstocksupplierconfig";
                })
                .catch(err => {
                    // An error occurred
                })
            },
            changeVisibility: function() {
                this.showPassword = !this.showPassword
                if(this.showPassword)
                {
                     this.visibility= 'text'
                }else{
                     this.visibility= 'password'
                }
            },
        },
        watch: {

        },
        computed: {
            targetpathState() {
                if(this.targetpath == null)
                    return false
                return this.targetpath.length > 3 ? true : false
            },
            filenameState() {
                if(this.targetfilename == null)
                    return false
                return this.targetfilename.length > 3 ? true : false
            },
            ftpServerState(){
                 return this.ftpserver == null ? false : true
            },
            ftpUserState(){
                 return this.ftpuser == null ? false : true
            },
            ftpPasswordState(){
                 return this.ftppassword == null ? false : true
            },
        },
    };
</script>
