<template>
    <v-app>
        <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="modelValue"
        >
        <template v-slot:default="dialog">
        <v-card>
            <v-toolbar
            color="primary"
            dark
            >Opening from the top</v-toolbar>
            <v-card-text>
            <div class="text-h2 pa-12">Hello world!</div>
            </v-card-text>
            <v-card-actions class="justify-end">
            <v-btn
                text
                @click="dialog.value = false"
            >Close</v-btn>
            </v-card-actions>
        </v-card>
        </template>
        </v-dialog>
</v-app>
</template>
<script>
export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
 /*props: {
        messagedialog: false,
      value: Array,
      url: {
          type: String,
          default: '/admin/suppliers'
      },
      callback: {
              type: Function,
              default: () => ({})
          },
          catch: {
              type: Function,
              default: () => ({})
          },
          finally: {
              type: Function,
              default: () => ({})
          },
  },*/
  data: () => ({
    editedItem: {
      id: 0,
      supplierName: null,
      supplierType: 0,
      isMainSupplier: 0,
      isShippingMainStandard: 0,
      isShippingFromMainStandard: 0,
      description: null,
      lastAction: 1,

    },
    defaultItem: {
      id: 0,
      supplierName: null,
      supplierType: 0,
      isMainSupplier: 0,
      isShippingMainStandard: 0,
      isShippingFromMainStandard: 0,
      description: null,
      lastAction: 1,
    },

  }),

  computed: {
    formTitle () {
      return 'Meldung';
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
  },

  created () {
    console.log("MessageDialog created");
    
  },

  methods: {

    close () {
      this.modelValue = false
      this.$nextTick(() => {
        this.editedItem = this.defaultItem; //Object.assign({}, this.defaultItem)
      })
    },
  },
}
</script>