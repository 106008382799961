<template>
    <div class="row">
        <b-modal
            id="modal-prevent-closing"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">

                <b-form-group
                label="Lagerbestand"
                label-for="supllierstockname-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Lagerbestand ist pflicht" 
                >
                    <b-form-input
                        id="supllierstockname-input"
                        v-model="editdata.supplier.supplierName" 
                        required
                        disabled
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Lieferantenvorlage"
                label-for="supplier-input"
                label-cols="8"
                label-cols-lg="4" 
                >
                    <b-form-select
                    id="supplier-input"
                    v-model="supplierstockssumup.sumup_supplierstocktemplate_id"
                    :options="suppliers"
                    value-field="id"
                    text-field="templateName"
                    disabled-field="notEnabled"
                    :state="supplierState"
                    aria-describedby="input-live-help input-live-feedback"
                    required
                    ></b-form-select>
                    <b-form-invalid-feedback id="input-live-feedback">
                        Lieferantenvorlage erforderlich.
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                label="Wenn die Menge unter"
                label-for="quantityless-input"
                label-cols="8"
                label-cols-lg="4"
                >
                    <b-form-input
                        id="quantityless-input"
                        v-model="supplierstockssumup.quantityless" 
                        :state="quantitylessState"
                        type="number"
                        min="1"
                        value="1"
                        step="1" 
                        pattern="^(?:[0-9]\d*|\d)$"
                        aria-describedby="input-live-help quantityless-input-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="quantityless-input-live-feedback">
                            Menge erforderlich.
                        </b-form-invalid-feedback>
                    </b-form-group>
                <b-form-group
                label="Beschreibung"
                label-for="description-input"
                label-cols="8"
                label-cols-lg="4"
                >
                <b-form-input
                    id="description-input"
                    v-model="supplierstockssumup.description" 
                ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Status"
                label-for="checkbox-1"
                label-cols="8"
                label-cols-lg="4"
                >
                    <b-form-checkbox
                    id="checkbox-1"
                    v-model="supplierstockssumup.lastaction"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                    >
                    <div>{{(supplierstockssumup.lastaction > 0 ? 'Aktiviert' : 'Deaktiviert')}}</div>
                    </b-form-checkbox>
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped supplierstockssumups-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Lagerbestand</th>
                        <th>Lieferant</th>
                        <th>Wenn die Menge unter</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/supplierstockssumups'
            },
            location_url: {
                type: String,
                default: '/admin/supplierstockssumup/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Summierung hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            suppliers:{},
            onlynumberspattern : /^[0-9]*$/,
            supplierstockssumup: {
                id: 0,
                supplierstocktemplate_id: 0,
                sumup_supplierstocktemplate_id: null,
                description: null,
                lastaction: 1
            },
           
        }),

        created() {
            this.supplierstockssumup.supplierstocktemplate_id = this.editdata.id;
            this.getSuppliers();
            this.getSupplierStocksSumUps('/admin/supplierstockssumup/getsupplierstockssumups/'+ this.editdata.id);
        },
        mounted() {
            window.supplierstockssumups = this;
        },
        methods: {
            async getSupplierStocksSumUps(address) {
                 $(document).ready(function() {
                    var table = $('.supplierstockssumups-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'supplierstocktemplatename', name: 'supplierstocktemplatename'},
                            {data: 'supplier', name: 'Lieferant'},
                            {data: 'quantityless', name: 'Menge'},
                            {data: 'description', name: 'description'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'action'},
                        ],
                    });
                });
            },

            getSuppliers: function(){
                axios.get('/admin/getallsupplierstocktemplates')
                .then(function (response) {
                    this.suppliers = response.data.filter(this.filterSupplier) ;
                }.bind(this));
            },
            filterSupplier(supplier) {
              return supplier.id != this.supplierstockssumup.supplierstocktemplate_id;
            },
            getEditSupplierStocksSumup(edit_id){
                axios.get('/admin/supplierstockssumups/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Summierung des Lagerbestandes bearbeiten"
                    this.supplierstockssumup = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                       window.location = location.protocol + "//" + location.hostname + this.location_url + this.editdata.id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getDeleteSupplierStocksSumUp(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie diese Summierung des Lagerbestandes wirklich löschen??', {
                title: 'WARNUNG',
                headerVariant: "warning",
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neue Summierung hinzufügen";
        this.supplierstockssumup.id = 0;
        this.supplierstockssumup.supplierstocktemplate_id = this.editdata.id;
        this.supplierstockssumup.sumup_supplierstocktemplate_id = 0;
        this.supplierstockssumup.description = null;
        this.supplierstockssumup.lastaction = 1;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.supplierstockssumup.id > 0){
            axios.put(this.url+'/'+this.supplierstockssumup.id,
                this.supplierstockssumup,
            ).then(response => {
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            console.log(this.supplierstockssumup);
            axios.post(this.url,
                this.supplierstockssumup,
            ).then(response => {
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
      }
        },
        watch: {

        },
        computed: {
            quantitylessState(){
                return this.supplierstockssumup.quantityless == null ? false : true;
            },
            supplierState(){
                return this.supplierstockssumup.sumup_supplierstocktemplate_id < 1 ? false : true;
            },
        },
    };
</script>
