<template>
    <v-app>
    <div class="vue-csv-uploader">
        <div class="form">
            <div class="vue-csv-uploader-part-one">
                <b-form-group role="form-group">
                    <label for="input-suppliername">Lieferantenname:</label>
                    <b-form-input
                    id="input-suppliername"
                    type="text"
                    v-model="suppliername"
                    :state="suppliernameState"
                    aria-describedby="input-suppliername-help input-suppliername-feedback"
                    placeholder="Lieferantenname"
                    trim
                     min="1"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-suppliername-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-suppliernumber">Lieferantennummer:</label>
                    <b-form-input
                    id="input-suppliernumber"
                    type="text"
                    v-model="suppliernumber"
                    :state="suppliernumberState"
                    aria-describedby="input-suppliernumber-help input-suppliernumber-feedback"
                    placeholder="Lieferantennummer"
                    trim
                    min="2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-suppliernumber-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="select-suppliertype">Lieferantentyp:</label>
                    <b-form-select
                        v-model="suppliertype"
                        :options="suppliertypeoptions"
                        class="mb-3"
                        value-field="value"
                        text-field="name"
                        disabled-field="notEnabled"
                    ></b-form-select>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="isMainSupplier-button">Hauptlieferant:</label>
                        <b-form-checkbox v-model="isMainSupplier" name="isMainSupplier-button" id="isMainSupplier-button" size="lg" switch></b-form-checkbox>
                        <small><b-icon icon="exclamation-triangle-fill"  variant="warning"></b-icon> <i style="color:red;">Nur <b>Inter-Drive</b> darf als Hauptlieferant markiert werden!</i></small> 
                </b-form-group>
                <b-form-group role="form-group" v-if="isMainSupplier">
                    <label for="select-suppliertype">Mindestpreis-Rabat %:</label>
                    <b-form-input
                    id="input-mindestpreisrabat"
                    v-model="mindestpreisrabat"
                    aria-describedby="input-mindestpreisrabat-help input-mindestpreisrabat-feedback"
                    trim
                    ></b-form-input>
                </b-form-group>
                <div class="form-group">
                    <label>Dateipfad des Lieferantes:</label>
                    <v-autocomplete
                    :items="directories"
                    v-model="directory"
                    label="Dateipfad"
                    ></v-autocomplete>
                    <!--v-select
                    :items="directories"
                    label="Dateipfad"
                    v-model="directory"
                    v-if="suppliertype < 2" 
                    ></v-select-->
                    <!--v-select :options="directories" label="Pfad" v-model="directory" :value="myValue" v-if="suppliertype < 2" >
                        <option selected>Pfad auswählen</option>
                    </v-select-->
                    <b-form-input
                            id="input-directory"
                            type="text"
                            v-model="directory"
                            aria-describedby="input-directory-help input-directory-feedback"
                            placeholder="0"
                            trim
                            v-if="suppliertype > 1"
                        ></b-form-input>
                </div>
                <div role="group">
                    <label for="input-live">Dateiname:</label>
                    <b-form-input
                    id="input-live"
                    v-model="filename"
                    :state="nameState"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Stock Dateiname"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </div>
                 <b-form-group>
                    <label for="input-validitytime">Gültigkeit der Lieferantendaten (In Stunden)*:</label>
                    <b-form-input
                        id="input-validitytime"
                        type="number"
                        v-model="validitytime"
                        :state="validitytimeState"
                        aria-describedby="input-validitytime-help input-validitytime-feedback"
                        placeholder="0"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-validitytime-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <div class="form-group" v-if="suppliertype < 2">
                    <label>Vorlage des Lieferantes:</label>
                    <v-select
                    :items="supplierfiles"
                    label="Vorlage"
                    v-model="selectedtemplatefile"
                    ></v-select>
                    <!--v-select :options="supplierfiles" label="Pfad" v-model="selectedtemplatefile" :value="myValue"  >
                        <option selected>Vorlage auswählen</option>
                    </v-select-->
                </div>
                <!-- div class="form-group csv-import-file">
                     <label>Musterdatei (csv-Datei oder xsxl-Datei oder txt-Datei):</label>
                    <input ref="csv" type="file" @change.prevent="validFileMimeType" :class="inputClass" name="csv">
                    <slot name="error" v-if="showErrorMessage">
                        <div class="invalid-feedback d-block">
                            Der Dateityp ist ungültig
                        </div>
                    </slot>
                </div -->
                <div class="form-group" v-if="suppliertype < 2">
                    <b-form-checkbox
                        id="checkbox-startRow"
                        v-model="startRow"
                        name="checkbox-startRow"
                        value="1"
                        unchecked-value="2"
                        >
                        Ohne Header
                    </b-form-checkbox>
                </div>
                <div class="form-group" v-if="suppliertype < 2">
                    <slot name="next" :load="load">
                        <button type="submit" :disabled="disabledNextButton" :class="buttonClass" @click.prevent="load">
                            {{ loadBtnText }}
                        </button>
                    </slot>
                </div>
            </div>
            <div class="vue-csv-uploader-part-two">
                <div class="vue-csv-mapping" v-if="sample || suppliertype == 2">
                    <table :class="tableClass" v-if="suppliertype < 2">
                        <slot name="thead">
                            <thead>
                            <tr>
                                <th>Erforderliche Felder</th>
                                <th>Artikel-Felder</th>
                                <th>Musterfelder</th>
                            </tr>
                            </thead>
                        </slot>
                        <tbody>
                        <tr v-for="(field, key) in fieldsToMap" :key="key">
                            <td>{{ field.label }}:</td>
                            <td>
                                <v-autocomplete :items="pcart04mapFields" label="" v-model="artikel_preis_ColumnNr" clearable v-if="field.key == 'Preis'" item-value="key" item-text="label">
                                    <option selected>Auswählen</option>
                                </v-autocomplete>
                                <v-autocomplete :items="pcart04mapFields" label=""  v-model="artikel_tyresInParcel_ColumnNr" clearable v-if="field.key == 'Reifen_Pro_Paket'" item-value="key" item-text="label">
                                    <option selected>Auswählen</option>
                                </v-autocomplete>
                            </td>
                            <td>
                                <select :class="tableSelectClass" :name="`csv_uploader_map_${key}`" v-model="map[field.key]">
                                    <option :value="-1">Ignorieren</option>
                                    <option v-for="(column, key) in firstRow" :key="key" :value="key">{{ column }}</option>
                                </select>
                            </td>
                            <!--td>
                                <b-form-input
                                id="input-preiszuschlag"
                                type="text"
                                v-model="preiszuschlag"
                                aria-describedby="input-preiszuschlag-help input-preiszuschlag-feedback"
                                placeholder="Preiszuschlag"
                                v-if="field.key == 'Preis'"
                                ></b-form-input>
                            </td-->
                        </tr>
                        </tbody>
                    </table>
                    <!--b-form-group
                        id="fieldset-1"
                        description=" "
                        label="Skonto Type:"
                        label-for="select-skonto"
                        >
                        <b-row>
                            <b-col cols="6" sm="3">
                                <b-form-select  id="select-skonto" v-model="skontotype" :options="skontotypeoptions"></b-form-select>
                            </b-col>
                        </b-row>
                        </b-form-group>
                        <b-form-group>
                        <label for="input-skonto" v-if="skontotype == 1">Skontopreis:</label>
                        <label for="input-skonto" v-if="skontotype == 2">Skontoprozent:</label>
                        <b-form-input
                            id="input-skonto"
                            type="text"
                            v-model="skonto"
                            aria-describedby="input-skonto-help input-skonto-feedback"
                            placeholder="0"
                            trim
                            v-if="skontotype > 0"
                        ></b-form-input>
                    </b-form-group -->
                    <b-form-group label="Matchingsart" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            v-model="matchingselected"
                            :options="matchingoptions"
                            :aria-describedby="ariaDescribedby"
                            name="plain-inline"
                            plain
                        ></b-form-radio-group>
                    </b-form-group>
                     <b-form-tags v-model="itemvalues" :limit="limit"  no-outer-focus class="mb-2" v-if="matchingselected == '3'">
                        <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                            <b-input-group class="mb-2">
                            <b-form-input
                                v-bind="inputAttrs"
                                v-on="inputHandlers"
                                placeholder="Hersteller-Artikelnummer eingeben und die mit der Eingabetaste hinzufügen"
                                class="form-control"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button @click="addTag()" variant="primary">Hinzufügen</b-button>
                            </b-input-group-append>
                            </b-input-group>
                            <div class="d-inline-block" style="font-size: 1.5rem;">
                            <b-form-tag
                                v-for="tag in tags"
                                @remove="removeTag(tag)"
                                :key="tag"
                                :title="tag"
                                :variant="tagVariant"
                                class="mr-1"
                            >{{ tag }}</b-form-tag>
                            </div>
                        </template>
                    </b-form-tags>
                    <div class="form-group" v-if="suppliertype == 2 || (selectedtemplatefile && suppliername && suppliernumber && form.csv && filename && directory
                        && ((matchingselected == '3' && itemvalues.length > 0) || matchingselected != '3'))">
                        <slot name="submit" :submit="submit">
                            <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</v-app>
</template>

<script>
    import { drop, every, forEach, get, isArray, map, set } from 'lodash';
    import axios from 'axios';
    import Papa from 'papaparse';
    //import mimeTypes from "mime-types";

    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default:  '/admin/supplierstocktemplate/'
            },
            mapFields: {
                //required: true
                type: Array,
                default: () => {
                    return ['Artikelnummer', 'Preis', 'Menge', 'Reifen_Pro_Paket', 'EAN']
                }
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            parseConfig: {
                type: Object,
                default() {
                    return {};
                }
            },
            headers: {
                default: null
            },
            loadBtnText: {
                type: String,
                default: "Laden"
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            autoMatchFields: {
                type: Boolean,
                default: false
            },
            autoMatchIgnoreCase: {
                type: Boolean,
                default: false
            },
            tableClass: {
                type: String,
                default: "table"
            },
            checkboxClass: {
                type: String,
                default: "form-check-input"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            fileMimeTypes: {
                type: Array,
                default: () => {
                    return ['text/csv','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
                }
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
            canIgnore: {
               type: Boolean,
               default: false,
            }
        },

        data: () => ({
            form: {
                  csv: null,
                },
                id: 0,
                suppliername: '',
                suppliernumber:'',
                suppliertype: '0',
                isMainSupplier: false,
                mindestpreisrabat:0,
                suppliertypeoptions: $func.getSupplierTypeOptions(),
                fieldsToMap: [],
                pcart04fieldsToMap: [],
                map: {},
                hasHeaders: true,
                csv: null,
                sample: null,
                isValidFileMimeType: false,
                fileSelected: false,
                directories: [],
                directory: null,
                filename: '',
                supplierfiles:[],
                selectedtemplatefile:'',
                templatefile: null,
                file: null,
                enableSubmit: false,
                myValue: '10_Continental/stock',
                startRow: 2,
                matchingselected:'1',
                matchingoptions: [
                           // { text: 'mit Cartomak', value: '1' },
                            { text: 'mit EAN', value: '2' },
                            { text: 'mit Hersteller-Artikelnummern', value: '3' }
                        ],
                itemvalues: [],
                limit:3,
                preiszuschlag:null,
                artikel_preis_ColumnNr: -1,
                artikel_tyresInParcel_ColumnNr: -1,
                selectedcountries: [],
                countryoptions: null,
                skontotype: 0,
                skontotypeoptions: [
                    { value: 0, text: 'Kein Skonto' },
                    { value: 1, text: 'Skonto als Preis' },
                    { value: 2, text: 'Skonto als Prozent' }
                ],
                skonto: '0',
                validitytime:0,
        }),
        created() {
            this.getArtikelColumns();
            this.getDirectories();
            this.id = this.editdata.id;
            this.hasHeaders = this.headers;
            this.suppliername = this.editdata.supplierName;
            this.suppliernumber = this.editdata.supplierNumber;
            this.suppliertype = this.editdata.supplierType;
            this.isMainSupplier = this.editdata.isMainSupplier > 0 ? true : false;
            if(this.suppliertype > 1){
                this.suppliertypeoptions = [ { value: '2', name: 'MASTER'}]
               // this.suppliertypeoptions.push( })
            }
            this.directory = this.editdata.supplierPath;
            this.filename = this.editdata.filename;
            this.fileSelected = true;
            this.templatefile = this.editdata.templateFile;
            this.selectedtemplatefile = this.editdata.templateFile;
            this.startRow = this.editdata.withoutHeader;
            this.isValidFileMimeType = true;
            this.source = this.editdata.source;
            this.matchingselected = this.editdata.matchingsart;
            this.itemvalues =  this.editdata.itemsnumber == null ? [] : this.editdata.itemsnumber.split(',').map(s => s.trim());
            if(this.editdata.countries != null)
            {
                this.selectedcountries = this.editdata.countries.split(",");
            }
            this.artikel_preis_ColumnNr = this.editdata.artikel_preis_ColumnNr;
            this.artikel_tyresInParcel_ColumnNr = this.editdata.artikel_tyresInParcel_ColumnNr;
            this.skontotype = this.editdata.skontotype;
            this.skonto = this.editdata.skonto;
            this.validitytime = this.editdata.validitytime;
            this.load();

            if (isArray(this.mapFields)) {
                this.fieldsToMap = map(this.mapFields, (item) => {
                    return {
                        key: item,
                        label: item
                    };
                });
            } else {
                this.fieldsToMap = map(this.mapFields, (label, key) => {
                    return {
                        key: key,
                        label: label
                    };
                });
            }

            if (isArray(this.pcart04mapFields)) {
                this.pcart04fieldsToMap = map(this.pcart04mapFields, (item) => {
                    return {
                        key: item,
                        label: item
                    };
                });
            } else {
                this.pcart04mapFields = map(this.pcart04mapFields, (label, key) => {
                    return {
                        key: key,
                        label: label
                    };
                });
            }

            this.map['Artikelnummer'] = this.editdata.supplierArticleNumber_ColumnNr;
            this.map['Preis'] = this.editdata.price_ColumnNr;
            this.map['Menge'] = this.editdata.quantity_ColumnNr	;
            this.map['Reifen_Pro_Paket'] = this.editdata.tyresInParcel_ColumnNr;
            this.map['EAN'] = this.editdata.EAN_ColumnNr;
            this.form.csv = this.buildMappedCsv();
            this.load();
            this.getTemplateFiles();
        },

        methods: {
            changeDirectory: function(value) {
                this.getTemplateFiles();
            },
            getArtikelColumns: function(){
                axios.get('/admin/getallartikelcolumns')
                .then(function (response) {
                    this.pcart04mapFields = response.data;
                }.bind(this));
            },
            getDirectories: function(){
            axios.get('/admin/supplierstocktemplatedirectories')
              .then(function (response) {
                 this.directories = response.data;
              }.bind(this));
            },
            getTemplateFiles: function(){
                this.supplierfiles = [];
                this.selectedtemplatefile = '';
               /* if(this.directory.includes('/stock') == false)
                {
                    return;
                }
                
                
                */
                var dir = this.directory.substring(0, this.directory.indexOf('/'));
                console.log("DIR: "+dir)
                axios.get('/admin/supplierstocktemplatefiles/'+dir)
                .then(function (response) {
                    this.supplierfiles = response.data;
                    if(this.supplierfiles.length == 1){
                        this.selectedtemplatefile = this.supplierfiles[0];
                        this.load();
                    }
                }.bind(this)).catch(response => {
                });
            },
            submit() {
                const _this = this;
                this.form.csv = this.buildMappedCsv();
                let csvdata = this.form.csv.shift();
                this.$emit('input', this.form.csv);
                const artikel_price = this.pcart04mapFields.find(element => element.key == this.artikel_preis_ColumnNr);
                const artikel_tyresInParcel = this.pcart04mapFields.find(element => element.key == this.artikel_tyresInParcel_ColumnNr);
                var stocktemplate = {
                    'id': this.id,
                    'suppliername':  this.suppliername,
                    'suppliernumber':  this.suppliernumber,
                    'suppliertype': this.suppliertype,
                    'isMainSupplier': this.isMainSupplier,
                    'mindestpreisrabat': this.mindestpreisrabat,
                    'directory':  this.directory,
                    'filename':  this.filename,
                    'templatefile': this.templatefile,
                    'startRow': this.startRow,
                    'delimiter': this.delimiter,
                    'csvdata': csvdata,
                    'matchingsoption': this.matchingselected,
                    'itemsnumber': this.itemvalues,
                    'artikel_preis': artikel_price == null ? null : artikel_price.label,
                    'artikel_preis_ColumnNr': this.artikel_preis_ColumnNr,
                    'artikel_tyresInParcel': artikel_tyresInParcel == null ? null : artikel_tyresInParcel.label,
                    'artikel_tyresInParcel_ColumnNr' : artikel_tyresInParcel == null ? -1 : artikel_tyresInParcel.key,
                    'skontotype': this.skontotype,
                    'skonto': this.skonto,
                    'selectedtemplatefile': this.selectedtemplatefile,
                    'validitytime': this.validitytime,
                    'preiszuschlag': this.preiszuschlag,
                    'templatecolumns': this.firstRow
                }
                axios.put(this.url + this.id,
                    stocktemplate,
                ).then(response => {
                   // _this.callback(response);
                   this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },

            buildMappedCsv() {
                const _this = this;
                let csv = this.hasHeaders ? drop(this.csv) : this.csv;
                return map(csv, (row) => {
                    let newRow = {};
                    forEach(_this.map, (column, field) => {
                        console.log('Column: '+column)
                        console.log('Field: '+field);
                        console.log('Row: '+row);
                        set(newRow, field, get(row, column)+'#'+column);
                        console.log('NewRow: '+field+ ' => '+get(row, column));
                    });

                    return newRow;
                });
            },
            validFileMimeType() {
               let file  = this.$refs.csv.files[0];
                //const mimeType = file.type === "" ? mimeTypes.lookup(file.name) : file.type;

                if (file) {
                    this.fileSelected = true;
                    this.isValidFileMimeType = true; //this.validation ? this.validateMimeType(mimeType) : true;
                } else {
                    this.isValidFileMimeType = !this.validation;
                    this.fileSelected = false;
                }
            },
            /*
            validateMimeType(type) {
                return this.fileMimeTypes.indexOf(type) > -1;
            },
            */
            load() {
                const _this = this;
               // this.enableSubmit = false;
                this.readFile((output) => {
                    _this.sample = get(Papa.parse(output, { preview: 2, skipEmptyLines: true }), "data");
                    _this.csv = get(Papa.parse(output, { skipEmptyLines: true }), "data");
                });
            },
            readFile(callback){
                //var tempfilename = this.selectedtemplatefile.substring(this.selectedtemplatefile.lastIndexOf('/')+1);
                axios.get('/admin/gettemplate/'+ this.selectedtemplatefile)
                    .then(function (response) {
                        //Felder der CSV-Tabelle
                         console.log('Column: '+response.data);
                        callback(response.data);
                    }.bind(this));
            },

            toggleHasHeaders() {
                this.hasHeaders = !this.hasHeaders;
            },
            makeId(id) {
                return `${id}${this._uid}`;
            },

            showMsgBoxOne(callback) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
                })
                .catch(err => {
                    // An error occurred
                })
            },
        },
        watch: {
            map: {
                deep: true,
                handler: function (newVal) {
                    let hasAllKeys = Array.isArray(this.mapFields) ? every(this.mapFields, function (item) {
                        return newVal.hasOwnProperty(item);
                    }) : every(this.mapFields, function (item, key) {
                        return newVal.hasOwnProperty(key);
                    });

                    if (hasAllKeys) {
                        this.form.csv = this.buildMappedCsv();
                        //this.submit();
                        //this.enableSubmit = true;
                    }else{
                        //this.enableSubmit = false;
                    }
                }
            },
            sample(newVal, oldVal) {
                if(this.autoMatchFields){
                    if(newVal !== null){
                        this.fieldsToMap.forEach(field => {
                            newVal[0].forEach((columnName, index) => {
                                if(this.autoMatchIgnoreCase === true){
                                    if(field.label.toLowerCase().trim() === columnName.toLowerCase().trim()){
                                        this.$set(this.map, field.key, index);
                                    }
                                } else{
                                    if(field.label.trim() === columnName.trim()){
                                        this.$set(this.map, field.key, index);
                                    }
                                }
                            });
                        });
                    }
                }
            }
        },
        computed: {
            firstRow() {
                return get(this, "sample.0");
            },
            showErrorMessage() {
                return this.fileSelected && !this.isValidFileMimeType;
            },
            disabledNextButton() {
                return !this.isValidFileMimeType;
            },
            nameState() {
                return this.filename.length > 2 ? true : false
            },
            suppliernameState() {
                return this.suppliername.length > 1 ? true : false
            },
            suppliernumberState() {
                return this.suppliernumber.length > 3 ? true : false
            },
             validitytimeState() {
                return this.validitytime > 0;
            }

        },
    };
</script>
