<template>
    <v-app>
            <v-data-table
            :headers="headers"
            :items="notificationssubscribers"
            :items-per-page="itemsPerPage"
            class="notificationssubscribers-table"
            :server-items-length="totalNotifiactionssubscribers"
            :loading="loading"
            :options.sync="options"
            locale="de"
            :footer-props="footerProps"
            multi-sort
            >
            <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Benachrichtigungsteilnehmer</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                class="mx-2"
                fab
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
              <v-icon dark>
                mdi-plus
              </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-app-bar
                dark
                color="success"
              >
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              </v-app-bar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                    <v-row>
                        <v-col
                        cols="12"
                        >
                        <v-text-field
                            v-model="editedItem.firstName"
                            label="Vorname"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-col
                      cols="12"
                    >
                    <v-text-field
                        v-model="editedItem.lastName"
                        label="Nachname"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                    <v-text-field
                        v-model="editedItem.email"
                        label="E-Mail*"
                        :rules="rules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    >
                    <v-text-field
                    label="Beschreibung"
                    v-model="editedItem.description" 
                    hide-details="auto"
                    ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                        <v-switch
                        v-model="editedItem.lastAction"
                        :label="`Teilnehmer ${editedItem.lastAction > 0 ? 'aktiviert': 'deaktiviert'}`"
                        color="green"
                        ></v-switch>
                    </v-col>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="close"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    @click="save"
                    v-if="editedItem.email"
                    :disabled="!valid"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="notificationsdialog"
            max-width="500px"
          >
            <v-card>
              <v-app-bar
                dark
                color="success"
              >
              <v-card-title>
                <span class="text-h5">Nachrichten abonnieren</span>
              </v-card-title>
              </v-app-bar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-container fluid
                        v-for="(value, key, index) in notificationconfigs" :key="index"
                        >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                            v-on="on"
                            v-bind="attrs">
                                <v-switch
                                v-model="editedItem.notifications"
                                color="primary"
                                :label="value.name"
                                :value="value.tag"
                                ></v-switch>
                            </div>
                        </template>
                        <span>{{ value.description }}</span>
                        </v-tooltip>
                        </v-container>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="close"
                >
                  Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    @click="save"
                    v-if="editedItem.email"
                    :disabled="!valid"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Teilnehmer löschen möchten?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
            <template v-slot:header.firstName="{ header }">
                {{ header.text }}
                <v-menu  v-model="firstnamefilter" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small :color="firstname ? 'primary' : ''">
                        mdi-filter
                    </v-icon>
                    </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                    <v-text-field
                    v-model="firstname"
                    class="pa-4"
                    type="text"
                    label="Geben Sie den Suchbegriff ein"
                    :autofocus="true"
                    ></v-text-field>
                <v-btn
                text
                @click="firstnamefilter = false"
                >
                <v-icon dark>
                    mdi-close
                    </v-icon>
                </v-btn>
                    <v-btn
                    @click="firstname = '',filter()"
                    small
                    text
                    color="red"
                    >
                    <v-icon dark>
                    mdi-filter-off
                    </v-icon>
                    </v-btn>
                    <v-btn
                    @click="filter"
                    small
                    text
                    color="green"
                    class="ml-2 mb-2"
                    fixed
                    right
                    >
                    <v-icon dark>
                    mdi-database-search
                    </v-icon>
                </v-btn>
                </div>
                </v-menu>
            </template>
            <template v-slot:header.lastName="{ header }">
                {{ header.text }}
                <v-menu  v-model="lastnamefilter" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small :color="lastname ? 'primary' : ''">
                        mdi-filter
                    </v-icon>
                    </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                    <v-text-field
                    v-model="lastname"
                    class="pa-4"
                    type="text"
                    label="Geben Sie den Suchbegriff ein"
                    :autofocus="true"
                    ></v-text-field>
                <v-btn
                text
                @click="lastnamefilter = false"
                >
                <v-icon dark>
                    mdi-close
                    </v-icon>
                </v-btn>
                    <v-btn
                    @click="lastname = '',filter()"
                    small
                    text
                    color="red"
                    >
                    <v-icon dark>
                    mdi-filter-off
                    </v-icon>
                    </v-btn>
                    <v-btn
                    @click="filter"
                    small
                    text
                    color="green"
                    class="ml-2 mb-2"
                    fixed
                    right
                    >
                    <v-icon dark>
                    mdi-database-search
                    </v-icon>
                </v-btn>
                </div>
                </v-menu>
            </template>
            <template v-slot:header.email="{ header }">
                {{ header.text }}
                <v-menu  v-model="emailfilter" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small :color="email ? 'primary' : ''">
                        mdi-filter
                    </v-icon>
                    </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                    <v-text-field
                    v-model="email"
                    class="pa-4"
                    type="text"
                    label="Geben Sie den Suchbegriff ein"
                    :autofocus="true"
                    ></v-text-field>
                <v-btn
                text
                @click="emailfilter = false"
                >
                <v-icon dark>
                    mdi-close
                    </v-icon>
                </v-btn>
                    <v-btn
                    @click="email = '',filter()"
                    small
                    text
                    color="red"
                    >
                    <v-icon dark>
                    mdi-filter-off
                    </v-icon>
                    </v-btn>
                    <v-btn
                    @click="filter"
                    small
                    text
                    color="green"
                    class="ml-2 mb-2"
                    fixed
                    right
                    >
                    <v-icon dark>
                    mdi-database-search
                    </v-icon>
                </v-btn>
                </div>
                </v-menu>
            </template>
            <template v-slot:item.notifications="{ item }">
                <v-chip
                class="ma-2"
                color="success"
                text-color="green"
                outlined
                >
                    {{item.notifications === null ? 0 : item.notifications.length}}
                    <v-icon right>
                        mdi-bell
                    </v-icon>
                </v-chip>
            </template>
            <template v-slot:item.lastAction="{ item }">
                <v-switch
                v-model="item.lastAction"
                color="green"
                disabled
                label=""
                ></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                        class="mr-2"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="editItem(item)"
                        >
                        mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                        class="mr-2"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="editNotifications(item)"
                        >
                        mdi-bell-cog
                        </v-icon>
                    </template>
                    <span>Nachrichten abonnieren</span>
                </v-tooltip>
                
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItem(item)"
                        color="red"
                    >
                        mdi-delete
                    </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>    
        </v-data-table>
    </v-app>
</template>
<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/notificationsubscribers'
            },
            location_url: {
                type: String,
                default: '/admin/notificationsubscribers/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },
        data: () => ({
            valid: true,
            messagedialog: false,
            dialog: false,
            notificationsdialog:false,
            dialogDelete: false,
            disableOk:false,
            notificationconfigs:[],
            notificationssubscribers:[],
            totalNotifiactionssubscribers: 0,
            itemsPerPage: 50,
            loading: true,
            conditions: [],
            filters: [],
            options: {
              page: 1,
              itemsPerPage: 50,
             // sortBy: 'DESC',
              sortDesc: [false],
              mustSort: false,
              multiSort: false,
              sort:'',
              filters:null
            },
            firstnamefilter:false,
            lastnamefilter:false,
            emailfilter:false,
            firstname:'',
            lastname:'',
            email:'',
            rules: [
                value => !!value || 'Erforderlich.',
                value => (value || '').length <= 50 || 'Max 50 characters',
                value => {
                   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Ungültige E-Mail!'
                },
            ],
            headers: [
                    {text: 'id', value: 'id'}, 
                    {text: 'Vorname', value: 'firstName'},
                    {text: 'Nachname', value: 'lastName'},
                    {text: 'E-Mail', value: 'email'},
                    {text: 'Abonierte Nachrichten', value: 'notifications'},
                    {text: 'Beschreibung', value: 'description'},
                    {text: 'Status', value: 'lastAction'},
                    { text: 'Aktionen', value: 'actions', sortable:false },
                ],
            footerProps: {
                'items-per-page-options': [50, 500, 1000, -1],
                'show-current-page': true,
                'show-first-last-page': true,
                disableItemsPerPage: false,
                itemsPerPageText: 'Einträge pro Seite',
                itemsPerPageAllText: 'Alle',
            },

            editedItem: {
                id: 0,
                firstName: null,
                lastName: null,
                email: null,
                notifications:[],
                description: null,
                lastAction: 1,
            },
            defaultItem: {
                id: 0,
                firstName: null,
                lastName: null,
                email: null,
                notifications:[],
                description: null,
                lastAction: 1,
            },
        }),
        created() {
            this.getNotificationconfigs();
        },
        methods: {
            getNotificationconfigs() {
                axios.get('/admin/getnotificationconfigs/')
                .then(function (response) {
                    this.notificationconfigs = response.data;
                }.bind(this));
            },
            async getNotifiactionssubscribers() {
                this.loading = true
                this.options.sort = this.options.sortBy[0];
                axios.get('/admin/getnotificationsubscribers/', {params:this.options})
                .then(function (response) {
                    this.notificationssubscribers = response.data.data;
                    this.totalNotifiactionssubscribers = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                }.bind(this));
            },
            filter() {
              this.filters.length = 0;
              if (this.firstname) {
                  this.filters.push(new Array('firstName','LIKE',this.firstname+'%'));
              }
              if (this.lastname) {
                  this.filters.push(new Array('lastName','LIKE',this.lastname+'%'));
              }
              if (this.email) {
                  this.filters.push(new Array('email','LIKE',this.email+'%'));
              }
              this.options.filters = this.filters;
              this.getNotifiactionssubscribers();
            },

            editItem (item) {
                axios.get(this.url+'/'+item.id+'/edit')
                    .then(function (response) { 
                        this.editedItem = response.data;
                        if (this.editedItem.notifications == null) {
                            this.editedItem.notifications = [];
                        }
                        this.dialog = true
                    }.bind(this)); 
            },

            editNotifications(item) {
                axios.get(this.url+'/'+item.id+'/edit')
                    .then(function (response) { 
                        this.editedItem = response.data;
                        if(this.editedItem.notifications == null)
                        {
                            this.editedItem.notifications = [];
                        }
                        this.notificationsdialog = true
                    }.bind(this)); 
            },
            deleteItem (item) {
                axios.get(this.url+'/'+item.id+'/edit')
                    .then(function (response) { 
                        this.editedItem = response.data;
                        this.dialogDelete = true
                    }.bind(this));
            },

            deleteItemConfirm () {
                axios.delete(this.url+'/'+this.editedItem.id)
                .then(response => {
                this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
                this.closeDelete()
            },

            close () {
                this.dialog = false
                this.notificationsdialog = false,
                this.$nextTick(() => {
                this.editedItem = this.defaultItem; //Object.assign({}, this.defaultItem)
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                this.editedItem = this.defaultItem;//Object.assign({}, this.defaultItem)
                })
            },

            save () {
                const _this = this;
                this.validate();
                if(this.editedItem.id > 0){
                    axios.put(this.url+'/'+this.editedItem.id,
                        this.editedItem,
                    ).then(response => {
                    _this.callback(response);
                        this.showMsgBoxOne(response, true);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }else{
                    axios.post(this.url,
                        this.editedItem,
                    ).then(response => {
                        _this.callback(response);
                        this.showMsgBoxOne(response, true);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }
                // Hide the modal manually
                this.$nextTick(() => {
                // this.resetModal();
                    this.$bvModal.hide('modal-prevent-closing')
                })
                this.close()
            },

            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if(change)
                    {
                        this.getNotifiactionssubscribers();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            validate () {
                this.$refs.form.validate()
            },
           
        },
        watch: {
            dialog (val) {
                val || this.close()
            },
            notificationsdialog(val) {
                val || this.close()
            },

            dialogDelete (val) {
                val || this.closeDelete()
            },
            options(newValue, oldValue) {
                let cont = false
                if(oldValue.page!==newValue.page)cont=true
                if(oldValue.itemsPerPage!==newValue.itemsPerPage)cont=true
                if(oldValue.sortBy!==newValue.sortBy)cont=true
                if(oldValue.sortDesc!==newValue.sortDesc)cont=true
                if(cont){
                  this.getNotifiactionssubscribers();
                }
            },
        },
        computed: {
            formTitle () {
                return this.editedItem.id === 0 ? 'Teilnehmer hinzufügen' : 'Teilnehmer bearbeiten'
            },
        },
    }
</script>