<template>
    <div class="row">
        <b-modal
            id="modal-platformownoffermarkup"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
                label="Plattform"
                label-for="platform-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Platform ist pflicht" 
            >
                <b-form-input
                    id="platform-input"
                    v-model="editdata.name" 
                    required
                    readonly
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Warengruppe-Von"
                label-for="arwg-from-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Warengruppe-Von ist pflicht" 
            >
                <b-form-input
                    id="arwg-from-input"
                    v-model="platformownoffermarkup.ARWG_from" 
                    type="number"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Warengruppe-Bis"
                label-for="arwg-to-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Warengruppe-Bis ist pflicht" 
            >
                <b-form-input
                    id="arwg-to-input"
                    v-model="platformownoffermarkup.ARWG_to" 
                    type="number"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Wert"
            label-for="value-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Der Wert ist pflicht" 
            >
                <b-form-input
                    id="value-input"
                    v-model="platformownoffermarkup.markupvalue" 
                    type="number"
                    min="0"
                    value="0"
                    step="0.01" 
                    pattern="[0-9]+([\\,|\\.][0-9]+)?"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Type"
                label-for="type-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Type des Preisaufschlages ist pflicht" 
            >
                <b-form-select
                 id="type-input"
                v-model="platformownoffermarkup.markuptype"
                :options="typeoptions"
                value-field="key"
                text-field="type"
                disabled-field="notEnabled"
                ></b-form-select>
            </b-form-group>
            <b-form-group
            label="Startzeit"
            label-for="starttime-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="platformownoffermarkup-input"
                v-model="platformownoffermarkup.starttime" 
                type="time"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Endzeit"
            label-for="endtime-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="endtime-input"
                v-model="platformownoffermarkup.endtime"
                type="time"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Beschreibung"
            label-for="description-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Name is required" 
            >
            <b-form-input
                id="description-input"
                v-model="platformownoffermarkup.description" 
            ></b-form-input>
            </b-form-group>
        </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped platformownoffermarkup-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Platform</th>
                        <th>ARWG-von</th>
                        <th>ARWG_bis</th>
                        <th>Wert</th>
                        <th>Type</th>
                        <th>Startzeit</th>
                        <th>Endzeit</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/platformownoffermarkups'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Preisaufschlag hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
                enableSubmit: false,
                editplatformownoffermarkup:{},

                platformownoffermarkup: {
                    id: 0,
                    platforms_id: 0,
                    ARWG_from: 0,
                    ARWG_to:'',
                    markupvalue: 0,
                    markuptype:'PREIS',
                    starttime:null,
                    endtime:null,
                    description: null,
                    LastAction: 1
                },
                typeoptions:[ {key: 'PREIS', type: 'PREIS'},{key: 'PROZENT', type: 'PROZENT'},],
        }),

        created() {
            this.platformownoffermarkup.platforms_id = this.editdata.id;
            this.getPlatformOwnofferMarkups('/admin/getplatrormownoffermarkups/'+this.editdata.id);
        },
        mounted() {
             window.platformownoffermarkup=this;
        },
        methods: {
                async getPlatformOwnofferMarkups(address) {
                 $(document).ready(function() {
                    var collapsedGroups = {};
                    var table = $('.platformownoffermarkup-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'platform', name: 'Platform'},
                            {data: 'ARWG_from', name: 'ARWG_von'},
                            {data: 'ARWG_to', name: 'ARWG_bis'},
                            {data: 'markupvalue', name: 'Wert'},
                            {data: 'markuptype', name: 'Type'},
                            {data: 'starttime', name: 'Startzeit'},
                            {data: 'endtime', name: 'Stopzeit'},
                            {data: 'description', name: 'Beschreibung'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'Aktionen'},
                        ],
                        //order: [[ 4, 'asc' ]],
                    });
                $('.platformownoffermarkup-table tbody').on( 'click', 'tr.dtrg-group', function () {
                         var name = $(this).data('name');
                        collapsedGroups[name] = !collapsedGroups[name];
                        table.draw(false);
                } );
                });
            },
            getEditPlatformOwnofferMarkup(edit_id){
                axios.get('/admin/platformownoffermarkups/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-platformownoffermarkup');
                    this.modalname = "Preisaufschlag bearbeiten"
                    this.platformownoffermarkup = response.data;
                    this.headerBgVariant = 'primary';
                   // this.$root.$emit("bv::show::modal", "your-modal-id");
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        $('.platformownoffermarkup-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            getDeletePlatformOwnofferMarkup(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie den Preisaufschlag wirklich löschen?', {
                title: 'Bitte bestätigen Sie',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        //this.nameState = valid
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Preisaufschlag der Angebote hinzufügen";
        this.platformownoffermarkup.id = 0;
        this.platformownoffermarkup.ARWG_from = null;
        this.platformownoffermarkup.ARWG_to = null;
        this.platformownoffermarkup.markupvalue = 0;
        this.platformownoffermarkup.starttime = null;
        this.platformownoffermarkup.endtime = null;
        this.platformownoffermarkup.description = null;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.platformownoffermarkup.id > 0){
            axios.put(this.url+'/'+this.platformownoffermarkup.id,
                this.platformownoffermarkup,
            ).then(response => {
            // _this.callback(response);
            console.log(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.platformownoffermarkup,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-platformownoffermarkup')
        })
      }
        },
        watch: {

        },
        computed: {
            valueState() {
                if(this.platformownoffermarkup.markupvalue == null || this.platformownoffermarkup.markupvalue == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },

            starttimeState() {
                if(this.platformownoffermarkup.starttime == null || this.platformownoffermarkup.starttime == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },
            endtimeState() {
                if(this.platformownoffermarkup.endtime == null || this.platformownoffermarkup.endtime == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },
           
        },
    };
</script>
