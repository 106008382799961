<template>
    <div class="row">
        <!--b-container fluid -->
         <b-form-group
            label="Versanddienst:"
            label-for="providerName-input"
            label-cols="6"
            label-cols-lg="6" 
            invalid-feedback="Versanddienst ist pflicht" 
            >
                <b-form-input
                    id="providerName-input"
                    v-model="editdata.versanddienst" 
                    required
                    readonly
                ></b-form-input>
            </b-form-group>
        <b-modal
            id="modal-shippingservicecharge"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
            label="Versanddienst"
            label-for="versanddienst-input"
            label-cols="8"
            label-cols-lg="4" 
            invalid-feedback="Versanddienst ist pflicht" 
            >
            <b-form-input
                id="versanddienst-input"
                v-model="editdata.versanddienst" 
                required
                readonly
            ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Land"
                label-for="country-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Land ist pflicht" 
            >
                <b-form-select
                id="country-input"
                v-model="shippingservicecharge.countrycode"
                :options="countryoptions"
                value-field="code"
                text-field="country"
                :state= "countrycodeState"
                disabled-field="notEnabled"
                required
                ></b-form-select>
            </b-form-group>
            <b-form-group
                label="Typ"
                label-for="type-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Typ ist pflicht" 
            >
                <b-form-select
                id="type-input"
                v-model="shippingservicecharge.type"
                :options="typeoptions"
                value-field="name"
                text-field="name"
                disabled-field="notEnabled"
                :state="typeState"
                required
                ></b-form-select>
            </b-form-group>
            <b-form-group
            label="2Kg"
            label-for="kg2-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg2-input"
                v-model="shippingservicecharge.kg2" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="3Kg"
            label-for="kg3-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg3-input"
                v-model="shippingservicecharge.kg3" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="4Kg"
            label-for="kg4-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg4-input"
                v-model="shippingservicecharge.kg4" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="5Kg"
            label-for="kg5-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg5-input"
                v-model="shippingservicecharge.kg5" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="6Kg"
            label-for="kg6-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg6-input"
                v-model="shippingservicecharge.kg6" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="8Kg"
            label-for="kg8-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg8-input"
                v-model="shippingservicecharge.kg8" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="10Kg"
            label-for="kg10-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg10-input"
                v-model="shippingservicecharge.kg10" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="12Kg"
            label-for="kg12-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg12-input"
                v-model="shippingservicecharge.kg12" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="14Kg"
            label-for="kg14-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg14-input"
                v-model="shippingservicecharge.kg14" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="15Kg"
            label-for="kg15-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg15-input"
                v-model="shippingservicecharge.kg15" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="16Kg"
            label-for="kg16-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg16-input"
                v-model="shippingservicecharge.kg16" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="18Kg"
            label-for="kg18-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg18-input"
                v-model="shippingservicecharge.kg18" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="20Kg"
            label-for="kg20-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg20-input"
                v-model="shippingservicecharge.kg20" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="25Kg"
            label-for="kg25-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg25-input"
                v-model="shippingservicecharge.kg25" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="31,5Kg"
            label-for="kg31_5-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg31_5-input"
                v-model="shippingservicecharge.kg31_5" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="40Kg"
            label-for="kg40-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="kg40-input"
                v-model="shippingservicecharge.kg40" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Beschreibung"
            label-for="description-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Name is required" 
            >
            <b-form-input
                id="description-input"
                v-model="shippingservicecharge.description" 
            ></b-form-input>
            </b-form-group>
        </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped shippingservicecharge-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Kreditor</th>
                        <th>Dienst</th>
                        <th>Land</th>
                        <th>ISO-Code</th>
                        <th>Typ</th>
                        <th>2 kg</th>
                        <th>3 kg</th>
                        <th>4 kg</th>
                        <th>5 kg</th>
                        <th>6 kg</th>
                        <th>8 kg</th>
                        <th>10 kg</th>
                        <th>12 kg</th>
                        <th>14 kg</th>
                        <th>15 kg</th>
                        <th>16 kg</th>
                        <th>18 kg</th>
                        <th>20 kg</th>
                        <th>25 kg</th>
                        <th>31,5 kg</th>
                        <th>40 kg</th>
                        <th>Beschreibung</th>
                        <th>Erstellt am</th>
                        <th>Aktualisiert am</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/shippingservicecharges'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Neue Versandkosten hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            enableSubmit: false,
            countryoptions:{},
            typeoptions:[],
            shippingservicecharge: {
                id: 0,
                shippingserviceprovider_id:0,
                kreditor:null,
                versanddienst: null,
                type: 'Reifen',
                country: '',
                countrycode: 'DEU',
                kg2:null,
                kg3: null,
                kg4: null,
                kg5: null,
                kg6: null,
                kg8: null,
                kg10: null,
                kg12: null,
                kg14: null,
                kg15: null,
                kg16: null,
                kg18: null,
                kg20: null,
                kg25: null,
                kg31_5: null,
                kg40: null,
                description: null
            },
        }),

        created() {
            this.getShippingPackagingTypes();
            this.getCountries();
            this.shippingservicecharge.kreditor = this.editdata.kreditor;
            this.shippingservicecharge.versanddienst = this.editdata.versanddienst;
            this.shippingservicecharge.shippingserviceprovider_id = this.editdata.id;
            this.getShippingServiceCharges('/admin/shippingservicecharges/getshippingservicecharges/'+this.editdata.id);
        },
        mounted() {
            window.shippingservicecharge=this;
        },
        methods: {
                getShippingPackagingTypes: function () {
                    axios.get('/admin/getshippingpackagingtypes')
                        .then(function (response) {
                            this.typeoptions = response.data;
                        }.bind(this));
                },
                async getShippingServiceCharges(address) {
                 $(document).ready(function() {
                    var table = $('.shippingservicecharge-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'kreditor', name: 'kreditor'},
                            {data: 'versanddienst', name: 'versanddienst'},
                            {data: 'country', name: 'country'},
                            {data: 'countrycode', name: 'countrycode'},
                            {data: 'type', name: 'type'},
                            {data: 'kg2', name: 'kg2'},
                            {data: 'kg3', name: 'kg3'},
                            {data: 'kg4', name: 'kg4'},
                            {data: 'kg5', name: 'kg5'},
                            {data: 'kg6', name: 'kg6'},
                            {data: 'kg8', name: 'kg8'},
                            {data: 'kg10', name: 'kg10'},
                            {data: 'kg12', name: 'kg12'},
                            {data: 'kg14', name: 'kg14'},
                            {data: 'kg15', name: 'kg15'},
                            {data: 'kg16', name: 'kg16'},
                            {data: 'kg18', name: 'kg18'},
                            {data: 'kg20', name: 'kg20'},
                            {data: 'kg25', name: 'kg25'},
                            {data: 'kg31_5', name: 'kg31_5'},
                            {data: 'kg40', name: 'kg40'},
                            {data: 'description', name: 'Beschreibung'},
                            {data: 'created_at', name: 'Erstellt am'},
                             {data: 'updated_at', name: 'Aktualisiert am'},
                            {data: 'LastAction', name: 'LastAction'},
                            {data: 'action', name: 'Aktionen'},
                        ],
                        //order: [[ 4, 'asc' ]],
                    });
                });
            },
            getCountries: function(){
                axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
            },
            getDeleteCharge(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('dass Sie es wirklich löschen möchten?', {
                title: 'Bitte bestätigen Sie',
                size: 'nd',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            //this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            getEditCharge(edit_id){
                axios.get('/admin/shippingservicecharges/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-shippingservicecharge');
                    this.modalname = "Versandkosten bearbeiten"
                    this.shippingservicecharge = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },

            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        window.location = location.protocol + "//" + location.hostname + "/admin/shippingserviceprovider/getshippingservicecharges/"+this.editdata.id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                return valid
            },
            resetModal() {
                this.headerBgVariant = 'success';
                this.modalname = "Neue Versandkosten hinzufügen";
                this.shippingservicecharge.id = 0;
                this.shippingservicecharge.country = '';
                this.shippingservicecharge.countrycode = 'DE';
                this.shippingservicecharge.kg2 = null;
                this.shippingservicecharge.kg3 =  null;
                this.shippingservicecharge.kg4 =  null;
                this.shippingservicecharge.kg5 = null;
                this.shippingservicecharge.kg6 = null;
                this.shippingservicecharge.kg8 = null;
                this.shippingservicecharge.kg10 = null;
                this.shippingservicecharge.kg12 = null;
                this.shippingservicecharge.kg14 = null;
                this.shippingservicecharge.kg15 = null;
                this.shippingservicecharge.kg16 = null;
                this.shippingservicecharge.kg18 = null;
                this.shippingservicecharge.kg20 = null;
                this.shippingservicecharge.kg25 = null;
                this.shippingservicecharge.kg31_5 = null;
                this.shippingservicecharge.kg40 = null;
                this.shippingservicecharge.description = null;
            },
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
            handleSubmit() {
                const _this = this;
                if(this.shippingservicecharge.id > 0){
                    axios.put(this.url+'/'+this.shippingservicecharge.id,
                        this.shippingservicecharge,
                    ).then(response => {
                    // _this.callback(response);
                    this.showMsgBoxOne(response, true);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }else{
                    axios.post(this.url,
                        this.shippingservicecharge,
                    ).then(response => {
                        // _this.callback(response);
                        this.showMsgBoxOne(response, true);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }

                // Hide the modal manually
                this.$nextTick(() => {
                    this.resetModal();
                    this.$bvModal.hide('modal-shippingservicecharge')
                })
            }
        },
        watch: {

        },
        computed: {
            countrycodeState() {
                return (this.shippingservicecharge.countrycode == null || this.shippingservicecharge.countrycode == "") ? false : true;
            },
            typeState() {
                return (this.shippingservicecharge.type == null || this.shippingservicecharge.type == "") ? false : true;
            },
        },
    };
</script>
