var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-data-table',{staticClass:"supplierstock-table",attrs:{"headers":_vm.headers,"items":_vm.supplierstocks,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalsupplierstocks,"loading":_vm.loading,"options":_vm.options,"locale":"de","footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.supplierName",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.suppliername ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.suppliernamefilter),callback:function ($$v) {_vm.suppliernamefilter=$$v},expression:"suppliernamefilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.suppliername),callback:function ($$v) {_vm.suppliername=$$v},expression:"suppliername"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.suppliernamefilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.suppliername = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.supplierNumber",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.suppliernr ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.suppliernrfilter),callback:function ($$v) {_vm.suppliernrfilter=$$v},expression:"suppliernrfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.suppliernr),callback:function ($$v) {_vm.suppliernr=$$v},expression:"suppliernr"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.suppliernrfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.suppliernr = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.ARAINR",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arainr ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.arainrfilter),callback:function ($$v) {_vm.arainrfilter=$$v},expression:"arainrfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arainr),callback:function ($$v) {_vm.arainr=$$v},expression:"arainr"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arainrfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arainr = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.EAN",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arean ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.areanfilter),callback:function ($$v) {_vm.areanfilter=$$v},expression:"areanfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arean),callback:function ($$v) {_vm.arean=$$v},expression:"arean"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.areanfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arean = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.ARWG",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arwg ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.arwgfilter),callback:function ($$v) {_vm.arwgfilter=$$v},expression:"arwgfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arwg),callback:function ($$v) {_vm.arwg=$$v},expression:"arwg"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arwgfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.arwg = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.ARALB",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.aralb ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.aralbfilter),callback:function ($$v) {_vm.aralbfilter=$$v},expression:"aralbfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.aralb),callback:function ($$v) {_vm.aralb=$$v},expression:"aralb"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.aralbfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.aralb = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"header.ARSVK",fn:function(ref){
var header = ref.header;
return [_vm._v("\n            "+_vm._s(header.text)+"\n            "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arsvk ? 'primary' : ''}},[_vm._v("\n                            mdi-filter\n                        ")])],1)]}}],null,true),model:{value:(_vm.arsvkfilter),callback:function ($$v) {_vm.arsvkfilter=$$v},expression:"arsvkfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arsvk),callback:function ($$v) {_vm.arsvk=$$v},expression:"arsvk"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arsvkfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-close\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.arsvk = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-filter-off\n                        ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                            mdi-database-search\n                        ")])],1)],1)])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.price, 'currency'))+"\n        ")]}},{key:"item.ARDEL",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertToDecimal(item.ARDEL, 'currency'))+"\n            ")]}},{key:"item.ARGW",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.convertToDecimal(item.ARGW, 'decimal'))+"\n        ")]}},{key:"item.ARHKE",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertToDecimal(item.ARHKE, 'decimal'))+"\n        ")]}}])}),_vm._v(" "),[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"elevation":"2","rounded":"","small":""},on:{"click":_vm.back}},[_vm._v("Zurück")])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }