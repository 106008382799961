<template>
    <v-app>
        <div class="vue-csv-uploader">
            <v-form>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                        v-model="editdata.name"
                        label="Platformvorlage"
                        dense
                        readonly
                        ></v-text-field> 
                    </v-col>
                   <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                        v-model="editdata.filepath"
                        label="Pfad der Plattformvorlage"
                        dense
                        readonly
                        ></v-text-field>
                    </v-col>    
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                        v-model="pstocktemplate.name"
                        :rules="templatenameRules"
                        hint="Vorlagename"
                        label="Name der Bestandsvorlage"
                        required
                        ></v-text-field> 
                    </v-col> 
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-autocomplete
                        v-model="pstocktemplate.path"
                        :items="directories"
                        label="Pfad des Bestands"
                        @change="changeDirectory"
                        persistent-hint
                        required
                        ></v-autocomplete>
                    </v-col>  
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                        v-model="pstocktemplate.filename"
                        :rules="filenameRules"
                        hint="Dateiname des Bestandes"
                        label="Dateiname des Bestandes"
                        @change="changeDirectory"
                        required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >   
                    <v-file-input
                        v-model="selectedtemplatefile"
                        show-size
                        label="Vorlagedatei auswählen"
                        @change="onUpload"
                        @click:clear="clearSelectedTemplate"
                        prepend-icon="mdi-cloud-upload"
                    > 
                    </v-file-input>
                    </v-col>
                    <v-col
                        cols="12"
                        md="8"
                        >  
                        <v-text-field
                            v-model="pstocktemplate.description"
                            hint="Beschreibung"
                            label="Beschreibung"
                            ></v-text-field>
                        </v-col>
                </v-row>
                    <v-row>  
                        <v-col
                        cols="12"
                        md="4"
                        >
                        <div class="form-group">
                            <b-form-checkbox
                                id="checkbox-startRow"
                                v-model="pstocktemplate.startRow"
                                name="checkbox-startRow"
                                value="1"
                                unchecked-value="2"
                                >
                                Ohne Header
                            </b-form-checkbox>
                        </div>
                        </v-col>

                    </v-row>
                    <div class="vue-csv-uploader-part-two">
                        <div class="vue-csv-mapping">
                            <table :class="tableClass">
                                <slot name="thead">
                                    <thead>
                                    <tr>
                                        <th width="20">Erforderliche Felder</th>
                                        <th width="50">CSV-Felder Zuordnung</th>
                                    </tr>
                                    </thead>
                                </slot>
                                <tbody>
                                <tr v-for="(field, key) in fieldsToMap" :key="key" >
                                    <td>{{ field.label }}:</td>
                                    <td>
                                        <select :class="tableSelectClass" :name="`csv_uploader_map_${key}`" v-model="map[field.key]">
                                            <option :value="-1" >Ignorieren</option>
                                            <option v-for="(column, key) in firstRow" :key="key" :value="key">{{ column }}</option>
                                        </select>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <b-form-group>
                        <b-button href='#' variant='secondary' v-on:click="back">Zurück</b-button>
                        <slot name="submit" :submit="submit" v-if="pstocktemplate.name && pstocktemplate.filename && pstocktemplate.path && sample && form.csv">
                            <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                        </slot>
                    </b-form-group>
                </v-form>
        </div>
       </v-app> 
    </template>
    
    <script>
        import { drop, every, forEach, get, isArray, map, set } from 'lodash';
        import axios from 'axios';
        import Papa from 'papaparse';
        export default {
            props: {
                value: Array,
                editdata: null,
                url: {
                    type: String,
                    default: '/admin/platformstocktemplate'
                },
                 mapFields: {
                    //required: true
                    type: Array,
                    default: () => {
                        return ['Artikelnummer', 'EAN', 'Bestand','Preis', 'Warengruppe']
                    }
                },
    
        callback: {
                    type: Function,
                    default: () => ({})
                },
                catch: {
                    type: Function,
                    default: () => ({})
                },
                finally: {
                    type: Function,
                    default: () => ({})
                },
                parseConfig: {
                    type: Object,
                    default() {
                        return {};
                    }
                },
                headers: {
                    default: null
                },
                loadBtnText: {
                    type: String,
                    default: "Laden"
                },
                submitBtnText: {
                    type: String,
                    default: "Speichern"
                },
                autoMatchFields: {
                    type: Boolean,
                    default: false
                },
                autoMatchIgnoreCase: {
                    type: Boolean,
                    default: false
                },
                tableClass: {
                    type: String,
                    default: "table"
                },
                checkboxClass: {
                    type: String,
                    default: "form-check-input"
                },
                buttonClass: {
                    type: String,
                    default: "btn btn-primary"
                },
                inputClass: {
                    type: String,
                    default: "form-control-file"
                },
                validation: {
                    type: Boolean,
                    default: true,
                },
                fileMimeTypes: {
                    type: Array,
                    default: () => {
                        return ['text/csv','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
                    }
                },
                tableSelectClass: {
                    type: String,
                    default: 'form-control'
                },
                canIgnore: {
                   type: Boolean,
                   default: false,
                }
            },
    
            data: () => ({
                form: {
                      csv: null,
                    },
                templatenameRules: [v => !!v || 'Vorlagename ist erforderlich',],
                filenameRules: [
                    v => !!v || 'Dateiname ist erforderlich',
                ],
                pcart04mapFields:[],
                fieldsToMap: [],
                pcart04fieldsToMap: [],
                map: {},
                hasHeaders: false,
                csv: null,
                sample: null,
                isValidFileMimeType: false,
                fileSelected: false,
                enableSubmit: false,
                directories: [],
                myValue:'',
                selectedtemplatefile:null,
                templatefile: null,
                filedata:null,
                pstocktemplate: {
                    id: 0,
                    platformtemplate_id:0,
                    name: null,
                    path: null,
                    filename: null,
                    templateFile: null,
                    templateheader: null,
                    startRow: 2,
                    validitytime: 0,
                    description: '',
                    csvdata: null,
                },
            }),
    
            created() {
                this.pstocktemplate.platformtemplate_id = this.editdata.id;
                this.getDirectories();

                if (isArray(this.mapFields)) {
                    this.fieldsToMap = map(this.mapFields, (item) => {
                        return {
                            key: item,
                            label: item
                        };
                    });
                } else {
                    this.fieldsToMap = map(this.mapFields, (label, key) => {
                        return {
                            key: key,
                            label: label
                        };
                    });
                }
    
                if (isArray(this.pcart04mapFields)) {
                    this.pcart04fieldsToMap = map(this.pcart04mapFields, (item) => {
                        return {
                            key: item,
                            label: item
                        };
                    });
                } else {
                    this.pcart04mapFields = map(this.pcart04mapFields, (label, key) => {
                        return {
                            key: key,
                            label: label
                        };
                    });
                }
                this.form.csv = this.buildMappedCsv();
            },
    
            methods: {
                async clearSelectedTemplate(){
                    const _this = this;
                    _this.pstocktemplate.templateFile = null;
                    _this.sample = null;
                    _this.csv = null;
                    _this.pstocktemplate.templateheader=null;
    
                },
                async onUpload() {
                    const _this = this;
                    try {
                        if (_this.selectedtemplatefile) {
                            console.log(_this.selectedtemplatefile.name);
                            _this.clearSelectedTemplate();
                            _this.pstocktemplate.templateFile = _this.selectedtemplatefile.name
                            let reader = new FileReader();
                            reader.readAsText(this.selectedtemplatefile, "UTF-8");
                            reader.onload = function (evt) {
                            _this.sample = get(Papa.parse(evt.target.result, { preview: 1, skipEmptyLines: true }), "data");
                            _this.csv = _this.sample;
                            };
                            reader.onerror = function () {
                            };
                        }
                    } catch (error) {
                        
                    } 
                },
                back: function(){
                    window.location = location.protocol + "//" + location.hostname + "/admin/platformstocktemplates/"+this.pstocktemplate.platformtemplate_id;
                },
                toast(toaster, append = false) {
                    this.$bvToast.toast(`Bitte selektieren Sie die Felder`, {
                    title: `Toaster ${toaster}`,
                    toaster: toaster,
                    solid: true,
                    appendToast: append
                    })
                },
                getArtikelColumns: function(){
                    axios.get('/admin/getallartikelcolumns')
                    .then(function (response) {
                        this.pcart04mapFields = response.data;
                    }.bind(this));
                },
                changeDirectory: function(value) {
                    //this.getTemplateFiles();
                },
                getTemplateFiles: function(){
                    this.supplierfiles = [];
                    this.pstocktemplate.templateFile = '';
                    var dir = this.pstocktemplate.filepath.substring(0, this.pstocktemplate.filepath.indexOf('/'));
                    console.log("DIR: "+dir)
                    axios.get('/admin/platformtemplatefiles/'+dir)
                    .then(function (response) {
                        this.supplierfiles = response.data;
                        if(this.supplierfiles.length == 1)
                            this.pstocktemplate.templateFile = this.supplierfiles[0];
                    }.bind(this)).catch(response => {});
                },

                getPlatformTemplateArtikelTypes: function(){
                    axios.get('/admin/getplatformtemplateartikeltypes')
                        .then(function (response) {
                            this.platformtemplateartikeltypes = response.data;
                        }.bind(this));
                },
                getDirectories: function(){
                    axios.get('/admin/supplierstocktemplatedirectories')
                    .then(function (response) {
                        this.directories = response.data;
                    }.bind(this));
                },

                submit() {
                    const _this = this;
                    this.form.csv = this.buildMappedCsv();
                    let csvdata = this.form.csv.shift();
                    this.$emit('input', this.form.csv);
                    this.pstocktemplate.csvdata = csvdata;
                    console.log('CSVDATA: '+csvdata);
                    axios.post(this.url,
                        this.pstocktemplate,
                    ).then(response => {
                       // _this.callback(response);
                       console.log(response);
                       this.showMsgBoxOne(response);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                },
                showMsgBoxOne(callback, change) {
                    this.$bvModal.msgBoxOk(callback.data)
                    .then(value => {
                        if(change)
                        {
                            window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
                },
    
                 buildMappedCsv() {
                    const _this = this;
                    console.log('buildMappedCsv aufgerufen CSV: '+_this.csv);
                    let csv = this.hasHeaders ? drop(this.csv) : this.csv;
                    console.log('CSV: '+csv);
                    return map(csv, (row) => {
                        let newRow = {};
                        forEach(_this.map, (column, field) => {
                            console.log('Column: '+column)
                            console.log('Field: '+field);
                            console.log('Row: '+row); 
                            set(newRow, field, get(row, column)+'#'+column);
                            console.log('NewRow: '+field+ ' => '+get(row, column));
                            // return newRow;
                        });
                        return newRow;
                    });
                },
                validFileMimeType() {
                   let file  = this.$refs.csv.files[0];
                    //const mimeType = file.type === "" ? mimeTypes.lookup(file.name) : file.type;
    
                    if (file) {
                        this.fileSelected = true;
                        //this.isValidFileMimeType = this.validation ? this.validateMimeType(mimeType) : true;
                        this.isValidFileMimeType = true;
                    } else {
                        this.isValidFileMimeType = !this.validation;
                        this.fileSelected = false;
                    }
                },
                /*
                validateMimeType(type) {
                    return this.fileMimeTypes.indexOf(type) > -1;
                },
                */
                load() {
                    const _this = this;
                   // this.enableSubmit = false;
                    this.readFile((output) => {
                        _this.sample = get(Papa.parse(output, { preview: 2, skipEmptyLines: true }), "data");
                        _this.csv = get(Papa.parse(output, { skipEmptyLines: true }), "data");
                    });
                },
    
                readFile(callback){
                   // var tempfilename = this.selectedtemplatefile.substring(this.selectedtemplatefile.lastIndexOf('/')+1);
                    console.log('tempfile: ' + this.pstocktemplate.templateFile);
                    axios.get('/admin/gettemplate/'+this.pstocktemplate.templateFile)
                        .then(function (response) {
                            console.log(response.data);
                            callback(response.data);
                            this.toast('b-toaster-top-center');
                        }.bind(this));
                },
                toggleHasHeaders() {
                    this.hasHeaders = !this.hasHeaders;
                },
                makeId(id) {
                    return `${id}${this._uid}`;
                },
                checkprice(price){
                    var regex = /^\d+(\.\d+)*$/;
                    var ret = regex.test(price);
                    console.log(ret)
                    var count = (price.match(/./g)).length;
                    console.log(count)
                    return /^\d+(\.\d+)*$/.test(price);
                }
            },
            watch: {
    
            },
            computed: {
                 firstRow() {
                    this.pstocktemplate.templateheader = get(this, "sample.0");
                    return this.pstocktemplate.templateheader;
                },
    
                pcart04Row() {
                    return get(this, "sample.0");
                },
                showErrorMessage() {
                    return this.fileSelected && !this.isValidFileMimeType;
                },
    
                disabledNextButton() {
                    return this.pstocktemplate.templateFile == null;
                },
            },
        };
    </script>
    