<template>
    <div class="vue-csv-uploader">
        <div class="form">
            <b-form-row>
                <b-col>
                    <b-form-group role="form-group">
                        <label for="input-name">Lieferantenname:</label>
                        <b-form-input
                        id="input-name"
                        v-model="supplierName"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group role="form-group">
                        <label for="input-supplierNumber">Lieferantennummer:</label>
                        <b-form-input
                        id="input-supplierNumber"
                        v-model="editdata.supplierNumber"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group role="form-group">
                        <label for="input-templatename">Vorlagename:</label>
                        <b-form-input
                        id="input-templatename"
                        v-model="editdata.templateName"
                        disabled
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <div class="table-responsive">
                    <b-button type='button' class="btn btn-info" @click="addNewRow"  size="sm">
                        <i class="fas fa-plus-circle"></i>
                        Filter hinzufügen
                    </b-button>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Spalte</th>
                                <th scope="col">Wert</th>
                                <th scope="col">Enddatum</th>
                                <th scope="col">Beschreibung</th>
                                <th scope="col">Status</th>
                                <th scope="col">#</th>
                            </tr>
                        </thead>
                        <tr v-for="(supplierfilter, k) in supplier_filters" :key="k">
                            <td>
                                <b-form-select
                                v-model="supplierfilter.columnName"
                                :options="templatecolumns"
                                value-field="value"
                                text-field="text"
                                disabled-field="notEnabled"
                                v-on:change="changeColumn($event, supplierfilter)"
                                required
                                ></b-form-select>
                            </td>
                            <td>
                                 <b-form-input class="form-control" type="text"  v-model="supplierfilter.value"  required></b-form-input>
                            </td>
                             <td>
                                 <b-form-input class="form-control" type="date"  v-model="supplierfilter.validDateUntil"></b-form-input>
                            </td>
                             <td>
                                 <b-form-input class="form-control" type="text"  v-model="supplierfilter.description"></b-form-input>
                            </td>
                            <td>
                                <b-form-checkbox
                                :id="'checkbox_'+supplierfilter.id"
                                v-model="supplierfilter.LastAction"
                                :name="'checkbox_'+supplierfilter.id"
                                value="1"
                                unchecked-value="0"
                                switch
                                size="lg"
                                >
                                </b-form-checkbox> 
                            </td>
                            <td scope="row" class="trashIconContainer" >
                                <i class="far fa-trash-alt" style="color: red;" @click="deleteRow(k, supplierfilter)" v-b-tooltip.hover title="Löschen"></i>
                            </td>
                        </tr>
                    </table>
                </div>
            </b-form-row>
            <b-form-row>    
                <b-form-group>
                    <b-button href='#' variant='secondary' v-on:click="back">Zurück</b-button>
                    <slot name="submit" :submit="submit" v-if="supplier_filters.length > 0">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText" size="sm">
                    </slot>
                </b-form-group>
            </b-form-row>
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import { findIndex, drop, every, forEach, get, isArray, map, set } from 'lodash';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/supplierstocktemplatefilter'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
                enableSubmit: false,
                filteroptions:{},
                supplierstocktemplate_id:0,
                supplierName: '',
                supplierNumber: '',
                supplier_filters: [{
                    id: 0,
                    supplierstocktemplate_id:0,
                    supplierName: '',
                    supplierNumber: '',
                    columnName: '',
                    columnNr: '',
                    value: '',
                    validDateUntil:null,
                    description:'',
                    LastAction:1
                }],
                surchargetypeoptions:[
                    {
                        key: 0,
                        type: 'Deaktiviert'
                    },
                    {
                        key: 1,
                        type: 'Aktiviert ohne Zeitfenster'
                    },
                    {
                        key: 2,
                        type: 'Aktiviert mit Zeitfenster'
                    },
                ],
                templatecolumns: []
        }),

        created() {
            this.supplier_filters = [];
            this.supplierstocktemplate_id = this.editdata.id;
            this.supplierName = this.editdata.supplier.supplierName;
            this.supplierNumber = this.editdata.supplierNumber;
            this.getSupplierStockFilters();
            this.getSupplierStockTemplateColumns();
        },

        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/supplier/"+this.editdata.supplier.id+"/supplierstocktemplates";
            },
            changeColumn: function($event, supplierfilter) {
                supplierfilter.columnNr = _.findIndex(this.templatecolumns, (e) => {
                    return e == $event;
                }, 0);
            },
            getSupplierStockTemplateColumns: function(){
                axios.get('/admin/supplierstocktemplatefilters/getSupplierStockTemplateColumns/'+this.supplierstocktemplate_id)
                .then(function (response) {
                    this.templatecolumns = response.data;
                     console.log(this.templatecolumns);
                }.bind(this));
            },
            getSupplierStockFilters: function(){
                axios.get('/admin/supplierstocktemplatefilters/getsupplierstocktemplatefilters/'+this.supplierstocktemplate_id)
                .then(function (response) {
                    this.supplier_filters = response.data;
                }.bind(this));
            },
            addNewRow() {
                this.supplier_filters.push({
                    id: 0,
                    supplierstocktemplate_id: this.supplierstocktemplate_id,
                    supplierName: this.supplierName,
                    supplierNumber: this.supplierNumber,
                    columnName: '',
                    columnNr: '',
                    value: '',
                    validDateUntil:null,
                    description:'',
                    LastAction:0
                });
            },
            deleteRow(index, supplierfilter) {
                const _this = this;
                var idx = this.supplier_filters.indexOf(supplierfilter);
                if (idx > -1) {
                    this.supplier_filters.splice(idx, 1);
                   if(supplierfilter.id > 0)
                   {
                       axios.delete('/admin/supplierstocktemplatefilter/'+supplierfilter.id
                       ).then(response => {
                            this.showMsgBoxOne(response, false);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                   }
                }
            },

            submit() {
                const _this = this;
                axios.post(this.url,
                    this.supplier_filters,
                ).then(response => {
                   this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if(change)
                    {
                        window.location.reload();
                        //window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
        },
        watch: {

        },
        computed: {

        },
    };
</script>
