<template>
    <v-app>
        <div class="vue-csv-uploader">
            <v-form>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platformName" label="Platformname" dense readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platformMatchcode" label="Matchcode" dense readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="debitorNr" label="DebitorNr" dense readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platformSupplierPath" label="Pfad der Plattform" dense
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="supplierNumber" label="Lieferantennummer" dense readonly></v-text-field>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="ptemplate.name" :rules="templatenameRules" hint="Vorlagename"
                            label="Name der Plattformvorlage" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete v-model="ptemplate.filepath" :items="directories" label="Pfad der Plattformdatei"
                            persistent-hint required></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" v-if="!ptemplate.isnotemplate">
                        <v-text-field v-model="ptemplate.filename" :rules="filenameRules" hint="Dateiname der Plattform"
                            label="Dateiname" required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-select v-model="ptemplate.country" :items="countryoptions"
                            :rules="[v => !!v || 'Land ist erforderlich!']" item-value="code" item-text="country"
                            label="Land" required></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete v-model="ptemplate.artikeltype" :items="platformtemplateartikeltypes"
                            item-value="name" item-text="name" label="Artikeltype" required></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete v-model="ptemplate.importorder" :items="importorders"
                            label="Import-Reihenfolge"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field v-model="ptemplate.import_timeout"
                            hint="Importdatei alle eingegebenen Minuten prüfen. Für nicht prüfen 0 eingeben"
                            label="Importdatei-Zeitüberschreitung (Minuten)"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-checkbox v-model="ptemplate.isnotemplate" hide-details class="shrink mr-2 mt-0"
                            label="Die Datei der Plattform nicht importieren" @change="checkIsnotemplate"></v-checkbox>
                    </v-col>

                </v-row>
                <v-row>
                    <v-col cols="12" md="4" v-if="ptemplate.isnotemplate">
                        <v-autocomplete v-model="ptemplate.offerfromplatform" :items="platformtemplates" item-text="name"
                            item-value="id" label="Angebotspreise von der Plattform übernehmen" persistent-hint
                            prefix="Platform:"></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="ptemplate.isnotemplate < 1">
                    <v-col cols="12" md="4">
                        <b-form-checkbox id="checkbox-isdependent" v-model="ptemplate.isdependent"
                            name="checkbox-isdependent" value="1" unchecked-value="0">
                            Händlerpreise sind von der anderen Plattform abhängig
                        </b-form-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="ptemplate.isnotemplate < 1">
                    <v-col cols="12" md="4" v-if="ptemplate.isdependent > 0">
                        <v-autocomplete v-model="ptemplate.dependentplatform_id" :items="platformtemplates" item-text="name"
                            item-value="id" label="Händlerpreise von der Plattform übernehmen" persistent-hint
                            prefix="Platformname:"></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-text-field v-model="ptemplate.description" label="Beschreibung"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <b-form-group>
                        <b-button href='#' variant='secondary' v-on:click="back">Zurück</b-button>
                        <slot name="submit" :submit="submit" v-if="ptemplate.name">
                            <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                        </slot>
                    </b-form-group>
                </v-row>
            </v-form>
        </div>
    </v-app>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformtemplate'
        },

        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        parseConfig: {
            type: Object,
            default() {
                return {};
            }
        },
        headers: {
            default: null
        },
        loadBtnText: {
            type: String,
            default: "Laden"
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        autoMatchFields: {
            type: Boolean,
            default: false
        },
        autoMatchIgnoreCase: {
            type: Boolean,
            default: false
        },
        tableClass: {
            type: String,
            default: "table"
        },
        checkboxClass: {
            type: String,
            default: "form-check-input"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },
        fileMimeTypes: {
            type: Array,
            default: () => {
                return ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
            }
        },
        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
        canIgnore: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        form: {
            csv: null,
        },
        templatenameRules: [v => !!v || 'Vorlagename ist erforderlich',],
        filenameRules: [
            v => !!v || 'Dateiname ist erforderlich',
        ],
        pcart04mapFields: [],
        fieldsToMap: [],
        pcart04fieldsToMap: [],
        map: {},
        hasHeaders: true,
        csv: null,
        sample: null,
        isValidFileMimeType: false,
        fileSelected: false,
        supplierfiles: [],
        enableSubmit: false,
        countryoptions: [],
        platformtemplateartikeltypes: [],
        platformName: '',
        platformMatchcode: '',
        debitorNr: '',
        platformSupplierPath: '',
        supplierNumber: '',
        country: null,
        directories: [],
        platformtemplates: [],
        selectedtemplatefile: null,
        templatefile: null,
        filedata: null,
        importorders: [],

        ptemplate: {
            id: 0,
            platforms_id: null,
            name: null,
            country: null,
            artikeltype: null,
            filepath: null,
            filename: null,
            isnotemplate: 0,
            offerfromplatform: -1,
            isdependent: 0,
            dependentplatform_id: -1,
            importorder: -1,
            import_timeout: 0,
            description: null,
        },

        supplier_countries: [{
            platforms_id: 0,
            platformName: '',
            supplierNumber: '',
            code: '',
            country: '',
            price: '',
            parsel: 0,
            surchargetype: 0,
            surcharge: 0.00,
            surchargestartdate: null,
            surchargestopdate: null,
            description: null
        }],
    }),

    created() {
        this.getPlatformtemplates();
        this.getImportorder();
        this.getCountries();
        this.getDirectories();
        this.getPlatformTemplateArtikelTypes();
        this.supplier_countries = [];
        this.ptemplate.platforms_id = this.editdata.id;
        this.platformName = this.editdata.name;
        this.debitorNr = this.editdata.debitorNr;
        this.platformMatchcode = this.editdata.matchcode;
        this.platformSupplierPath = this.editdata.supplierPath;
        this.getSupplierCountries();
    },

    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/platforms/" + this.ptemplate.platforms_id + "/platformtemplates";
        },
        toast(toaster, append = false) {
            this.$bvToast.toast(`Bitte selektieren Sie die Felder`, {
                title: `Toaster ${toaster}`,
                toaster: toaster,
                solid: true,
                appendToast: append
            })
        },
        checkIsnotemplate() {
            if (this.ptemplate.isnotemplate) {
                this.ptemplate.filename = "Keine";
            } else {
                this.ptemplate.filename = null;
            }
        },

        getImportorder() {

            /* console.log('Length:'+_.toArray(this.platformtemplates));
             for (let index = 0; index < this.platformtemplates.length + 1; index++) {
                 this.importorders[index] = index + 1;
             }*/
            console.log(this.importorders);
        },

        getSupplierCountries: function () {
            axios.get('/admin/suppliershippingcountries/getshippingscountries/' + this.ptemplate.platforms_id)
                .then(function (response) {
                    this.supplier_countries = response.data;
                }.bind(this));
        },
        getCountries: function () {
            axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
        },
        getPlatformTemplateArtikelTypes: function () {
            axios.get('/admin/getplatformtemplateartikeltypes')
                .then(function (response) {
                    this.platformtemplateartikeltypes = response.data;
                }.bind(this));
        },
        getDirectories: function () {
            axios.get('/admin/supplierstocktemplatedirectories')
                .then(function (response) {
                    this.directories = response.data;
                }.bind(this));
        },

        getPlatformtemplates: function () {
            axios.get('/admin/getplatformtemplatesnames')
                .then(function (response) {
                    this.platformtemplates = response.data;
                    this.importorders = Object.keys(this.platformtemplates).map(index => {
                        return ++index;
                    });
                    this.importorders.push(++this.importorders.length);
                }.bind(this));
        },
        submit() {
            const _this = this;
            axios.post(this.url,
                _this.ptemplate,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                _this.showMsgBoxOne(response);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        validFileMimeType() {
            let file = this.$refs.csv.files[0];
            //const mimeType = file.type === "" ? mimeTypes.lookup(file.name) : file.type;

            if (file) {
                this.fileSelected = true;
                //this.isValidFileMimeType = this.validation ? this.validateMimeType(mimeType) : true;
                this.isValidFileMimeType = true;
            } else {
                this.isValidFileMimeType = !this.validation;
                this.fileSelected = false;
            }
        },


    },
    watch: {

    },
    computed: {
        showErrorMessage() {
            return this.fileSelected && !this.isValidFileMimeType;
        },

        disabledNextButton() {
            return this.ptemplate.templatefile == null;
        },

    },
};
</script>
