<template>
    <v-app>
        <v-row>
            <v-data-table
            dense
            :headers="headers"
            :items="shippingcosts"
            item-key="id"
            :items-per-page="itemsPerPage"
            class="shippingcosts-table"
            :server-items-length="totalShippingcosts"
            :loading="loading"
            :options.sync="options"
            locale="de"
            :footer-props="footerProps"
            disable-sort
            group-by="ordername"
           
            >
            <template v-slot:group.header="{headers,items, isOpen, toggle}">
              <th :colspan="headers.length" bg-color="indigo">
                <v-icon @click="toggle"
                  >{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
                {{ items[0].country +' '+ items[0].type}}            
              </th> 
              <!--th :colspan="1" class="theme--light" v-for="header in kgheaders">{{ header.text }}</th-->
            </template>
            <template v-slot:header.kreditor="{ header }">
            {{ header.text }}
            <v-menu  v-model="kreditorfilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="kreditor ? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="kreditor"
                  class="pa-4"
                  type="text"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="kreditorfilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
                <v-btn
                  @click="kreditor = '',clearFilter()"
                  small
                  text
                  color="red"
                >
                <v-icon dark>
                  mdi-filter-off
                </v-icon>
                </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                  right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
            <template v-slot:header.versanddienst="{ header }">
            {{ header.text }}
            <v-menu  v-model="versanddienstfilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="versanddienst ? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="versanddienst"
                  class="pa-4"
                  type="text"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="versanddienstfilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
                <v-btn
                  @click="versanddienst = '',clearFilter()"
                  small
                  text
                  color="red"
                >
                <v-icon dark>
                  mdi-filter-off
                </v-icon>
                </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                  right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
            <template v-slot:header.country="{ header }">
            {{ header.text }}
            <v-menu  v-model="countryfilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="country ? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="country"
                  class="pa-4"
                  type="text"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="countryfilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                @click="country = ''; clearFilter()"
                small
                text
                color="red"
                class="ml-2 mb-2"
              >
              <v-icon dark>
                  mdi-filter-off
                </v-icon>
              </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                   right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
          <template v-slot:header.countrycode="{ header }">
            {{ header.text }}
            <v-menu  v-model="countrycodefilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="countrycode? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="countrycode"
                  class="pa-4"
                  type="text"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="countrycodefilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                @click="countrycode = ''; clearFilter()"
                small
                text
                color="red"
                class="ml-2 mb-2"
              >
              <v-icon dark>
                  mdi-filter-off
                </v-icon>
              </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                  right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
          <template v-slot:header.uebergewicht="{ header }">
            {{ header.text }}
            <v-menu  v-model="ugewichtfilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="ugewicht ? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="ugewicht"
                  class="pa-4"
                  type="numeric"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="ugewichtfilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                @click="ugewicht = '';clearFilter()"
                small
                text
                color="red"
                class="ml-2 mb-2"
              >
              <v-icon dark>
                mdi-filter-off
              </v-icon>
              </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                  right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
          <template v-slot:header.type="{ header }">
            {{ header.text }}
            <v-menu  v-model="anzahlfilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="type ? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="type"
                  class="pa-4"
                  type="text"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="anzahlfilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                @click="anzahl = '';clearFilter()"
                small
                text
                color="primary"
                class="ml-2 mb-2"
              >
              <v-icon dark>
                mdi-filter-off
              </v-icon>
              </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                  right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
            <template v-slot:item.kg2="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg2')"
                >
                   {{ convertToDecimal(item.kg2) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg2) }}  </p>
              </template>
              <template v-slot:item.kg3="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg3')"
                >
                   {{ convertToDecimal(item.kg3) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg3) }}  </p>
              </template>
              <template v-slot:item.kg4="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg4')"
                >
                   {{ convertToDecimal(item.kg4) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg4) }}  </p>
              </template>
              <template v-slot:item.kg5="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg5')"
                >
                   {{ convertToDecimal(item.kg5) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg5) }}  </p>
              </template>
              <template v-slot:item.kg6="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg6')"
                >
                   {{ convertToDecimal(item.kg6) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg6) }}  </p>
              </template>
              <template v-slot:item.kg8="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg8')"
                >
                   {{ convertToDecimal(item.kg8) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg8)}}  </p>
              </template>
              <template v-slot:item.kg10="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg10')"
                >
                   {{ convertToDecimal(item.kg10) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg10) }}  </p>
              </template>
              <template v-slot:item.kg12="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg12')"
                >
                   {{ convertToDecimal(item.kg12) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg12) }}  </p>
              </template>
              <template v-slot:item.kg14="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg14')"
                >
                   {{ convertToDecimal(item.kg14) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg14) }}  </p>
              </template>
              <template v-slot:item.kg15="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg15')"
                >
                   {{ convertToDecimal(item.kg15) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg15) }}  </p>
              </template>
              <template v-slot:item.kg16="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg16')"
                >
                   {{ convertToDecimal(item.kg16) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg16) }}  </p>
              </template>
              <template v-slot:item.kg18="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg18')"
                >
                   {{ convertToDecimal(item.kg18) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg18) }}  </p>
              </template>
              <template v-slot:item.kg20="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg20')"
                >
                   {{ convertToDecimal(item.kg20) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg20) }}  </p>
              </template>
              <template v-slot:item.kg25="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg25')"
                >
                   {{ convertToDecimal(item.kg25) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg25) }}  </p>
              </template>
              <template v-slot:item.kg31_5="{ item}">
              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg31_5')"
                >
                   {{ convertToDecimal(item.kg31_5) }} 
                   
                </v-chip>
                <p v-else>{{ convertToDecimal(item.kg31_5) }}  </p>
              </template>
              <template v-slot:item.kg40="{ item}">

              <v-chip
                color="green"
                text-color="white"
                small
                v-if="item.id == getOrdernameGroup(item.ordername, 'kg40')"
                >
                   {{ convertToDecimal(item.kg40) }} 
                   
                </v-chip>
                <p v-else-if="item.kg40 > 0">{{ convertToDecimal(item.kg40) }} </p>
              </template>
          </v-data-table>
        </v-row>
    </v-app>
</template>

<script>
    import axios from 'axios';
    import { drop, every, forEach, get, isArray, map, set, minBy} from 'lodash';
    import colors from 'vuetify/lib/util/colors'
  
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/shippingcosts'
            },
            location_url: {
                type: String,
                default: '/admin/shippingcosts/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            disableOk:false,
            expanded:[],
            shippingservices:[],
            supplierstocktemplates: [],
            shippingcosts:[],
            totalShippingcosts: 0,
            itemsPerPage: 50,
            loading: true,
            conditions: [],
            filters: [],
            options: {
              page: 1,
              itemsPerPage: 50,
             // sortBy: 'DESC',
              sortDesc: [false],
              mustSort: false,
              multiSort: false,
              sort:'',
              filters:null
            },
            compareitems:[],
            kreditor:'',
            versanddienst:'',
            country:'',
            countrycode:'',
            type:'',
            ugewicht:'',
            anzahl:'',
            kreditorfilter:false,
            versanddienstfilter: false,
            countryfilter: false,
            countrycodefilter: false,
            ugewichtfilter: false,
            anzahlfilter: false,
            kgheaders: [],
            headers: [
                    { text: '                   ', value: 'expand', align:'end' },
                    {text: 'id', value: 'id'}, 
                    {text: 'Kreditor', value: 'kreditor'},
                    {text: 'Dienstleister', value: 'versanddienst'},
                    {text: 'Land', value: 'country'},
                    {text: 'ISO-Code', value: 'countrycode'},
                    {text: 'Type', value: 'type'},
                    {text: '2 kg', value: 'kg2'},
                    {text: '3 kg', value: 'kg3'},
                    {text: '4 kg', value: 'kg4'},
                    {text: '5 kg', value: 'kg5'},
                    {text: '6 kg', value: 'kg6'},
                    {text: '8 kg', value: 'kg8'},
                    {text: '10 kg', value: 'kg10'},
                    {text: '12 kg', value: 'kg12'},
                    {text: '14 kg', value: 'kg14'},
                    {text: '15 kg', value: 'kg15'},
                    {text: '16 kg', value: 'kg16'},
                    {text: '18 kg', value: 'kg18'},
                    {text: '20 kg', value: 'kg20'},
                    {text: '25 kg', value: 'kg25'},
                    {text: '31,5 kg', value: 'kg31_5'},
                    {text: '40 kg', value: 'kg40'},
                    {text: 'Erstellt am', value: 'created_at'},
                    {text: 'Aktualisiert am', value: 'updated_at'},
                ],
                footerProps: {
                    'items-per-page-options': [50, 500, 1000, -1],
                    'show-current-page': true,
                    'show-first-last-page': true,
                    disableItemsPerPage: false,
                    itemsPerPageText: 'Einträge pro Seite',
                    itemsPerPageAllText: 'Alle',
                }
        }),
        created() {
           this.filters = new Array();
           //this.getKgHeader();
        },
        mounted() {
             window.shippingcosts=this;
        },
        methods: {
          getKgHeader() {
            this.kgheaders = this.headers.filter(item => item.value.startsWith("kg") );
          },
          convertToDecimal(value){
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
          },
          getOrdernameGroup(ordername, kgnr) {
            let items = this.shippingcosts.filter(shippingcost => shippingcost.ordername===ordername && parseFloat(shippingcost[kgnr])); // > 0).map(shippingcost => parseFloat(shippingcost[kgnr]) > 0);//
           // let itemsgroup = items.filter(shippingcost => shippingcost.name===fieldOf).map(shippingcost => shippingcost[kgnr] > 0);
            //console.log(items);
            // let min =  _.minBy(items, kgnr);
             let min = minBy(items, function(shippingcost) { return shippingcost[kgnr] * 100; }); 
             //console.log(min_val)
             if(min == null){
              return 0;
             }
             return min['id'];
          },
         
          getMinCosts(items) {
            let kgs = ['kg2', 'kg3', 'kg4', 'kg5', 'kg6', 'kg8', 'kg10', 'kg12', 'kg14', 'kg15', 'kg16', 'kg18', 'kg20', 'kg25', 'kg31_5', 'kg40'];
            let kgitems = [];
           // this.compareitems = [];
            kgs.forEach(kgnr => {
              //let min= items.reduce((m,x)=> m[kgnr]<x[kgnr] ? m:x);
              let min =  _.minBy(items, kgnr);
              //console.log(min);
              kgitems.push(min['id']);
              /*var minitem = items.sort(function (a, b) {
                    console.log(kgnr);
                      return a.kgnr - b.kgnr; })[0]*/
              //kgitems.push(kgnr+': '+minitem[kgnr]+' ');
              
            });
           // var min_kg2 = items.sort(function (a, b) {
            //          return a.kg15 - b.kg15 })[0];
           this.compareitems = kgitems;
            //console.log(kgitems);
            return kgitems;
          },
            
          async clearFilter(){
            this.filter();
          },
          async getShippingservices() {
            axios.get('/admin/getshippingservices')
            .then(function (response) {
                this.shippingservices = response.data;
            }.bind(this));
          },
          async getSupplierStockTemplates() {
            //axios.get('/admin/getallsupplierstocktemplates')
            axios.get('/admin/getsuppliers')
            .then(function (response) {
                this.supplierstocktemplates = response.data;
            }.bind(this));
          },
          async getShippingcosts() {
            this.loading = true
            this.options.sort = this.options.sortBy[0];
            axios.get('/admin/getshippingcosts/', {params:this.options})
            .then(function (response) {
                this.shippingcosts = response.data.data;
                this.totalShippingcosts = response.data.total
                this.itemsPerPage = response.data.per_page
                this.loading = false
                this.getOrdernameGroup('DEU_Karton', 'kg2');
                //console.log(this.shippingcosts);
            }.bind(this));
            
          },
          getColor (value) {
              if (value > 0) return "red"
              else return "green"
          },
          showMsgBoxOne(callback, change) {
              this.$bvModal.msgBoxOk(callback.data)
              .then(value => {
                  if(change)
                  {
                      window.location = location.protocol + "//" + location.hostname + this.location_url;
                  }
              })
              .catch(err => {
                  // An error occurred
              })
          },
          checkFormValidity() {
              const valid = this.$refs.form.checkValidity()
              //this.nameState = valid
              return valid
          },
          handleOk(bvModalEvent) {
              bvModalEvent.preventDefault()
              this.handleSubmit()
          },
         
        filter() {
          this.filters.length = 0;
          if (this.kreditor) {
              this.filters.push(new Array('kreditor','=',this.kreditor));
          }
          if (this.versanddienst) {
            this.filters.push(new Array('versanddienst','LIKE',this.versanddienst+'%'));
          }
          if (this.country) {
            this.filters.push(new Array('country', 'LIKE', this.country+'%'));
          }
          if (this.countrycode) {
            this.filters.push(new Array('countrycode', 'LIKE', this.countrycode+'%'));
          }
          if (this.type) {
            this.filters.push(new Array('type', 'LIKE', this.type+'%'));
          }
          if (this.ugewicht) {
            this.filters.push(new Array('uebergewicht','=',this.ugewicht));
          }
          this.options.filters = this.filters;
          this.options.page = 1;
          this.getShippingcosts();
        }
        },
        watch: {
            options(newValue, oldValue) {
                let cont = false
                if(oldValue.page!==newValue.page)cont=true
                if(oldValue.itemsPerPage!==newValue.itemsPerPage)cont=true
                if(oldValue.sortBy!==newValue.sortBy)cont=true
                if(oldValue.sortDesc!==newValue.sortDesc)cont=true
                if(cont){
                  this.getShippingcosts();
                }
            },
        },
        computed: {

        },
    };
</script>
