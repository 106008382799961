<template>
    <v-app>
        <v-row>
            <v-data-table
            :headers="headers"
            :items="ownofferexceededs"
            :items-per-page="itemsPerPage"
            class="ownofferexceededs-table"
            :server-items-length="totalOwnofferexceededs"
            :loading="loading"
            :options.sync="options"
            locale="de"
            :footer-props="footerProps"
            multi-sort
            >
                <template v-slot:header.ARANR="{ header }">
                    {{ header.text }}
                    <v-menu  v-model="aranrfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small :color="aranr ? 'primary' : ''">
                            mdi-filter
                        </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field
                        v-model="aranr"
                        class="pa-4"
                        type="text"
                        label="Geben Sie den Suchbegriff ein"
                        :autofocus="true"
                        ></v-text-field>
                    <v-btn
                    text
                    @click="aranrfilter = false"
                    >
                    <v-icon dark>
                        mdi-close
                        </v-icon>
                    </v-btn>
                        <v-btn
                        @click="aranr = '',filter()"
                        small
                        text
                        color="red"
                        >
                        <v-icon dark>
                        mdi-filter-off
                        </v-icon>
                        </v-btn>
                        <v-btn
                        @click="filter"
                        small
                        text
                        color="green"
                        class="ml-2 mb-2"
                        fixed
                        right
                        >
                        <v-icon dark>
                        mdi-database-search
                        </v-icon>
                    </v-btn>
                    </div>
                    </v-menu>
                </template>
                <template v-slot:header.ARWG="{ header }">
                    {{ header.text }}
                    <v-menu  v-model="arwgfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small :color="arwg ? 'primary' : ''">
                            mdi-filter
                        </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field
                        v-model="arwg"
                        class="pa-4"
                        type="text"
                        label="Geben Sie den Suchbegriff ein"
                        :autofocus="true"
                        ></v-text-field>
                    <v-btn
                    text
                    @click="arwgfilter = false"
                    >
                    <v-icon dark>
                        mdi-close
                        </v-icon>
                    </v-btn>
                        <v-btn
                        @click="arwg = '',filter()"
                        small
                        text
                        color="red"
                        >
                        <v-icon dark>
                        mdi-filter-off
                        </v-icon>
                        </v-btn>
                        <v-btn
                        @click="filter"
                        small
                        text
                        color="green"
                        class="ml-2 mb-2"
                        fixed
                        right
                        >
                        <v-icon dark>
                        mdi-database-search
                        </v-icon>
                    </v-btn>
                    </div>
                    </v-menu>
                </template>
                <template v-slot:header.AREAN="{ header }">
                    {{ header.text }}
                    <v-menu  v-model="areanfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small :color="arean ? 'primary' : ''">
                            mdi-filter
                        </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field
                        v-model="arean"
                        class="pa-4"
                        type="text"
                        label="Geben Sie den Suchbegriff ein"
                        :autofocus="true"
                        ></v-text-field>
                    <v-btn
                    text
                    @click="areanfilter = false"
                    >
                    <v-icon dark>
                        mdi-close
                        </v-icon>
                    </v-btn>
                        <v-btn
                        @click="arean = '',filter()"
                        small
                        text
                        color="red"
                        >
                        <v-icon dark>
                        mdi-filter-off
                        </v-icon>
                        </v-btn>
                        <v-btn
                        @click="filter"
                        small
                        text
                        color="green"
                        class="ml-2 mb-2"
                        fixed
                        right
                        >
                        <v-icon dark>
                        mdi-database-search
                        </v-icon>
                    </v-btn>
                    </div>
                    </v-menu>
                </template>
                <template v-slot:header.ARHE="{ header }">
                    {{ header.text }}
                    <v-menu  v-model="arhefilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small :color="arhe ? 'primary' : ''">
                            mdi-filter
                        </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field
                        v-model="arhe"
                        class="pa-4"
                        type="text"
                        label="Geben Sie den Suchbegriff ein"
                        :autofocus="true"
                        ></v-text-field>
                    <v-btn
                    text
                    @click="arhefilter = false"
                    >
                    <v-icon dark>
                        mdi-close
                        </v-icon>
                    </v-btn>
                        <v-btn
                        @click="arhe = '',filter()"
                        small
                        text
                        color="red"
                        >
                        <v-icon dark>
                        mdi-filter-off
                        </v-icon>
                        </v-btn>
                        <v-btn
                        @click="filter"
                        small
                        text
                        color="green"
                        class="ml-2 mb-2"
                        fixed
                        right
                        >
                        <v-icon dark>
                        mdi-database-search
                        </v-icon>
                    </v-btn>
                    </div>
                    </v-menu>
                </template>
                <template v-slot:header.ARAINR="{ header }">
                    {{ header.text }}
                    <v-menu  v-model="arainrfilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon small :color="arainr ? 'primary' : ''">
                            mdi-filter
                        </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field
                        v-model="arainr"
                        class="pa-4"
                        type="text"
                        label="Geben Sie den Suchbegriff ein"
                        :autofocus="true"
                        ></v-text-field>
                    <v-btn
                    text
                    @click="arainrfilter = false"
                    >
                    <v-icon dark>
                        mdi-close
                        </v-icon>
                    </v-btn>
                        <v-btn
                        @click="arainr = '',filter()"
                        small
                        text
                        color="red"
                        >
                        <v-icon dark>
                        mdi-filter-off
                        </v-icon>
                        </v-btn>
                        <v-btn
                        @click="filter"
                        small
                        text
                        color="green"
                        class="ml-2 mb-2"
                        fixed
                        right
                        >
                        <v-icon dark>
                        mdi-database-search
                        </v-icon>
                    </v-btn>
                    </div>
                    </v-menu>
                </template>
            </v-data-table>
        </v-row>
        
    </v-app>
</template>
<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/getownofferexceededs'
            },
            location_url: {
                type: String,
                default: '/admin/getownofferexceededs/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },
        data: () => ({
            disableOk:false,
            platformtemplates:[],
            supplierstocktemplates: [],
            ownofferexceededs:[],
            totalOwnofferexceededs: 0,
            itemsPerPage: 50,
            loading: true,
            conditions: [],
            filters: [],
            options: {
              page: 1,
              itemsPerPage: 50,
             // sortBy: 'DESC',
              sortDesc: [false],
              mustSort: false,
              multiSort: false,
              sort:'',
              filters:null
            },
            aranrfilter:false,
            arwgfilter:false,
            areanfilter:false,
            arhefilter:false,
            arainrfilter:false,
            aranr:'',
            arwg:'',
            arean:'',
            arhe:'',
            arainr:'',
            headers: [
                    {text: 'id', value: 'id'}, 
                    {text: 'ARANR', value: 'ARANR'},
                    {text: 'ARAPR', value: 'ARAPR'},
                    {text: 'ARBZ1', value: 'ARBZ1'},
                    {text: 'ARBZ2', value: 'ARBZ2'},
                    {text: 'ARBZ3', value: 'ARBZ3'},
                    {text: 'ARWG', value: 'ARWG'},
                    {text: 'ARHE', value: 'ARHE'},
                    {text: 'ARZOGR', value: 'ARZOGR'},
                    {text: 'ARHKE', value: 'ARHKE'},
                    {text: 'ARDEL', value: 'ARDEL'},
                    {text: 'ARVLB', value: 'ARVLB'},
                    {text: 'ARHAN', value: 'ARHAN'},
                    {text: 'AREAN ', value: 'AREAN'},
                    {text: 'ARSVK', value: 'ARSVK'},
                    {text: 'ARAFPK', value: 'ARAFPK'},
                    {text: 'ARAINR', value: 'ARAINR'},
                    {text: 'ARUEGRGW', value: 'ARUEGRGW'},
                    {text: 'T24', value: 'T24'},
                    {text: 'T100', value: 'T100'},
                    {text: 'GETTYGO', value: 'GETTYGO'}
                ],
                footerProps: {
                    'items-per-page-options': [50, 500, 1000, -1],
                    'show-current-page': true,
                    'show-first-last-page': true,
                    disableItemsPerPage: false,
                    itemsPerPageText: 'Einträge pro Seite',
                    itemsPerPageAllText: 'Alle',
                }
        }),
        created() {
            //this.getOwnOfferExceededs();
        },
        methods: {
            async getOwnOfferExceededs() {
                this.loading = true
                this.options.sort = this.options.sortBy[0];
                axios.get('/admin/getownofferexceededs/', {params:this.options})
                .then(function (response) {
                    this.ownofferexceededs = response.data.data;
                    this.totalOwnofferexceededs = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                }.bind(this));
            },
            filter() {
              this.filters.length = 0;
              if (this.aranr) {
                  this.filters.push(new Array('ARANR','=',this.aranr));
              }
              if (this.arwg) {
                  this.filters.push(new Array('ARWG','=',this.arwg));
              }
              if (this.arean) {
                  this.filters.push(new Array('AREAN','=',this.arean));
              }
              if (this.arhe) {
                  this.filters.push(new Array('ARHE','=',this.arhe));
              }
              if (this.arainr) {
                  this.filters.push(new Array('ARAINR','=',this.arainr));
              }
              
              this.options.filters = this.filters;
              this.getOwnOfferExceededs();
            }
        },
        watch: {
            options(newValue, oldValue) {
                let cont = false
                if(oldValue.page!==newValue.page)cont=true
                if(oldValue.itemsPerPage!==newValue.itemsPerPage)cont=true
                if(oldValue.sortBy!==newValue.sortBy)cont=true
                if(oldValue.sortDesc!==newValue.sortDesc)cont=true
                if(cont){
                  this.getOwnOfferExceededs();
                }
            },

        },
    }
  
</script>