<template>
    <v-app>
        <v-row>
            <v-data-table :headers="headers" :items="platformsupplieroffers" :items-per-page="itemsPerPage"
                class="platformsupplieroffers-table" :server-items-length="totalPlatformsupplieroffers" :loading="loading"
                :options.sync="options" locale="de" :footer-props="footerProps" multi-sort>
                <template v-slot:header.platformname="{ header }">
                    {{ header.text }}
                    <v-menu v-model="platformfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="platform ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-autocomplete v-model="platform" :items="platformtemplates" item-text="name" item-value="id"
                                label="Select" persistent-hint single-line clearable autofocus auto-select-first
                                @click:clear="platform = '', clearFilter()"></v-autocomplete>
                            <v-btn text @click="platformfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="platform = '', clearFilter()" small text color="red">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.ARAINR="{ header }">
                    {{ header.text }}
                    <v-menu v-model="arainrfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="arainr ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="arainr" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="arainrfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="arainr = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.ean="{ header }">
                    {{ header.text }}
                    <v-menu v-model="eanfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="ean ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="ean" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="eanfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="ean = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.ARWG="{ header }">
                    {{ header.text }}
                    <v-menu v-model="arwgfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="arwg ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="arwg" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="arwgfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="arwg = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:header.hersteller="{ header }">
                    {{ header.text }}
                    <v-menu v-model="herstellerfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="hersteller ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="hersteller" class="pa-4" type="text"
                                label="Geben Sie den Suchbegriff ein" :autofocus="true"></v-text-field>
                            <v-btn text @click="herstellerfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="hersteller = ''; clearFilter()" small text color="primary" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
                <template v-slot:item.ownprice="{ item }">
                    <v-chip color="cyan" text-color="white" small>
                        {{ convertDoubleToCurrency(item.ownprice) }}
                    </v-chip>
                </template>
                <template v-slot:item.minprice="{ item }">
                    <v-chip color="orange" text-color="white" small>
                        {{ convertDoubleToCurrency(item.minprice) }}
                    </v-chip>
                </template>
                <template v-slot:item.platformbestprice="{ item }">
                    <v-chip color="blue" text-color="white" small>
                        {{ convertDoubleToCurrency(item.platformbestprice) }}
                    </v-chip>
                </template>
                <template v-slot:item.ownoffer="{ item }">
                    <v-chip :color="getColor(item.ispriceexceeded)" text-color="white" small>
                        {{ convertDoubleToCurrency(item.ownoffer) }}
                    </v-chip>
                </template>
                <template v-slot:item.ispriceexceeded="{ item }">
                    <v-icon small color='green' v-if="item.ispriceexceeded == 0">
                        mdi-check-circle
                    </v-icon>
                    <v-icon small color='red' v-if="item.ispriceexceeded == 1">
                        mdi-alert
                    </v-icon>
                </template>
                <template v-slot:item.platformcharges="{ item }">
                    {{ convertDoubleToCurrency(item.platformcharges) }}
                </template>
                <template v-slot:item.shippingcharge="{ item }">
                    {{ convertDoubleToCurrency(item.shippingcharge) }}
                </template>
            </v-data-table>
        </v-row>
    </v-app>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformsupplieroffers'
        },
        location_url: {
            type: String,
            default: '/admin/platformsupplieroffers/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        disableOk: false,
        platformtemplates: [],
        platformsupplieroffers: [],
        totalPlatformsupplieroffers: 0,
        itemsPerPage: 50,
        loading: true,
        conditions: [],
        filters: [],
        options: {
            page: 1,
            itemsPerPage: 50,
            sortDesc: [false],
            mustSort: false,
            multiSort: false,
            sort: '',
            filters: null
        },
        platform: '',
        arainr: '',
        ean: '',
        hersteller: '',
        arwg: '',
        platformfilter: false,
        arainrfilter: false,
        eanfilter: false,
        arwgfilter: false,
        herstellerfilter: false,
        headers: [
            { text: 'id', value: 'id' },
            { text: 'Plattform', value: 'platformname' },
            { text: 'ARAINR', value: 'ARAINR' },
            { text: 'EAN', value: 'ean' },
            { text: 'ARWG', value: 'ARWG' },
            { text: 'Hersteller', value: 'hersteller' },
            { text: 'Tyre24-Id', value: 'tyre24_id' },
            { text: 'suppliernumber1', value: 'suppliernumber1' },
            { text: 'suppliercountry1', value: 'suppliercountry1' },
            { text: 'stock1', value: 'stock1' },
            { text: 'price1', value: 'price1' },
            { text: 'suppliernumber2', value: 'suppliernumber2' },
            { text: 'suppliercountry2', value: 'suppliercountry2' },
            { text: 'stock2', value: 'stock2' },
            { text: 'price2', value: 'price2' },
            { text: 'suppliernumber3', value: 'suppliernumber3' },
            { text: 'suppliercountry3', value: 'suppliercountry3' },
            { text: 'stock3', value: 'stock3' },
            { text: 'price3', value: 'price3' },
            { text: 'suppliernumber4', value: 'suppliernumber4' },
            { text: 'suppliercountry4', value: 'suppliercountry4' },
            { text: 'stock4', value: 'stock4' },
            { text: 'price4', value: 'price4' },
            { text: 'suppliernumber5', value: 'suppliernumber5' },
            { text: 'suppliercountry5', value: 'suppliercountry5' },
            { text: 'stock5', value: 'stock5' },
            { text: 'price5', value: 'price5' },
            { text: 'suppliernumber6', value: 'suppliernumber6' },
            { text: 'suppliercountry6', value: 'suppliercountry6' },
            { text: 'stock6', value: 'stock6' },
            { text: 'price6', value: 'price6' },
            { text: 'suppliernumber7', value: 'suppliernumber7' },
            { text: 'suppliercountry7', value: 'suppliercountry7' },
            { text: 'stock7', value: 'stock7' },
            { text: 'price7', value: 'price7' },
            { text: 'suppliernumber8', value: 'suppliernumber8' },
            { text: 'suppliercountry8', value: 'suppliercountry8' },
            { text: 'stock8', value: 'stock8' },
            { text: 'price8', value: 'price8' },
            { text: 'suppliernumber9', value: 'suppliernumber9' },
            { text: 'suppliercountry9', value: 'suppliercountry9' },
            { text: 'stock9', value: 'stock9' },
            { text: 'price9', value: 'price9' },
            { text: 'suppliernumber10', value: 'suppliernumber10' },
            { text: 'suppliercountry10', value: 'suppliercountry10' },
            { text: 'stock10', value: 'stock10' },
            { text: 'price10', value: 'price10' }, 
            { text: 'Erstellt am', value: 'createddate' },
        ],
        footerProps: {
            'items-per-page-options': [50, 500, 1000, -1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
        }
    }),
    created() {
        this.filters = new Array();
        this.getPlatformtemplates();
    },
    mounted() {
        window.platformsupplieroffers = this;
    },
    methods: {
        async clearFilter() {
            this.filter();
        },
        convertDoubleToCurrency(value) {
            return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
        },
        async getPlatformtemplates() {
            axios.get('/admin/getallplatformtemplates')
                .then(function (response) {
                    this.platformtemplates = response.data;
                }.bind(this));
        },

        async getPlatformSupplierOffers() {
            this.loading = true
            this.options.sort = this.options.sortBy[0];
            axios.get('/admin/getplatformsupplieroffers/', { params: this.options })
                .then(function (response) {
                    this.platformsupplieroffers = response.data.data;
                    this.totalPlatformsupplieroffers = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                }.bind(this));
        },
        getColor(value) {
            if (value > 0) return "red"
            else return "green"
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + this.location_url;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        filter() {
            this.filters.length = 0;
            if (this.arainr) {
                this.filters.push(new Array('ARAINR', '=', this.arainr));
            }
            if (this.ean) {
                this.filters.push(new Array('ean', '=', this.ean));
            }
            if (this.platform) {
                this.filters.push(new Array('platformtemplate_id', '=', this.platform));
            }
            if (this.hersteller) {
                this.filters.push(new Array('hersteller', 'like', this.hersteller));
            }
            if (this.arwg) {
                this.filters.push(new Array('ARWG', '=', this.arwg));
            }
            this.options.filters = this.filters;
            this.getPlatformSupplierOffers();
        }
    },
    watch: {
        options(newValue, oldValue) {
            let cont = false
            if (oldValue.page !== newValue.page) cont = true
            if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
            if (oldValue.sortBy !== newValue.sortBy) cont = true
            if (oldValue.sortDesc !== newValue.sortDesc) cont = true
            if (cont) {
                this.getPlatformSupplierOffers();
            }
        },
    },
};
</script>
