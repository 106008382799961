var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-row',[_c('v-data-table',{staticClass:"platformstock-table",attrs:{"headers":_vm.headers,"items":_vm.platformstocks,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalPlatformstocks,"loading":_vm.loading,"options":_vm.options,"locale":"de","footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.articlenumber",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                    "+_vm._s(header.text)+"\n                    "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.articlenumber ? 'primary' : ''}},[_vm._v("\n                                    mdi-filter\n                                ")])],1)]}}],null,true),model:{value:(_vm.articlenumberfilter),callback:function ($$v) {_vm.articlenumberfilter=$$v},expression:"articlenumberfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.articlenumber),callback:function ($$v) {_vm.articlenumber=$$v},expression:"articlenumber"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.articlenumberfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                    mdi-close\n                                ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.articlenumber = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                    mdi-filter-off\n                                ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                    mdi-database-search\n                                ")])],1)],1)])]}},{key:"header.ean",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.ean ? 'primary' : ''}},[_vm._v("\n                                mdi-filter\n                            ")])],1)]}}],null,true),model:{value:(_vm.eanfilter),callback:function ($$v) {_vm.eanfilter=$$v},expression:"eanfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.ean),callback:function ($$v) {_vm.ean=$$v},expression:"ean"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.eanfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-close\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.ean = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-filter-off\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-database-search\n                            ")])],1)],1)])]}},{key:"header.arwg",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arwg ? 'primary' : ''}},[_vm._v("\n                                mdi-filter\n                            ")])],1)]}}],null,true),model:{value:(_vm.arwgfilter),callback:function ($$v) {_vm.arwgfilter=$$v},expression:"arwgfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"numeric","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arwg),callback:function ($$v) {_vm.arwg=$$v},expression:"arwg"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arwgfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-close\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arwg = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-filter-off\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-database-search\n                            ")])],1)],1)])]}},{key:"header.ARHE",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.hersteller ? 'primary' : ''}},[_vm._v("\n                                mdi-filter\n                            ")])],1)]}}],null,true),model:{value:(_vm.herstellerfilter),callback:function ($$v) {_vm.herstellerfilter=$$v},expression:"herstellerfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.hersteller),callback:function ($$v) {_vm.hersteller=$$v},expression:"hersteller"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.herstellerfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-close\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.hersteller = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-filter-off\n                            ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                                mdi-database-search\n                            ")])],1)],1)])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                    "+_vm._s(_vm.convertDoubleToCurrency(item.price))+"\n            ")]}},{key:"item.compareprice",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm.convertDoubleToCurrency(item.compareprice))+"\n            ")]}},{key:"item.pricepercentage",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.pricepercentage + ' %')+"\n            ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }