<template>
    <div class="row">
        <b-modal
            id="modal-prevent-closing"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                label="Plattform"
                label-for="platformname-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Lagerbestand ist pflicht" 
                >
                    <b-form-input
                        id="platformname-input"
                        v-model="editdata.name" 
                        required
                        disabled
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Lagerbestand"
                label-for="supplierstocktemplate-input"
                label-cols="8"
                label-cols-lg="4" 
                >
                    <b-form-select
                    id="supplierstocktemplate-input"
                    v-model="supplierstockplatformdiscount.supplierstocktemplate_id"
                    :options="supplierstocktemplates"
                    value-field="id"
                    text-field="templateName"
                    disabled-field="notEnabled"
                    :state="supplierstocktemplateState"
                    invalid-feedback="Lagerbestand ist pflicht" 
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="ARWG-Von"
                label-for="arwg-from-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-Von ist pflicht" 
                >
                   <b-form-input
                        id="arwg-from-input"
                        v-model="supplierstockplatformdiscount.ARWG_from" 
                        :state="arwgFromState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="^(?:[0-9]\d*|\d)$"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="ARWG-bis"
                label-for="arwg-to-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-bis ist pflicht" 
                >
                    <b-form-input
                        id="arwg-to-input"
                        v-model="supplierstockplatformdiscount.ARWG_to" 
                        :state="arwgToState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="T24-Rabatt"
                label-for="dependentplatform_discount-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="T24-Rabatt ist pflicht" 
                >
                    <b-form-input
                        id="dependentplatform_discount-input"
                        v-model="supplierstockplatformdiscount.dependentplatform_discount" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        :state="dependentplatform_discountState"
                        invalid-feedback="T24-Rabatt ist pflicht"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="T24-Rabatt-Type"
                    label-for="dependentplatform_discounttype-input"
                    label-cols="8"
                    label-cols-lg="4"
                    invalid-feedback="T24-Rabatt-Type ist pflicht" 
                >
                    <b-form-select
                    id="dependentplatform_discounttype-input"
                    v-model="supplierstockplatformdiscount.dependentplatform_discount_type"
                    :options="typeoptions"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Plattform-Rabatt"
                label-for="platform_discount-input"
                label-cols="8"
                label-cols-lg="4" 
                >
                    <b-form-input
                        id="platform_discount-input"
                        v-model="supplierstockplatformdiscount.platform_discount" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        :state="platform_discountState"
                        invalid-feedback="Plattform-Rabatt ist pflicht"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Plattform-Rabatt-Type"
                    label-for="platform_discount_type-input"
                    label-cols="8"
                    label-cols-lg="4"
                >
                    <b-form-select
                    id="platform_discount_type-input"
                    v-model="supplierstockplatformdiscount.platform_discount_type"
                    :options="typeoptions"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    invalid-feedback="Plattform-Rabatt-Type ist pflicht" 
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Beschreibung"
                label-for="description-input"
                label-cols="8"
                label-cols-lg="4"
                >
                <b-form-input
                    id="description-input"
                    v-model="supplierstockplatformdiscount.description" 
                ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Stand"
                label-for="lastaction-checkbox"
                label-cols="8"
                label-cols-lg="4"
                >
                <b-form-checkbox
                id="lastaction-checkbox"
                v-model="supplierstockplatformdiscount.LastAction"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
                >
                    {{ supplierstockplatformdiscount.LastAction > 0 ? "Aktiviert" : "Deaktiviert" }}
                </b-form-checkbox>
               
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped supplierstockplatformdiscount-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Lagerbestand</th>
                        <th>ARWG-Von</th>
                        <th>ARWG-Bis</th>
                        <th>T24-Rabatt</th>
                        <th>Plattform-Rabatt</th>
                        <th>Beschreibung</th>
                        <th>Stand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        <div class="row">
            <b-button href='#' variant='outline-primary' v-on:click="back">Zurück</b-button>    
        </div> 
    </div>

</template>

<script>
    import axios from 'axios';
  
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/supplierstockplatformdiscounts'
            },
            location_url: {
                type: String,
                default: '/admin/supplierstockplatformdiscount/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Rabatt hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            supplierstocktemplates:{},
            onlynumberspattern : /^[0-9]*$/,
            supplierstockplatformdiscount: {
                id: 0,
                platforms_id:0,
                supplierstocktemplate_id: 0,
                ARWG_from: null,
                ARWG_to: null,
                dependentplatform_discount: null,
                dependentplatform_discount_type: 'PROZENT',
                platform_discount: null,
                platform_discount_type: 'PROZENT',
                description: null,
                LastAction: 1
            },
            // typeoptions:[ {key: 'PREIS', type: 'PREIS'},{key: 'PROZENT', type: 'PROZENT'},],
        }),

        created() {
            this.typeoptions = this.$func.getValueTypeOptions();
            this.supplierstockplatformdiscount.platforms_id = this.editdata.id;
            this.getSupplierStockTemplates();
            this.getSupplierStockPlatformDiscounts('/admin/supplierstockplatformdiscount/getsupplierstockplatformdiscountsdata/'+ this.editdata.id);
        },
        mounted() {
             window.supplierstockplatformdiscounts=this;
        },
        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/platforms/";
            },
            async getSupplierStockPlatformDiscounts(address) {
                 $(document).ready(function() {
                    var table = $('.supplierstockplatformdiscount-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'supplierstocktemplatename', name: 'supplierstocktemplatename'},
                            {data: 'ARWG_from', name: 'ARWG_from'},
                            {data: 'ARWG_to', name: 'ARWG_to'},
                            {data: 'dependentplatform_discount', name: 'dependentplatform_discount'}, 
                            {data: 'platform_discount', name: 'platform_discount'},
                            {data: 'description', name: 'description'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'action'},
                        ],
                    });
                });
            },

            getSupplierStockTemplates: function(){
                axios.get('/admin/getallsupplierstocktemplates')
                .then(function (response) {
                    this.supplierstocktemplates = response.data;
                }.bind(this));
            },

            getEditSupplierStockPlatformDiscount(edit_id){
                axios.get(this.url+'/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Rabatt des Lieferantes für die Plattform bearbeiten"
                    this.supplierstockplatformdiscount = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        $('.supplierstockplatformdiscount-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getDeleteSupplierStockPlatformDiscount(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie diesen Rabatt des Lagerbestandes wirklich löschen?', {
                title: 'WARNUNG',
                headerVariant: "warning",
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            console.log(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Rabatt des Lagerbestandes hinzufügen";
        this.supplierstockplatformdiscount.id = 0;
        this.supplierstockplatformdiscount.platforms_id = this.editdata.id;
        this.supplierstockplatformdiscount.supplierstocktemplate_id = 0;
        this.supplierstockplatformdiscount.ARWG_from = null;
        this.supplierstockplatformdiscount.ARWG_to = null;
        this.supplierstockplatformdiscount.dependentplatform_discount = null;
        this.supplierstockplatformdiscount.dependentplatform_discount_type = 'PROZENT';
        this.supplierstockplatformdiscount.platform_discount = null;
        this.supplierstockplatformdiscount.platform_discount_type = 'PROZENT';
        this.supplierstockplatformdiscount.description = null;
        this.supplierstockplatformdiscount.LadtAction = 1;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.supplierstockplatformdiscount.id > 0){
            axios.put(this.url+'/'+this.supplierstockplatformdiscount.id,
                this.supplierstockplatformdiscount,
            ).then(response => {
            // _this.callback(response);
            console.log(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            console.log(this.supplierstockplatformdiscount);
            axios.post(this.url,
                this.supplierstockplatformdiscount,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
           // this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
      }
        },
        watch: {

        },
        computed: {
            supplierstocktemplateState(){
                return this.supplierstockplatformdiscount.supplierstocktemplate_id == 0 ? false : true;
            },
            arwgFromState() {
                return this.supplierstockplatformdiscount.ARWG_from == null ? false : true;
            },

            arwgToState() {
                return this.supplierstockplatformdiscount.ARWG_to == null ? false : true;
            },

            dependentplatform_discountState(){
                return (this.supplierstockplatformdiscount.dependentplatform_discount == null || this.supplierstockplatformdiscount.dependentplatform_discount == "") ? false : true;;
            },

            platform_discountState(){
                return (this.supplierstockplatformdiscount.platform_discount == null || this.supplierstockplatformdiscount.platform_discount == "") ? false : true;;
            }
        },
    };
</script>
