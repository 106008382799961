<template>
    <div class="vue-csv-uploader">
        <div class="form">
            <div class="form">
                 <b-form-group role="form-group">
                    <label for="input-customernumber">Kundennummer:</label>
                    <b-form-input
                    id="input-customernumber"
                    v-model="customernumber"
                    :state="customernumberState"
                    aria-describedby="input-customernumber-help input-customernumber-feedback"
                    placeholder="Kundennummer"
                    type="number"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-customernumber-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-name">Name:</label>
                    <b-form-input
                    id="input-name"
                    v-model="name"
                    :state="nameState"
                    aria-describedby="input-name-help input-name-feedback"
                    placeholder="Name"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-name-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-desc">Land:</label>
                    <b-form-select
                    v-model="country"
                    :options="countryoptions"
                    class="mb-5"
                    value-field="code"
                    text-field="country"
                    disabled-field="notEnabled"
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-desc">Beschreibung:</label>
                    <b-form-input
                    id="input-desc"
                    v-model="description"
                    placeholder="Beschreibung"
                    trim
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                     <b-button href='#' variant='secondary' v-on:click="back">Abbrechen</b-button>
                    <slot name="submit" :submit="submit" v-if="customernumber && name">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/wholesaler/'
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            countryoptions:{},
            customernumber: null,
            name: null,
            country: null,
            description: '',
            enableSubmit: false,
        }),

        created() {
            this.getCountries();

            this.id = this.editdata.id;
            this.customernumber = this.editdata.customernumber;
            this.name = this.editdata.name;
            this.country = this.editdata.country;
            this.description = this.editdata.description;
        },

        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/wholesaler";
            },
            getCountries: function(){
                axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
            },
            submit() {
                const _this = this;

                var wholesaler = {
                    'id': this.id,
                    'customernumber':  this.customernumber,
                    'name':  this.name,
                    'country': this.country,
                    'description': this.description 
                 };
                 axios.put(this.url + this.id,
                    wholesaler,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                   console.log(response);
                   this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            showMsgBoxOne(callback) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    window.location = location.protocol + "//" + location.hostname + "/admin/wholesaler";
                })
                .catch(err => {
                    // An error occurred
                })
            },
        },
        watch: {

        },
        computed: {
            nameState() {
                if(this.name == null)
                    return false
                return this.name.length > 3 ? true : false
            },
            customernumberState(){
                if(this.customernumber == null)
                    return false;
                return   /^\d+/.test(this.customernumber);
            }
        },
    };
</script>
