<template>
    <div class="vue-csv-uploader">
        <v-app>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="editdata.supplier.supplierName" label="Lieferantenname" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="editdata.templateName" label="Lieferantenvorlage" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="editdata.supplierNumber" label="Lieferantennummer" disabled></v-text-field>
                    </v-col>
                </v-row>
                <div class="table-responsive">
                    <!-- https://smarttutorials.net/dynamically-add-or-remove-table-row-using-vuejs/ -->
                    <button type='button' class="btn btn-info" @click="addNewRow">
                        <i class="fas fa-plus-circle"></i>
                        Mindestmenge Hinzufügen
                    </button>

                    <table class="table" ref="mindestmengetable">
                        <thead>
                            <tr>
                                <th scope="col">Min. Menge</th>
                                <th scope="col">Warengruppe von</th>
                                <th scope="col">Warengruppe bis</th>
                                <th scope="col">Startdatum</th>
                                <th scope="col">Enddatum</th>
                                <th scope="col">Beschreibung</th>
                                <th scope="col">#</th>
                            </tr>
                        </thead>
                        <tr v-for="(mindestmenge, k) in mindestmengen" :key="k">
                            <td>
                                <v-text-field hide-details="auto" type="number" min="1" step="1"
                                    v-model="mindestmenge.anzahl" :rules="[rules.required]" required></v-text-field>
                            </td>
                            <td>
                                <v-text-field hide-details="auto" type="number" min="1" step="1"
                                    v-model="mindestmenge.warengruppeVon" required></v-text-field>
                            </td>
                            <td>
                                <v-text-field hide-details="auto" type="number" min="1" step="1"
                                    v-model="mindestmenge.warengruppeBis" required></v-text-field>
                            </td>
                            <td>
                                <v-menu ref="validdatefrommenu_" v-model="mindestmenge.validdatefrommenu"
                                    :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px"
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="mindestmenge.display_validdatefrom" persistent-hint
                                            prepend-icon="mdi-calendar" v-bind="attrs"
                                            @blur="mindestmenge.display_validdatefrom = parseDate(mindestmenge.validDateFrom)"
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="mindestmenge.validDateFrom" no-title
                                        @input="mindestmenge.validdatefrommenu = false"
                                        @change="mindestmenge.display_validdatefrom = parseDate(mindestmenge.validDateFrom)">
                                        <v-btn text @click="mindestmenge = onClear(mindestmenge)">Löschen</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </td>
                            <td>
                                <v-menu ref="validdateuntilmenu" v-model="mindestmenge.validdateuntilmenu"
                                    :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px"
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="mindestmenge.display_validdateuntil" persistent-hint
                                            prepend-icon="mdi-calendar" v-bind="attrs"
                                            @blur="mindestmenge.display_validdateuntil = parseDate(mindestmenge.validDateUntil)"
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="mindestmenge.validDateUntil" no-title
                                        @input="mindestmenge.validdateuntilmenu = false"
                                        @change="mindestmenge.display_validdateuntil = parseDate(mindestmenge.validDateUntil)"></v-date-picker>
                                </v-menu>
                            </td>
                            <td>
                                <v-text-field hide-details="auto" v-model="mindestmenge.description"></v-text-field>
                            </td>
                            <td scope="row" class="trashIconContainer">
                                <i class="far fa-trash-alt fa-2x" style="color: red;"
                                    @click="deleteRow(k, mindestmenge)"></i>
                            </td>
                        </tr>
                    </table>
                </div>
                <v-btn-toggle borderless>
                    <v-btn value="left" @click="back">
                        Zurück
                    </v-btn>
                    <slot name="submit" :submit="submit">
                        <v-btn color="success" @click="submit">
                            Speichern
                        </v-btn>
                    </slot>
                </v-btn-toggle>
            </v-form>
        </v-app>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/supplierstockmindestmenge'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },
        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        rules: {
            required: value => !!value || 'Erforderlich',
            counter: value => value.length <= 20 || 'Max 20 characters',
            price: value => {
                return /^\d+(\.\d+)*$/.test(value) || 'Ungültig'
            },
        },
        enableSubmit: false,
        supplierstocktemplate_id: 0,
        mindestmengen: [{
            supplierstocktemplate_id: '',
            anzahl: 0,
            warengruppeVon: '',
            warengruppeBis: '',
            validDateFrom: '',
            validDateUntil: '',
            description: '',
            display_validdatefrom: '',
            validdatefrommenu: false,
            display_validdateuntil: '',
            validdateuntilmenu: false,
        }],
    }),

    created() {
        this.mindestmengen = [];
        this.supplierstocktemplate_id = this.editdata.id;
        this.getSupplierStockMindestmengen();
    },

    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/supplier/" + this.editdata.supplier.id + "/supplierstocktemplates";
        },
        getSupplierStockMindestmengen: function () {
            axios.get('/admin/supplierstockmindestmenge/getsupplierstockmindestmengen/' + this.supplierstocktemplate_id)
                .then(function (response) {
                    this.mindestmengen = response.data;
                }.bind(this));
        },
        addNewRow() {
            this.mindestmengen.unshift({
                id: 0,
                supplierstocktemplate_id: this.supplierstocktemplate_id,
                anzahl: 0,
                warengruppeVon: '',
                warengruppeBis: '',
                validDateFrom: '',
                validDateUntil: '',
                description: ''
            });
        },

        deleteRow(index, mindestmenge) {
            const _this = this;
            var idx = _this.mindestmengen.indexOf(mindestmenge);
            console.log(idx, index);
            if (idx > -1) {
                _this.mindestmengen.splice(idx, 1);
                if (mindestmenge.id > 0) {
                    axios.delete('/admin/supplierstockmindestmenge/' + mindestmenge.id
                    ).then(response => {
                        _this.showMsgBoxOne(response, false);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }
            }
        },

        submit() {
            const _this = this;
            axios.post(this.url,
                _this.mindestmengen,
            ).then(response => {
                _this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        },
        showMsgBoxOne(callback, change) {
            if (callback.data.data.code > 200) {
                this.$bvModal.msgBoxOk(callback.data.data.message, {
                    title: 'FEHLER',
                    headerBgVariant: 'danger',
                    headerTextVariant: 'light',
                    titleVariant: 'danger',
                    //bodyBgVariant: 'warning',
                    bodyTextVariant: 'danger',
                    size: 'lg',
                    okVariant: 'warning'
                });
                return;
            }
            this.$bvModal.msgBoxOk(callback.data.data.message, {
                title: 'MELDUNG',
                headerBgVariant: 'success',
                headerTextVariant: 'light',
                titleVariant: 'success',
                //bodyBgVariant: 'warning',
                bodyTextVariant: 'success',
                size: 'lg',
                okVariant: 'success'
            })
                .then(value => {
                    if (change) {
                        window.location.reload();
                    }
                })
                .catch(err => {

                })
        },

        checkDate(date) {
            return /^\d+(\.\d\d)*$/.test(date);
        },
        makeErrorToast() {
            this.$bvToast.toast('Test', {
                title: 'Fehler'
            })
        },
        showError(mp) {
            if (mp.anzahl < 1) {
                this.$bvModal.msgBoxOk('Die Menge darf nicht 0 sein!', {
                    title: 'FEHLER',
                    headerBgVariant: 'danger',
                    headerTextVariant: 'light',
                    titleVariant: 'danger',
                    bodyTextVariant: 'danger',
                    size: 'lg',
                    okVariant: 'warning'
                });
            }
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate(date) {
            console.log(date);
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        onClear(mindestmenge) {
            mindestmenge.validDateFrom = null;
            mindestmenge.validDateUntil = null;
            mindestmenge.display_validdatefrom = '';
            mindestmenge.validdatefrommenu = false;
            mindestmenge.display_validdateuntil = '';
            mindestmenge.validdateuntilmenu = false;
            return mindestmenge;
        },

    },
    watch: {

    },
    computed: {
        validDateFromState() {
            if (this.mindestmenge.validDateFrom == null)
                return true;
            return this.mindestmenge.validDateFrom.length > 0 ? this.checkDate(this.mindestmenge.validDateFrom) : false;
        },
        validDateUntilState() {
            if (this.mindestmenge.validDateUntil == null)
                return true;
            return this.mindestmenge.validDateUntil.length > 0 ? this.checkDate(this.mindestmenge.validDateUntil) : false;
        }
    },
};
</script>
