<template>
    <v-app>
        <v-data-table :headers="headers" :items="platformexportfilters" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Filter der Plattformexport {{ editdata.name }}
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="form">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="editdata.name" label="Plattform"
                                                    disabled></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-autocomplete v-model="editedItem.herstellernumber" :items="herstellers"
                                                    label="Hersteller" item-text="name"
                                                    item-value="number"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="editedItem.ARWG_from" label="ARWG von" type="number"
                                                    min="0"></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="editedItem.ARWG_to" label="ARWG bis" type="number"
                                                    min="0"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                    hide-details="auto"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-switch v-model="editedItem.lastAction" label="Status"
                                                    color="green"></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="800px">
                        <v-card>
                            <v-card-title class="text-h6 warning">Sind Sie sicher, dass Sie diesen ignorierten Lieferant
                                löschen
                                möchten?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                                <v-btn color="red darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.supplier_id="{ item }">
                {{ item.supplier.supplierName }}
            </template>
            <template v-slot:item.herstellernumber="{ item }">
                {{ item.herstellername }}
            </template>
            <template v-slot:item.lastAction="{ item }">
                <v-switch v-model="item.lastAction" color="green" disabled label=""></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformexportfilters'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: vm => ({
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogWarning: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
        },
        headers: [
            { text: 'Id', align: 'start', sortable: true, value: 'id' },
            { text: 'Hersteller', value: 'herstellernumber' },
            { text: 'ARWG von', value: 'ARWG_from' },
            { text: 'ARWG bis', value: 'ARWG_to' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'lastAction' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,
        allplatforms: [],
        herstellers: [],
        suppliers: [],
        platformexportfilters: [],
        editedItem: {
            id: 0,
            pexportfilesendconfig_id: vm.editdata.id,
            herstellernumber: null,
            ARWG_from: null,
            ARWG_to: null,
            description: '',
            lastAction: 1
        },
        defaultItem: {
            id: 0,
            pexportfilesendconfig_id: vm.editdata.id,
            herstellernumber: null,
            ARWG_from: null,
            ARWG_to: null,
            description: '',
            lastAction: 1
        },
    }),
    created() {
        this.getHerstellers();
        this.getPlatformExportFilters();
    },
    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Filter hinzufügen' : 'Filter bearbeiten'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        dialogWarning(val) {
            val || this.closeDialogWarning()
        },
    },

    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/getplatformexportfilesendconfigs/" + this.editdata.platform_id;
        },

        getHerstellers: function () {
            axios.get('/admin/getallhersteller')
                .then(function (response) {
                    this.herstellers = response.data;
                }.bind(this));
        },

        async getSuppliers() {
            axios.get('/admin/getsuppliers')
                .then(function (response) {
                    this.suppliers = response.data;
                }.bind(this));
        },
        getPlatformExportFilters: function () {
            axios.get('/admin/platformexportfilesendconfig/' + this.editdata.id + '/getplatformexportfilters')
                .then(function (response) {
                    this.platformexportfilters = response.data;
                }.bind(this));
        },
        editItem(item) {
            this.editedItem = item;
            this.dialog = true
        },

        deleteItem(item) {
            this.editedItem = item;
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            const _this = this;
            axios.delete(_this.url + '/' + _this.editedItem.id)
                .then(response => {
                    _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                     this.closeDelete();
                });
        },

        filterItem(item) {
            window.location = location.protocol + "//" + location.hostname + "/admin/platformexportfilesendconfig/" + item.id + "/platformexportfilters";
        },

        close() {
            this.$nextTick(() => {
                this.$refs.form.reset();
                this.editedItem = this.defaultItem;
                console.log('NextTick wurde ausgeführt'); 
            });
            this.dialog = false;
            console.log('LastAction: '+this.editedItem.lastAction);
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeDialogWarning() {
            this.dialogWarning = false
        },

        async save() {
            const { valid } = await this.$refs.form.validate()
            const _this = this;
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }

            // Hide the modal manually
            this.$nextTick(() => { 
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getPlatformExportFilters();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
    },
}
</script>