<template>
        <div class="form">
                <b-form-group role="form-group">
                    <label for="input-kreditor">Kreditor:</label>
                    <b-form-input
                    id="input-kreditor"
                    type="number"
                    v-model="provider.kreditor"
                    :state="kreditorState"
                    aria-describedby="input-kreditor-help input-kreditor-feedback"
                    placeholder="Kreditor"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-kreditor-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-versanddienst">Versanddienst:</label>
                    <b-form-input
                    id="input-versanddienst"
                    v-model="provider.versanddienst"
                    :state="versanddienstState"
                    aria-describedby="input-versanddienst-help input-versanddienst-feedback"
                    placeholder="Versanddienst"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-versanddienst-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-desc">Beschreibung:</label>
                    <b-form-input
                    id="input-desc"
                    v-model="provider.description"
                    placeholder="Beschreibung"
                    trim
                    ></b-form-input>
                </b-form-group>
                <b-form-group v-if="provider.kreditor && provider.versanddienst">
                    <slot name="submit" :submit="submit">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
        </div>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            value: Array,
            url: {
                type: String,
                default: '/admin/shippingserviceprovider'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            provider: {
                kreditor: null,
                versanddienst: null,
                description: null
            },
                enableSubmit: false,
        }),

        created() {

        },

        methods: {
            submit() {
                const _this = this;
               
                axios.post(this.url,
                    this.provider,
                ).then(response => {
                   // _this.callback(response);
                   console.log(response);
                   this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });

            },
            showMsgBoxOne(callback) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    window.location = location.protocol + "//" + location.hostname + "/admin/shippingserviceprovider";
                })
                .catch(err => {
                    // An error occurred
                })
            },
        },
        watch: {

        },
        computed: {
            versanddienstState() {
                if(this.provider.versanddienst == null)
                    return false
                return true;
            },
            kreditorState() {
                if(this.provider.kreditor == null)
                    return false
                return true;
            },
        },
    };
</script>
