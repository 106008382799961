<template>
    <v-app>
      <v-data-table
        :headers="headers"
        :items="wholesalers"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [50, 100, 150, -1],
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Datensätze pro Seite',
          pageText: '{0}-{1} von {2}'
        }"
      >
          <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Händler der Plattformvorlage {{ editdata.platform.name }} ignorieren </v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  class="mx-2"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-app-bar
                  dark
                  color="success"
                >
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                </v-app-bar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                      >
                        <v-text-field
                          v-model="editdata.platform.name"
                          label="Plattform*"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-text-field
                          v-model="editdata.name"
                          label="Vorlagename*"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                          <v-select
                              v-model="editedItem.customernumber"
                              :items="wholesalersoptions"
                              label="Händler*"
                              item-text="displayname"
                              item-value="customernumber"
                              required
                          ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                          <v-select
                              v-model="editedItem.country"
                              :items="countryoptions"
                              label="Land*"
                              item-text="country"
                              item-value="code"
                              required
                          ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="computedDateFromFormatted"
                            label="Startdatum"
                            persistent-hint
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.fromdate" no-title @input="menu = false" @change="dateChanged">
                          <v-btn text @click="onClearFromdate" v-if="editedItem.fromdate">Löschen</v-btn>
                        </v-date-picker>
                      </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="computedDateToFormatted"
                            label="Enddatum"
                            persistent-hint
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.todate" no-title @input="menu2 = false" @change="dateChanged">
                          <v-btn text @click="onClearTodate" v-if="editedItem.todate">Löschen</v-btn>
                        </v-date-picker>
                      </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                          <v-switch
                          v-model="editedItem.ignore"
                          label="ignorieren"
                          color="green"
                          ></v-switch>
                      </v-col>
                      <v-col
                      cols="12"
                      >
                      <v-text-field
                      label="Beschreibung"
                      v-model="editedItem.description" 
                      hide-details="auto"
                      ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                  color="primary"
                    @click="save"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Händler löschen möchten?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogWarning" max-width="600px">
              <v-card>
                <v-card-title class="text-h5 warning">Das Startdatum darf nicht größer als Enddatum sein!</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialogWarning">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.fromdate="{ item }">
          {{ formatDate(item.fromdate)}}
        </template>
        <template v-slot:item.todate="{ item }">
          {{ formatDate(item.todate) }}
        </template>
        <template v-slot:item.ignore="{ item }">
        <v-switch
            v-model="item.ignore"
            color="green"
            disabled
            label=""
            ></v-switch>
      </template>
        <template v-slot:item.description="{ item }">
          {{ item.description }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="blue"
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="deleteItem(item)"
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>  
      </template>
      </v-data-table>
        <template>
          <v-row>
            <v-col cols="3">
              <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
            </v-col>
          </v-row> 
        </template>
      </v-app>
  </template>
  <script>
   import { drop, every, forEach, get, isArray, map, set } from 'lodash';
    export default {
      props: {
          value: Array,
          editdata: null,
          url: {
              type: String,
              default: '/admin/platformtemplatewholesaler'
          },
          callback: {
                  type: Function,
                  default: () => ({})
              },
              catch: {
                  type: Function,
                  default: () => ({})
              },
              finally: {
                  type: Function,
                  default: () => ({})
              },
      },
  
      data: vm => ({
        menu: false,
        menu2: false,
        wholesalers: [],
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogWarning: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
          },
        headers: [
          { text: 'Id', align: 'start', sortable: true, value: 'id', },
          { text: 'Händlernummer', value: 'customernumber' },
          { text: 'Händler', value: 'name' },
          { text: 'Land', value: 'country' },
          { text: 'Startdatum', value: 'fromdate' },
          { text: 'Enddatum', value: 'todate' },
          { text: 'Ignorieren', value: 'ignore' },
          { text: 'Beschreibung', value: 'description' },
          { text: 'Aktionen', value: 'actions', sortable:false },
        ],
        success:false,
        countryoptions:{},
        wholesalersoptions:{},
        editedItem: {
            id:0,
            platformtemplate_id: vm.editdata.id,
            customernumber: '',
            name: '',
            fromdate:null,
            todate:null,
            country: '',
            description: '',
            ignore:1
        },
        defaultItem: {
            id:0,
            platformtemplate_id: vm.editdata.id,
            customernumber: '',
            name: '',
            fromdate:null,
            todate:null,
            country: '',
            description: '',
            ignore:1
        },
      }),
      created () {
        this.getCountries();
        this.getPlatformtemplateWholesalers();
        this.getWholesalers();
      },
      computed: {
        computedDateFromFormatted () {
          return this.formatDate(this.editedItem.fromdate)
        },
        computedDateToFormatted () {
          return this.formatDate(this.editedItem.todate)
        },
        formTitle () {
          return this.editedItem.id === 0 ? 'Ignorierter Händler hinzufügen' : 'Ignorierter Händler bearbeiten'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
        dialogWarning (val){
          val || this.closeDialogWarning()
        },
        
        menu (val) {
          //val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
      },

      methods: {

        back: function(){ 
                window.location = location.protocol + "//" + location.hostname +"/admin/platforms/"+this.editdata.platform.id+'/platformtemplates';
        },
        dateChanged: function(){
            if(this.editedItem.fromdate >= this.editedItem.todate){
              console.log("Startdatum ist größer als Enddatum");
              this.dialogWarning = true
            }
        },
        onClearFromdate: function(){
            this.editedItem.fromdate = null;
            this.menu = false;
        },
        onClearTodate: function(){
            this.editedItem.todate = null;
            this.menu2 = false;
        },
        getPlatformtemplateWholesalers: function(){
            axios.get('/admin/platformtemplatewholesaler/getplatformtemplatewholesalers/'+this.editdata.id)
            .then(function (response) {
                this.wholesalers = response.data;
            }.bind(this));
        },
        getCountries: function(){
            axios.get('/admin/getshippingcountries')
            .then(function (response) {
                this.countryoptions = response.data;
            }.bind(this));
        },
        getWholesalers: function(){
            let optionlar = []
            axios.get('/admin/getWholesalers')
            .then(function (response) {
               //console.log(response.data);
               this.wholesalersoptions = response.data;
              /*  $.each(response.data, function(key, value) {
                    optionlar.push({
                        'id': value.id,
                        'customernumber': value.customernumber,
                        'name': value.customernumber+' => '+value.name
                    });
                });
              */
            }.bind(this));
            this.wholesalersoptions = optionlar;
        },

        formatDate (date) {
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${day}.${month}.${year}`
        },

        editItem (item) {
          this.editedItem = item;
          this.dialog = true
        },
  
        deleteItem (item) {
          this.editedItem = item;
          this.dialogDelete = true
        },
  
        deleteItemConfirm () {
          axios.delete(this.url+'/'+this.editedItem.id)
            .then(response => {
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
          this.closeDelete();
        },
  
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = this.defaultItem; 
          })
        },
  
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = this.defaultItem;
          })
        },

        closeDialogWarning () {
          this.dialogWarning = false
        },

        getWholesalerName() {
          const _this = this;
          var result = _.find(_this.wholesalersoptions, function(item) { return item.customernumber === _this.editedItem.customernumber; });
          return result.name;
        },
  
        save () {
          const _this = this;
          console.log('customernumber: '+_this.editedItem.customernumber);
          _this.editedItem.name = _this.getWholesalerName();
          if(this.editedItem.id > 0){
              axios.put(this.url+'/'+this.editedItem.id,
                  this.editedItem,
              ).then(response => {
                 _this.callback(response);
                  this.showMsgBoxOne(response, true);
              }).catch(response => {
                  _this.catch(response);
              }).finally(response => {
                  _this.finally(response);
              });
          }else{
              axios.post(this.url,
                  this.editedItem,
              ).then(response => {
                  _this.callback(response);
                  this.showMsgBoxOne(response, true);
              }).catch(response => {
                  _this.catch(response);
              }).finally(response => {
                  _this.finally(response);
              });
          }
  
          // Hide the modal manually
          this.$nextTick(() => {
             // this.resetModal();
              this.$bvModal.hide('modal-prevent-closing')
          })
          this.close()
        },
  
        showMsgBoxOne(callback, change) {
          this.$bvModal.msgBoxOk(callback.data)
          .then(value => {
              if(change)
              {
                this. getPlatformtemplateWholesalers();
              }
          })
          .catch(err => {
              // An error occurred
          })
        },
      },
    }
  </script>