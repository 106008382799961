import Vue from 'vue'
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

// opts options are optional, but I've added them as a simple.
const opts = {
    theme: { dark: false },   
}

export default new Vuetify(opts)