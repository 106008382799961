<template>
    <div class="row">
        <b-container fluid>
          <b-row>
            <b-col cols="6">
                <b-form-group
                    label="Lieferant:"
                    label-for="supplierName-input"
                    label-cols="3"
                    label-cols-lg="3" 
                    invalid-feedback="Erforderlich" 
                    >
                    <b-form-input
                        id="supplierName-input"
                        v-model="editdata.supplier.supplierName" 
                        required
                        size="sm"
                        readonly
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group
                label="Bestandsvorlage:"
                label-for="templateName-input"
                label-cols="3"
                label-cols-lg="3" 
                invalid-feedback="Erforderlich" 
                >
                <b-form-input
                    id="templateName-input"
                    v-model="editdata.templateName" 
                    required
                    size="sm"
                    readonly
                ></b-form-input>
                </b-form-group>
            </b-col>
            </b-row>
            <!--b-form-group
            label="Standard Frachten:"
            label-for="check-isStandardShippingCountries"
            label-cols="1"
            label-cols-lg="1" 
            v-if="this.editdata.supplier.supplierType == 1"
            >
                <b-form-checkbox v-model="isStandardShippingCountries" name="check-isStandardShippingCountries" switch value="1" unchecked-value="0" @change="changeIsStandardShippingCountries($event)">
                    {{ isStandardShippingCountries == 1 ? 'Standard Frachten des Herstellers '+editdata.supplier.supplierName: '' }}
                </b-form-checkbox>
            </b-form-group -->
       
            <b-form-group
            label=" "
            label-for="new-input"
            label-cols="12"
            label-cols-lg="6" 
            class="float-left"
            >
            <b-button id="new-input" variant="success" v-b-tooltip.hover title="Neue Fracht hinzufügen" v-b-modal.modal-prevent-closing><i class="fa fa-plus-circle"></i> Neue Fracht</b-button>
            </b-form-group>   
        </b-container>
        <b-modal
            id="modal-prevent-closing"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            title="Neue Fracht hinzufügen"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
        >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
            label="Lieferant"
            label-for="name-input"
            label-cols="8"
            label-cols-lg="4" 
            invalid-feedback="Lieferant ist pflicht" 
            >
            <b-form-input
                id="name-input"
                v-model="suppliercountry.supplierName" 
                required
                readonly
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Lieferantennr."
            label-for="name-input"
            label-cols="8"
            label-cols-lg="4" 
            invalid-feedback="Lieferantennr. ist pflicht" 
            >
            <b-form-input
                id="name-input"
                v-model="suppliercountry.supplierNumber" 
                required
                readonly
            ></b-form-input>
            </b-form-group>

            <b-form-group
                label="Land"
                label-for="country-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Land ist pflicht" 
            >
                <b-form-select
                id="country-input"
                v-model="suppliercountry.code"
                :options="countryoptions"
                value-field="code"
                text-field="country"
                disabled-field="notEnabled"
                required
                ></b-form-select>
            </b-form-group>
            <b-form-group
            label="Anzahl"
            label-for="parsel-input"
            label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Name is required" 
            >
            <b-form-input
                id="parsel-input"
                v-model="suppliercountry.parsel" 
                type="number"
                min="1"
                value="1"
                step="1" 
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Preis"
            label-for="price-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Name is required" 
            >
            <b-form-input
                id="price-input"
                v-model="suppliercountry.price" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Warengruppe von"
            label-for="warengruppevon-input"
            label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Warengruppe ist pflicht" 
            >
            <b-form-input
                id="warengruppevon-input"
                v-model="suppliercountry.warengruppevon" 
                type="number"
                min="0"
                value="0"
                step="1" 
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Warengruppe bis"
            label-for="warengruppebis-input"
            label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Warengruppe bis ist pflicht" 
            >
            <b-form-input
                id="warengruppebis-input"
                v-model="suppliercountry.warengruppebis"
                type="number"
                min="0"
                value="0"
                step="1" 
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Zollgr. von"
            label-for="zollgroessevon-input"
            label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="zollgrossevon-input"
                v-model="suppliercountry.zollgrossevon" 
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Zollgr. bis"
            label-for="zollgroessebis-input"
            label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="zollgrossebis-input"
                v-model="suppliercountry.zollgrossebis" 
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Gewicht"
            label-for="gewicht-input"
            label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Gewicht ist pflicht" 
            >
            <b-form-input
                id="gewicht-input"
                v-model="suppliercountry.gewicht" 
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Übergewicht"
            label-for="uebergewicht-input"
            label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Übergewicht ist pflicht" 
            >
            <b-form-input
                id="uebergewicht-input"
                v-model="suppliercountry.uebergewicht" 
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Type"
                label-for="type-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Type des Preisaufschlages ist pflicht" 
            >
                <b-form-select
                 id="type-input"
                v-model="suppliercountry.surchargetype"
                :options="surchargetypeoptions"
                value-field="key"
                text-field="type"
                disabled-field="notEnabled"
                ></b-form-select>
            </b-form-group>
            <b-form-group
            label="Preisaufschlag"
            label-for="surcharge-input"
            label-cols="8"
            label-cols-lg="4"
             v-if="suppliercountry.surchargetype > 0" 
            >
            <b-form-input
                id="surcharge-input"
                v-model="suppliercountry.surcharge" 
                type="number"
                min="0"
                value="0"
                step="0.01" 
                pattern="[0-9]+([\\,|\\.][0-9]+)?"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Startdatum"
            label-for="surchargestartdate-input"
             label-cols="8"
            label-cols-lg="4"
             v-if="suppliercountry.surchargetype == 2" 
            >
            <b-form-input
                id="surchargestartdate-input"
                v-model="suppliercountry.surchargestartdate" 
                type="date"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Enddatum"
            label-for="surchargestopdate-input"
             label-cols="8"
            label-cols-lg="4"

             v-if="suppliercountry.surchargetype == 2" 
            >
            <b-form-input
                id="surchargestopdate-input"
                v-model="suppliercountry.surchargestopdate"
                type="date"
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Beschreibung"
            label-for="description-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Name is required" 
            >
            <b-form-input
                id="description-input"
                v-model="suppliercountry.description" 
            ></b-form-input>
            </b-form-group>
        </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped supplierstocktemplatescountries-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>supplierName</th>
                        <th>supplierNumber</th>
                        <th>code</th>
                        <th>Land</th>
                         <th>Anzahl</th>
                        <th>Price</th>
                        <th>WG-von</th>
                        <th>WG-bis</th>
                        <th>Zollgr. von</th>
                        <th>Zollgr. bis</th>
                        <th>Gewicht</th>
                        <th>Übergewicht</th>
                        <th>Type</th>
                        <th>Preisaufschlag</th>
                        <th>Startdatum</th>
                        <th>Endatum</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                         <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        <template>
            <v-row>
            <v-col cols="3">
                <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
            </v-col>
            </v-row> 
        </template>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/supplierstockshippingcountries'
            },
            editStock_Url: {
                type: String,
                default:  '/admin/supplierstocktemplate/'
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            headerBgVariant: 'success',
            headerTextVariant: 'light',
                enableSubmit: false,
                countryoptions:{},
                supplierstocktemplate_id:0,
                supplierName: '',
                supplierNumber: '',
                isStandardShippingCountries: 0,
                suppliercountry: {
                    id: 0,
                    supplierstocktemplate_id:0,
                    supplierName: '',
                    supplierNumber: '',
                    code: '',
                    country: '',
                    price: '',
                    parsel:1,
                    warengruppevon:0,
                    warengruppebis:0,
                    zollgrossevon:'',
                    zollgrossebis:'',
                    gewicht:'',
                    uebergewicht:'',
                    surchargetype:0,
                    surcharge:0.00,
                    surchargestartdate: null,
                    surchargestopdate: null,
                    description: null
                },
                
                surchargetypeoptions:[
                    {
                        key: 0,
                        type: 'Deaktiviert'
                    },
                    {
                        key: 1,
                        type: 'Aktiviert ohne Zeitfenster'
                    },
                    {
                        key: 2,
                        type: 'Aktiviert mit Zeitfenster'
                    },
                ]
        }),

        created() {

            this.getCountries();
            
            this.supplierstocktemplate_id = this.editdata.id;
            this.supplierName = this.editdata.supplierName;
            this.supplierNumber = this.editdata.supplierNumber;
            this.isStandardShippingCountries = this.editdata.isStandardShippingCountries;
            this.suppliercountry.id = 0;
            this.suppliercountry.supplierstocktemplate_id = this.editdata.id;
            this.suppliercountry.supplierName = this.editdata.supplier.supplierName;
            this.suppliercountry.supplierNumber = this.editdata.supplierNumber;
           //this.getSupplierCountries();
            this.getSupplierShippingsCountries('/admin/suppliershippingcountries/getshippingscountries/'+this.supplierstocktemplate_id);
        },
        mounted() {
            console.log('Component mounted.')
        },

        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/supplier/"+this.editdata.supplier.id+"/supplierstocktemplates";
            },
            changeIsStandardShippingCountries(event){
                console.log('Changed: '+event);
                const _this = this;
                var stocktemplate = {
                    'id': this.supplierstocktemplate_id,
                    'suppliername':  this.supplierName,
                    'suppliernumber':  this.supplierNumber,
                    'suppliertype': this.editdata.supplierType,
                    'isStandardShippingCountries':  this.isStandardShippingCountries == "1" ? 1 : 0
                }
                axios.put(this.editStock_Url + this.supplierstocktemplate_id,
                    stocktemplate,
                ).then(response => {
                   // _this.callback(response);
                   console.log(response);
                   this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });

            },
                async getSupplierShippingsCountries(address) {
                 $(document).ready(function() {
                    var collapsedGroups = {};
                    var table = $('.supplierstocktemplatescountries-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        columnDefs: [
                            { visible: false, targets: 1 },
                            { visible: false, targets: 2 },
                            { visible: false, targets: 3 },
                        ],

                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'supplierName', name: 'supplierName'},
                            {data: 'supplierNumber', name: 'supplierNumber'},
                            {data: 'code', name: 'code'},
                            {data: 'country', name: 'country'},
                            {data: 'parsel', name: 'parsel'},
                            {data: 'price', name: 'price'},
                            {data: 'warengruppevon', name: 'WG-von'},
                            {data: 'warengruppebis', name: 'WG-bis'},
                            {data: 'zollgrossevon', name: 'Zollgr. von'},
                            {data: 'zollgrossebis', name: 'Zollgr. bis'},
                            {data: 'gewicht', name: 'Gewicht'},
                            {data: 'uebergewicht', name: 'Übergewicht'},
                            {data: 'surchargetype', name: 'surchargetype'},
                            {data: 'surcharge', name: 'surcharge'},
                            {data: 'surchargestartdate', name: 'surchargestartdate'},
                            {data: 'surchargestopdate', name: 'surchargestopdate'},
                            {data: 'description', name: 'Beschreibung'},
                            {data: 'LastAction', name: 'LastAction'},
                            {data: 'action', name: 'Aktionen'},
                        ],
                        order: [[ 4, 'asc' ]],
                        rowGroup: {
                            // Uses the 'row group' plugin
                            dataSrc: 'country',
                            startRender: function(rows, group) {
                                var collapsed = !!collapsedGroups[group];
                                rows.nodes().each(function(r) {
                                    r.style.display = 'none';
                                    if (collapsed) {
                                        r.style.display = '';
                                    }
                                });

                                return $('<tr/>')
                                    .append('<td colspan="16" style="background: #FFF8DC; color: blue;"><b>' + group + '</b></td>')
                                    .attr('data-name', group)
                                    .toggleClass('collapsed', collapsed);
                            }                         
                        }
                    });
                $('.supplierstocktemplatescountries-table tbody').on( 'click', 'tr.dtrg-group', function () {
                         var name = $(this).data('name');
                        collapsedGroups[name] = !collapsedGroups[name];
                        table.draw(false);
                } );

                  

                });
            },
            getSupplierCountries: function(){
                axios.get('/admin/suppliershippingcountries/getshippingscountries/'+this.supplierstocktemplate_id)
                .then(function (response) {
                    this.rows = response.data;
                }.bind(this));
            },
            getCountries: function(){
                axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
            },

            submit() {
                 const _this = this;
                axios.post(this.url,
                    this.supplier_countries,
                ).then(response => {
                   // _this.callback(response);
                   console.log(response);
                   this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if(change)
                    {
                        window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplateshippings/"+this.supplierstocktemplate_id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        //this.nameState = valid
        return valid
      },
      resetModal() {
        this.suppliercountry.price = '';
        this.suppliercountry.parsel = 1;
        this.suppliercountry.warengruppevon = 0;
        this.suppliercountry.warengruppebis = 0;
        this.suppliercountry.zollgrossevon = '';
        this.suppliercountry.zollgrossebis = '';
        this.suppliercountry.surchargetype = 0;
        this.suppliercountry.surchargestartdate = null;
        this.suppliercountry.surchargestopdate = null;
        this.suppliercountry.description = null;
      },
      handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {

         const _this = this;
        axios.post(this.url,
            this.suppliercountry,
        ).then(response => {
            // _this.callback(response);
            console.log(response);
            this.showMsgBoxOne(response, true);
        }).catch(response => {
            _this.catch(response);
        }).finally(response => {
            _this.finally(response);
        });

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
      }
        },
        watch: {

        },
        computed: {
            isocodeState(){
                if(this.isocode == null)
                    return false;

                if(this.isocode.length != 2)
                    return false
                return true;
            },
            priceState(){
                return this.price == null ? false : true
            },

        },
    };
</script>
