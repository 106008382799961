<template>
    <b-col sm="12">
        <div class="form">
                <b-form-group
                    label="Landestype"
                    label-for="type-input"
                    label-cols="4"
                    label-cols-lg="2"
                    invalid-feedback="Type ist pflicht" 
                >
                    <b-form-select
                    id="type-input"
                    v-model="type"
                    :options="typeoptions"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                    label="Gehört"
                    label-for="parent-input"
                    label-cols="4"
                    label-cols-lg="2"
                    v-if="type !== 'LAND'"
                >
                    <b-form-select
                    id="parent-input"
                    v-model="parent_code"
                    :options="parentcountryoptions"
                    value-field="code"
                    text-field="country"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                    label="Landesname"
                    label-for="input-name"
                    label-cols="4"
                    label-cols-lg="2"
                >
                    <b-form-input
                    id="input-name"
                    v-model="name"
                    :state="nameState"
                    aria-describedby="input-name-help input-name-feedback"
                    placeholder="Landname"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-name-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label="National"
                    label-for="checkbox-isNational"
                    label-cols="4"
                    label-cols-lg="2"
                >
                    <b-form-checkbox
                    id="checkbox-isNational"
                    v-model="isNational"
                    name="checkbox-isNational"
                    value="1"
                    unchecked-value="0"
                    >
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group
                    label="EU"
                    label-for="checkbox-isEU"
                    label-cols="4"
                    label-cols-lg="2"
                >
                    <b-form-checkbox
                    id="checkbox-isEU"
                    v-model="isEU"
                    name="checkbox-isEU"
                    value="1"
                    unchecked-value="0"
                    >
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group
                label="ISO2-Code"
                label-for="input-isocode"
                label-cols="4"
                label-cols-lg="2"
                >
                    <b-col sm="3">
                        <b-form-input
                        id="input-iso2code"
                        v-model="iso2code"
                        :state="iso2codeState"
                        aria-describedby="input-iso2code-help input-iso2code-feedback"
                        placeholder="ISO2-Code"
                        trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-iso2code-feedback">
                            Die zweibuchstabige Länderabkürzung ist erforderlich!
                        </b-form-invalid-feedback>
                    </b-col>
                </b-form-group>
                <b-form-group
                    label="ISO3-Code"
                    label-for="input-isocode"
                    label-cols="4"
                    label-cols-lg="2"
                >
                    <b-col sm="3">
                        <b-form-input
                        id="input-iso3code"
                        v-model="isocode"
                        :state="isocodeState"
                        aria-describedby="input-iso3code-help input-iso3code-feedback"
                        placeholder="ISO3-Code"
                        trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-iso3code-feedback">
                            Die dreibuchstabige Länderabkürzung ist erforderlich! 
                        </b-form-invalid-feedback>
                    </b-col>
                </b-form-group>
                <b-form-group
                    label="Beschreibung"
                    label-for="input-desc"
                    label-cols="4"
                    label-cols-lg="2"
                >
                    <b-form-input
                    id="input-desc"
                    v-model="description"
                    placeholder="Beschreibung"
                    trim
                    ></b-form-input>
                </b-form-group>

                <b-form-group>
                    <slot name="submit" :submit="submit">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
        </div>
    </b-col>
</template>

<script>
    import axios from 'axios';

    export default {
        props: {
            value: Array,

            url: {
                type: String,
                default: '/admin/shippingcountries'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            parentcountryoptions:{},
            type: 'LAND',
            name: null,
            parent_code: null,
            isocode: null,
            iso2code: null,
            isNational: 0,
            isEU: 0,
            price: 0.00,
            description: '',
            enableSubmit: false,
            typeoptions:[ {key: 'ALLE', type: 'Alle Länder inklusiv DEU'}, {key: 'ALLE_EX_DEU', type: 'Alle Länder exklusiv DEU' }, {key: 'EU', type: 'EU Länder'}, {key: 'EU_EX_DEU', type: 'EU Länder exklusiv DEU'}, {key: 'LAND', type: 'Land'},{key: 'GEBIET', type: 'Gebiet eines Landes'},{key: 'INSEL', type: 'Insel eines Landes'},],
        }),

        created() {
            this.getParentCountries();
        },

        methods: {
            getParentCountries: function(){
                axios.get('/admin/getshippingparentcountries')
                .then(function (response) {
                    this.parentcountryoptions = response.data;
                }.bind(this));
            },
            submit() {
                const _this = this;
                let formData = new FormData();
                formData.append('type',  this.type);
                formData.append('parent_code',  this.parent_code);
                formData.append('isEU',  this.isEU);
                formData.append('countrycode',  this.isocode);
                formData.append('iso2code', this.iso2code);
                formData.append('countryname',  this.name);
                formData.append('shippingprice', this.price );
                formData.append('description', this.description );
                axios.post(_this.url,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                   // _this.callback(response);
                   _this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });

            },
            showMsgBoxOne(callback) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    window.location = location.protocol + "//" + location.hostname + "/admin/shippingcountries";
                })
                .catch(err => {
                    // An error occurred
                })
            },
        },
        watch: {

        },
        computed: {
            nameState() {
                if(this.name == null)
                    return false
                return this.name.length > 3 ? true : false
            },
            isocodeState(){
                if(this.isocode == null || this.isocode.length !== 3)
                    return false;
                return true;
            },
            iso2codeState() {
                if (this.iso2code == null || this.iso2code.length !== 2)
                    return false;
                return true;
            },
        },
    };
</script>
