<template>
    <v-app>
      <v-data-table
      :headers="headers"
      :items="platformblockingartikels"
      :items-per-page="itemsPerPage"
      class="platformblockingartikels-table"
      :server-items-length="totalPlatformblockingartikels"
      :loading="loading"
      :options.sync="options"
      locale="de"
      :footer-props="footerProps"
      multi-sort
      >
            <template v-slot:header.ARAINR="{ header }">
            {{ header.text }}
            <v-menu  v-model="arainrfilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="arainr ? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="arainr"
                  class="pa-4"
                  type="text"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="arainrfilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                @click="arainr = ''; clearFilter()"
                small
                text
                color="red"
                class="ml-2 mb-2"
              >
              <v-icon dark>
                  mdi-filter-off
                </v-icon>
              </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                   right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
            <template v-slot:header.AREAN="{ header }">
            {{ header.text }}
            <v-menu  v-model="areanfilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="arean ? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="arean"
                  class="pa-4"
                  type="text"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="areanfilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                @click="arean = ''; clearFilter()"
                small
                text
                color="red"
                class="ml-2 mb-2"
              >
              <v-icon dark>
                  mdi-filter-off
                </v-icon>
              </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                   right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
          <template v-slot:header.ARHE="{ header }">
            {{ header.text }}
            <v-menu  v-model="arhefilter" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small :color="arhe ? 'primary' : ''">
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white; width: 280px">
                <v-text-field
                  v-model="arhe"
                  class="pa-4"
                  type="text"
                  label="Geben Sie den Suchbegriff ein"
                  :autofocus="true"
                ></v-text-field>
              <v-btn
              text
              @click="arhefilter = false"
              >
              <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-btn
                @click="arhe = '';clearFilter()"
                small
                text
                color="primary"
                class="ml-2 mb-2"
              >
              <v-icon dark>
                mdi-filter-off
              </v-icon>
              </v-btn>
                <v-btn
                  @click="filter"
                  small
                  text
                  color="green"
                  class="ml-2 mb-2"
                  fixed
                  right
                >
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
              </div>
            </v-menu>
          </template>
          <template v-slot:item.Sperrartikel="{ item }">
            {{convertToDecimal(item.Sperrartikel)}}
            </template>
      </v-data-table>
      <template>
            <v-row>
            <v-col cols="3">
                <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
            </v-col>
            </v-row> 
        </template>
    </v-app>
</template>

<script>
    import axios from 'axios';
  
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/platformblockingartikels'
            },
            location_url: {
                type: String,
                default: '/admin/platformblockingartikels/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },

            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            disableOk:false,
            platformtemplates:[],
            platformblockingartikels:[],
            totalPlatformblockingartikels: 0,
            itemsPerPage: 50,
            loading: true,
            conditions: [],
            filters: [],
            options: {
              platform_id: 0,
              page: 1,
              itemsPerPage: 50,
             // sortBy: 'DESC',
              sortDesc: [false],
              mustSort: false,
              multiSort: false,
              sort:'',
              filters:null
            },
            arainr:'',
            arean: '',
            arwg: '',
            arhe:'',
            countrycode:'',

            arainrfilter: false,
            areanfilter: false,
            arwgfilter: false,
            arhefilter: false,
            countrycodefilter: false,
            headers: [
                    {text: 'id', value: 'id'}, 
                    {text: 'ARAINR', value: 'ARAINR'},
                    {text: 'AREAN', value: 'AREAN'},
                    {text: 'ARWG', value: 'ARWG'},
                    {text: 'ARHE', value: 'ARHE'},
                    {text: 'ARVLB', value: 'ARVLB'},
                    {text: 'ARAFPK', value: 'ARAFPK'},
                    { text: 'ARALB', value: 'ARALB' },
                    {text: 'Sperrartikel', value: 'Sperrartikel'},

                ],
                footerProps: {
                    'items-per-page-options': [50, 500, 1000, -1],
                    'show-current-page': true,
                    'show-first-last-page': true,
                    disableItemsPerPage: false,
                    itemsPerPageText: 'Einträge pro Seite',
                    itemsPerPageAllText: 'Alle',
                }
            
        }),
        created() {
          this.options.platform_id = this.editdata.id;
           this.filters = new Array();
        },
        mounted() {
             window.platformblockingartikels=this;
        },
        methods: {
          back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/platforms";
            },
          convertToDecimal(value){
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
          },
          async clearFilter(){
            this.filter();
          },
          async getPlatformBlockingArtikels() {
            this.loading = true
            this.options.sort = this.options.sortBy[0];
            axios.get('/admin/getplatformblockingartikels/', {params:this.options})
            .then(function (response) {
              this.platformblockingartikels = response.data.data;
              this.totalPlatformblockingartikels = response.data.total
              this.itemsPerPage = response.data.per_page
              this.loading = false
              if(typeof response.data === 'string' && this.platformblockingartikels === undefined)
              {
                this.$bvModal.msgBoxOk(response.data, {
              title: 'WARNUNG',
              headerBgVariant: 'warning',
              bodyTextVariant: 'danger',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            });
              }
            }.bind(this)).catch(error => {
              console.log(error)
            });
          },
          getColor (value) {
              if (value > 0) return "red"
              else return "green"
          },
          showMsgBoxOne(callback, change) {
              this.$bvModal.msgBoxOk(callback.data)
              .then(value => {
                  if(change)
                  {
                      window.location = location.protocol + "//" + location.hostname + this.location_url;
                  }
              })
              .catch(err => {
                  // An error occurred
              })
          },
          checkFormValidity() {
              const valid = this.$refs.form.checkValidity()
              //this.nameState = valid
              return valid
          },
          handleOk(bvModalEvent) {
              bvModalEvent.preventDefault()
              this.handleSubmit()
          },
        
        filter() {
          this.filters.length = 0;
          if (this.arainr) {
            this.filters.push(new Array('ARAINR','=',this.arainr));
          }
          if (this.arean) {
              this.filters.push(new Array('AREAN','=',this.arean));
          }
         
          if (this.arwg) {
            this.filters.push(new Array('ARWG', '=', this.arwg));
          }
          if (this.arhe) {
            this.filters.push(new Array('ARHE', '=', this.arhe));
          }
          this.options.filters = this.filters;
          this.getPlatformBlockingArtikels();
        }
        },
        watch: {
            options(newValue, oldValue) {
                let cont = false
                if(oldValue.page!==newValue.page)cont=true
                if(oldValue.itemsPerPage!==newValue.itemsPerPage)cont=true
                if(oldValue.sortBy!==newValue.sortBy)cont=true
                if(oldValue.sortDesc!==newValue.sortDesc)cont=true
                if(cont){
                  this.getPlatformBlockingArtikels();
                }
            },
        },
        computed: {
        
        },
    };
</script>
