<template>
    <div class="row">
        <b-modal
            id="modal-ownoffersclient"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
                label="Kundenname"
                label-for="name-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Kundenname ist pflicht" 
            >
                <b-form-input
                    id="name-input"
                    v-model="ownoffersclient.name" 
                    :state="nameState"
                    required
                ></b-form-input>
            </b-form-group>
           
            <b-form-group
                label="Versandart"
                label-for="sendtype-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Versandart ist pflicht" 
            >
                <b-form-select
                id="sendtype-input"
                v-model="ownoffersclient.sendtype"
                :options="typeoptions"
                value-field="key"
                text-field="type"
                disabled-field="notEnabled"
                required
                ></b-form-select>
            </b-form-group>
            <b-form-group
                label="FTP-Host"
                label-for="ftp_host-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="FTP-Host ist pflicht" 
                v-if="ownoffersclient.sendtype !== 'LOKAL'"
            >
                <b-form-input
                    id="ftp_host-input"
                    v-model="ownoffersclient.ftp_host" 
                    :state="ftp_hostState"
                ></b-form-input>
            </b-form-group>
            <b-form-group
            label="FTP-Benutzername"
            label-for="ftp_username-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="FTP-Benutzername ist pflicht" 
            v-if="ownoffersclient.sendtype !== 'LOKAL'"
            >
                <b-form-input
                    id="ftp_username-input"
                    v-model="ownoffersclient.ftp_username" 
                    :state="ftp_usernameState"
                    required
                ></b-form-input>
            </b-form-group>
            
            <b-form-group
            label="FTP-Passwort"
            label-for="ftp_password-input"
             label-cols="8"
            label-cols-lg="4"
            v-if="ownoffersclient.sendtype !== 'LOKAL'"
            invalid-feedback="FTP-Passwort ist pflicht" 
            >
            <b-form-input
                id="ftp_password-input"
                v-model="ownoffersclient.ftp_password" 
                :state="ftp_passwordState"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Dateipfad"
            label-for="ftp_filepath-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Dateipfad ist pflicht" 
            >
            <b-form-input
                id="ftp_filepath-input"
                v-model="ownoffersclient.ftp_filepath"
                :state="ftp_filepathState"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Dateiname"
            label-for="ftp_filename-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Dateiname ist pflicht" 
            >
            <b-form-input
                id="ftp_filename-input"
                v-model="ownoffersclient.ftp_filename"
                :state="ftp_filenameState"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Beschreibung"
            label-for="description-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="description-input"
                v-model="ownoffersclient.description" 
            ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Status"
                label-for="lastaction-checkbox"
                label-cols="8"
                label-cols-lg="4"
                >
                <b-form-checkbox
                id="lastaction-checkbox"
                v-model="ownoffersclient.LastAction"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
                >
                    {{ ownoffersclient.LastAction > 0 ? "Aktiviert" : "Deaktiviert" }}
                </b-form-checkbox>
            </b-form-group>
        </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped ownoffersclient-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Kundenname</th>
                        <th>Versandart</th>
                        <th>Dateiname</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            url: {
                type: String,
                default: '/admin/ownoffersclients'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Kunden hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
                enableSubmit: false,
                ownoffersclient: {
                    id:0,
                    name : null,
                    sendtype : 'FTP',
                    ftp_host : null,
                    ftp_username : null,
                    ftp_password : null,
                    ftp_filepath : null,
                    ftp_filename : null,
                    description: null, 
                    LastAction : 1
                },
                typeoptions:[ {key: 'FTP', type: 'FTP'},{key: 'SFTP', type: 'SFTP'},{key: 'LOKAL', type: 'LOKAL'}],
        }),

        created() {

            this.getEditOwnOffersClientsData('/admin/getownoffersclientsdata');
        },
        mounted() {
             window.ownoffersclient=this;
        },
        methods: {
                async getEditOwnOffersClientsData(address) {
                 $(document).ready(function() {
                    var collapsedGroups = {};
                    var table = $('.ownoffersclient-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'name', name: 'name'},
                            {data: 'sendtype', name: 'sendtype'},
                            {data: 'ftp_filename', name: 'ftp_filename'},
                            {data: 'description', name: 'Beschreibung'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'Aktionen'},
                        ],
                        //order: [[ 4, 'asc' ]],
                    });
                });
            },
            getEditOwnOffersClient(edit_id){
                axios.get('/admin/ownoffersclients/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-ownoffersclient');
                    this.modalname = "Kunden bearbeiten"
                    this.ownoffersclient = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        $('.ownoffersclient-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            getDeleteOwnOffersClient(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie den Kunde wirklich löschen?', {
                title: 'Bitte bestätigen Sie',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Kunden hinzufügen";
        this.ownoffersclient.id = 0;
        this.ownoffersclient.name = null;
        this.ownoffersclient.sendtype = 'FTP';
        this.ownoffersclient.ftp_host = null;
        this.ownoffersclient.ftp_username = null;
        this.ownoffersclient.ftp_password = null;
        this.ownoffersclient.ftp_filepath = null;
        this.ownoffersclient.ftp_filename = null;
        this.ownoffersclient.description = null;
        this.ownoffersclient.LastAction = 1;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.ownoffersclient.id > 0){
            axios.put(this.url+'/'+this.ownoffersclient.id,
                this.ownoffersclient,
            ).then(response => {
            // _this.callback(response);
            console.log(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.ownoffersclient,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-ownoffersclient')
        })
      }
        },
        watch: {

        },
        computed: {
            
            nameState() {
                if(this.ownoffersclient.name == null || this.ownoffersclient.name == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },

            ftp_hostState() {
                if(this.ownoffersclient.sendtype !== 'LOKAL' && (this.ownoffersclient.ftp_host == null || this.ownoffersclient.ftp_host == ""))
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },
            ftp_usernameState() {
                if(this.ownoffersclient.sendtype !== 'LOKAL' && (this.ownoffersclient.ftp_username == null || this.ownoffersclient.ftp_username == ""))
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },

            ftp_passwordState() {
                if(this.ownoffersclient.sendtype !== 'LOKAL' && (this.ownoffersclient.ftp_password == null || this.ownoffersclient.ftp_password == ""))
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },

            ftp_filepathState() {
                if(this.ownoffersclient.ftp_filepath == null || this.ownoffersclient.ftp_filepath == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },
            ftp_filenameState() {
                if(this.ownoffersclient.ftp_filename == null || this.ownoffersclient.ftp_filename == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },
        },
    };
</script>
