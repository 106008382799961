<template>
    <div class="row">
        <b-modal
            id="modal-platformexportfilerecipient"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
                label="Plattform"
                label-for="platformname-input"
                label-cols="8"
                label-cols-lg="4" 
            >
                <b-form-input
                    id="platformname-input"
                    v-model="editdata.platform.name" 
                    disabled
                ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Export-Datei"
                label-for="name-input"
                label-cols="8"
                label-cols-lg="4" 
            >
                <b-form-input
                    id="name-input"
                    v-model="editdata.name" 
                    disabled
                ></b-form-input>
            </b-form-group>
           
            <b-form-group
                label="Empfänger"
                label-for="recipient-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Empfänger ist pflicht" 
            >
                <b-form-select
                id="recipient-input"
                v-model="platformexportfilerecipient.townoffersclients_id"
                :options="ownoffersclients"
                value-field="id"
                text-field="name"
                disabled-field="notEnabled"
                :state="ownoffersclientState"
                required
                ></b-form-select>
            </b-form-group>
            <b-form-group
            label="Dateipfad"
            label-for="ftp_filepath-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Dateipfad ist pflicht" 
            >
            <b-form-input
                id="ftp_filepath-input"
                v-model="platformexportfilerecipient.ftp_filepath"
                :state="ftp_filepathState"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Dateiname"
            label-for="ftp_filename-input"
             label-cols="8"
            label-cols-lg="4"
            invalid-feedback="Dateiname ist pflicht" 
            >
            <b-form-input
                id="ftp_filename-input"
                v-model="platformexportfilerecipient.ftp_filename"
                :state="ftp_filenameState"
                required
            ></b-form-input>
            </b-form-group>
            <b-form-group
            label="Beschreibung"
            label-for="description-input"
             label-cols="8"
            label-cols-lg="4"
            >
            <b-form-input
                id="description-input"
                v-model="platformexportfilerecipient.description" 
            ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Status"
                label-for="lastaction-checkbox"
                label-cols="8"
                label-cols-lg="4"
                >
                <b-form-checkbox
                id="lastaction-checkbox"
                v-model="platformexportfilerecipient.LastAction"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
                >
                    {{ platformexportfilerecipient.LastAction > 0 ? "Aktiviert" : "Deaktiviert" }}
                </b-form-checkbox>
            </b-form-group>
        </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped platformexportfilerecipients-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Plattform</th>
                        <th>Bestandsdatei</th>
                        <th>Empfänger</th>
                        <th>Dateipfad</th>
                        <th>Dateiname</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/platformexportfilerecipients'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Empfänger hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            enableSubmit: false,
            ownoffersclients: {},
            platformexportfilerecipient: {
                id:0,
                pexportfilesendconfig_id : null,
                townoffersclients_id : null,
                ftp_filepath : null,
                ftp_filename : null,
                description: null, 
                LastAction : 1
            },
        }),

        created() {
            this.platformexportfilerecipient.pexportfilesendconfig_id = this.editdata.id;
            this.getEditPlatformExportFileRecipientsData('/admin/getplatformexportfilerecipientsdata/'+this.editdata.id);
            this.getOwnOffersClients();
        },
        mounted() {
             window.platformexportfilerecipient=this;
        },
        methods: {
            async getOwnOffersClients(){
                axios.get('/admin/getownoffersclients')
                .then(function (response) { 
                    this.ownoffersclients = response.data;
                }.bind(this));
            },
            async getEditPlatformExportFileRecipientsData(address) {
                $(document).ready(function() {
                var collapsedGroups = {};
                var table = $('.platformexportfilerecipients-table').DataTable({
                    processing: false,
                    serverSide: true,
                    searching: true,
                    searchable:true,
                    responsive: true,
                    select: true,
                    pageLength: 50,
                    language: {
                        url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                    },
                    ajax: address,
                    columns: [
                        {data: 'id', name: 'id'},
                        {data: 'platformname', name: 'Plattform'},
                        {data: 'exportfilename', name: 'exportfilename'},
                        {data: 'ownoffersclientname', name: 'ownoffersclientname'},
                        {data: 'ftp_filepath', name: 'ftp_filepath'},
                        {data: 'ftp_filename', name: 'ftp_filename'},
                        {data: 'description', name: 'Beschreibung'},
                        {data: 'status', name: 'status'},
                        {data: 'action', name: 'Aktionen'},
                    ],
                    //order: [[ 4, 'asc' ]],
                });
            });
            },
            getEditPlatformExportFileRecipient(edit_id){
                axios.get('/admin/platformexportfilerecipients/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-platformexportfilerecipient');
                    this.modalname = "Empfänger bearbeiten"
                    this.platformexportfilerecipient = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        $('.platformexportfilerecipients-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            getDeletePlatformExportFileRecipient(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie den Empfänger wirklich löschen?', {
                title: 'Bitte bestätigen Sie',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Empfänger hinzufügen";
        this.platformexportfilerecipient.id = 0;
        this.platformexportfilerecipient.townoffersclients_id = null;
        this.platformexportfilerecipient.ftp_filepath = null;
        this.platformexportfilerecipient.ftp_filename = null;
        this.platformexportfilerecipient.description = null;
        this.platformexportfilerecipient.LastAction = 1;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.platformexportfilerecipient.id > 0){
            axios.put(this.url+'/'+this.platformexportfilerecipient.id,
                this.platformexportfilerecipient,
            ).then(response => {
            // _this.callback(response);
            //console.log(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.platformexportfilerecipient,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.$bvModal.hide('modal-platformexportfilerecipient')
            this.resetModal();
        })
      }
        },
        watch: {

        },
        computed: {
            ownoffersclientState() {
                if(this.platformexportfilerecipient.townoffersclients_id == null || this.platformexportfilerecipient.townoffersclients_id == "")
                {
                   
                    this.disableOk = true;
                    return false;
                }
                var selected_ownofferclient = this.ownoffersclients.filter((client) => client.id === this.platformexportfilerecipient.townoffersclients_id);
                this.platformexportfilerecipient.ftp_filepath = selected_ownofferclient[0].ftp_filepath;
                this.platformexportfilerecipient.ftp_filename = selected_ownofferclient[0].ftp_filename;
                this.disableOk = false;
                return true;
            },

            ftp_filepathState() {
                if(this.platformexportfilerecipient.ftp_filepath == null || this.platformexportfilerecipient.ftp_filepath == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },
            ftp_filenameState() {
                if(this.platformexportfilerecipient.ftp_filename == null || this.platformexportfilerecipient.ftp_filename == "")
                {
                    this.disableOk = true;
                    return false;
                }
                 this.disableOk = false;
                return true;
            },
        },
    };
</script>
