<template>
    <v-app>
        <div>
            <v-form ref="form">
                <v-text-field v-model="filename" label="Dateiname" required readonly></v-text-field>
                <div class="table-responsive">
                    <!-- https://smarttutorials.net/dynamically-add-or-remove-table-row-using-vuejs/ -->

                    <v-data-table :headers="headers" :items="exportfileconfigs" single-select:true class="elevation-1"
                        v-sortable-data-table @sorted="saveOrder" item-key="id">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                                <v-toolbar-title>Spalten</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-dialog v-model="dialog" max-width="1000px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-badge :value="hover" color="green accent-4" content="Neue Spalte hinzufügen" left
                                            transition="slide-x-transition">
                                            <v-hover v-model="hover">
                                                <v-btn color="success" dark class="mx-2" icon left fab small v-bind="attrs"
                                                    v-on="on">
                                                    <v-icon dark>mdi-table-column-plus-after</v-icon>
                                                </v-btn>
                                            </v-hover>
                                        </v-badge>
                                    </template>
                                    <v-card elevation="15">
                                        <v-card-title>
                                            <span class="text-h5">{{ formTitle }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="6" md="4">
                                                        <v-text-field v-model="editedItem.exportColumnTitel"
                                                            label="Spaltenüberschrift" hint="Überschrift der Spalte"
                                                            persistent-hint required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="4">
                                                        <v-autocomplete v-model="editedItem.exportColumnType"
                                                            :items="columntypesoptions" label="Spaltentype"
                                                            hint="Der Type der Spalte" persistent-hint
                                                            required></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="4">
                                                        <v-autocomplete v-model="editedItem.artikelColumnName"
                                                            :items="artikelcolumns" item-text="text" item-value="text"
                                                            label="Spaltenwert" hint="Der Wert der Spalte" persistent-hint
                                                            required></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="4">
                                                        <v-text-field v-model="editedItem.description"
                                                            label="Beschreibung"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="4">
                                                        <v-switch color="success" v-model="editedItem.LastAction"
                                                            :model-value="true"
                                                            :label="` ${editedItem.LastAction > 0 ? 'Aktiviert' : 'Deaktiviert'}`">
                                                        </v-switch>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red darken-1" text @click="closeNew" class="ml-2 mt-5" outlined
                                                rounded small>
                                                Abbrechen
                                            </v-btn>
                                            <v-btn color="blue darken-1" text @click="saveNew" class="ml-2 mt-5" outlined
                                                rounded small>
                                                Speichern
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-dialog v-model="dialogDelete" max-width="700px">
                                    <v-card>
                                        <v-card-title class="text-h5">Sind Sie sicher, dass Sie diese Spalte löschen
                                            möchten?</v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closeDelete">Nein</v-btn>
                                            <v-btn color="red darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.exportColumnTitel="props">
                            <v-edit-dialog :return-value.sync="props.item.exportColumnTitel" large persistent
                                @save="save(props.item)" @cancel="cancel" @open="open" @close="close"
                                cancel-text="Abbrechen" save-text="Speichern">
                                {{ props.item.exportColumnTitel }}
                                <template v-slot:input>
                                    <div class="mt-4 text-h6">
                                        Spaltenname bearbeiten
                                    </div>
                                    <v-text-field v-model="props.item.exportColumnTitel" :rules="[max25chars]" label="Edit"
                                        single-line counter autofocus></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.exportColumnType="props">
                            <v-edit-dialog :return-value.sync="props.item.exportColumnType" large persistent
                                @save="save(props.item)" @cancel="cancel" @open="open" @close="close"
                                cancel-text="Abbrechen" save-text="Speichern">
                                <div>{{ props.item.exportColumnType }}</div>
                                <template v-slot:input>
                                    <div class="mt-4 text-h6">
                                        Type bearbeiten
                                    </div>
                                    <v-combobox :items="columntypesoptions"
                                        v-model="props.item.exportColumnType"></v-combobox>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.artikelColumnName="props">
                            <v-edit-dialog :return-value.sync="props.item.artikelColumnName" large persistent
                                @save="save(props.item)" @cancel="cancel" @open="open" @close="close"
                                cancel-text="Abbrechen" save-text="Speichern">
                                <div>{{ props.item.artikelColumnName }}</div>
                                <template v-slot:input>
                                    <div class="mt-4 text-h6">
                                        Spaltenwert bearbeiten
                                    </div>
                                    <v-autocomplete :items="artikelcolumns" item-text="text" item-value="text"
                                        v-model="props.item.artikelColumnName"></v-autocomplete>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.description="props">
                            <v-edit-dialog :return-value.sync="props.item.description" large persistent
                                @save="save(props.item)" @cancel="cancel" @open="open" @close="close"
                                cancel-text="Abbrechen" save-text="Speichern">
                                {{ props.item.description }}
                                <template v-slot:input>
                                    <div class="mt-4 text-h6">
                                        Beschreibung bearbeiten
                                    </div>
                                    <v-text-field v-model="props.item.description" label="Edit" single-line
                                        autofocus></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.exportColumnOrder="{ item }">
                            <v-badge :value="orderhover" color="deep-purple accent-4" content="Reihenfolge mit Maus ändern"
                                right inline transition="slide-x-transition">
                                <v-hover v-model="orderhover">
                                    <v-icon small color="blue" class="mr-2">
                                        mdi-arrow-up-down-bold-outline
                                    </v-icon>
                                </v-hover>
                            </v-badge>
                            {{ item.exportColumnOrder }}
                        </template>
                        <template v-slot:item.LastAction="{ item }">
                            <v-edit-dialog :return-value.sync="item.LastAction" large persistent
                                    @save="save(item)" @cancel="cancel" @open="open" @close="close"
                                    cancel-text="Abbrechen" save-text="Speichern">
                                     <v-switch color="success"
                                        v-model="item.LastAction"
                                        :model-value="true"
                                        disabled
                                      > </v-switch>
                                    <template v-slot:input>
                                        <div class="mt-4 text-h6">
                                            Status bearbeiten
                                        </div>
                                        <v-switch color="success" v-model="item.LastAction"
                                            :model-value="true"
                                        > </v-switch>
                                    </template>
                                </v-edit-dialog>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small color="blue" class="mr-2" @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small color="red" tag="Löschen" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                        {{ snackText }}

                        <template v-slot:action="{ attrs }">
                            <v-btn v-bind="attrs" text @click="snack = false" class="ml-2 mt-5" outlined rounded small>
                                Schließen
                            </v-btn>
                        </template>
                    </v-snackbar>

                </div>
                <b-form-group>
                    <b-button variant='secondary' v-on:click="back">Zurück</b-button>
                    <slot name="submit" :submit="submit">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
            </v-form>
        </div>
    </v-app>
</template>
<script>
//https://muhimasri.com/blogs/create-an-editable-dynamic-table-with-bootstrap-vue/
//https://nortonsafe.search.ask.com/web?q=vue%20editable%20table&chn=prev&o=APN11910&geo=en_US&prt=&ctype=&ver=&tpr=121
//https://www.npmjs.com/package/bootstrap-vue-editable-table
//https://github.com/muhimasri/b-editable-table
import axios from 'axios';
import Sortable from 'sortablejs';
import 'vuetify/dist/vuetify.min.css';

export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformexportfileconfig'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },
        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },
    components: {
        Sortable,
    },

    data: () => ({
        hover: false,
        orderhover: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        snack: false,
        snackColor: '',
        snackText: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        pagination: {},
        headers: [
            { text: 'Spaltenreihenfolge', value: 'exportColumnOrder' },
            { text: 'Spaltenüberschrift', value: 'exportColumnTitel' },
            { text: 'Type', value: 'exportColumnType' },
            { text: 'Wert von', value: 'artikelColumnName' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'LastAction' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],

        table: null,
        enableSubmit: false,
        name: '',
        filename: '',
        platformexportfilesendconfig_id: 0,
        artikelcolumns: {},
        columntypesoptions: ['DATUM', 'TEXT', 'WAEHRUNG'],
        exportfileconfigs: [{
            id: 0,
            pexportfilesendconfig_id: '',
            exportColumnOrder: 1,
            exportColumnTitel: null,
            exportColumnType: null,
            artikelColumnNr: null,
            artikelColumnName: null,
            description: null,
            LastAction: 1
        }],
        editedItem: {
            id: 0,
            pexportfilesendconfig_id: '',
            exportColumnOrder: 1,
            exportColumnTitel: null,
            exportColumnType: 'TEXT',
            artikelColumnNr: null,
            artikelColumnName: null,
            description: null,
            LastAction: 1
        },
        defaultItem: {
            id: 0,
            pexportfilesendconfig_id: '',
            exportColumnOrder: 1,
            exportColumnTitel: null,
            exportColumnType: 'TEXT',
            artikelColumnNr: null,
            artikelColumnName: null,
            description: null,
            LastAction: 1
        },
    }),

    created() {
        this.exportfileconfigs = [];
        this.name = this.editdata.name;
        this.filename = this.editdata.targetfilename;
        this.platformexportfilesendconfig_id = this.editdata.id;
        this.editedItem.pexportfilesendconfig_id = this.editdata.id;
        this.defaultItem.pexportfilesendconfig_id = this.editdata.id;
        this.getArtikelColumns();
        this.getPlatformExportfileConfigs();
    },

    methods: {
        saveOrder(event) {
            const movedItem = this.exportfileconfigs.splice(event.oldIndex, 1)[0];
            this.exportfileconfigs.splice(event.newIndex, 0, movedItem);
            for (let i = 0; i < this.exportfileconfigs.length; i++) {
                this.exportfileconfigs[i].exportColumnOrder = i + 1;
            }
        },
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/getplatformexportfilesendconfigs/" + this.editdata.platform.id;
        },
        getPlatformExportfileConfigs: function () {
            axios.get('/admin/getplatformexportfileconfigs/' + this.platformexportfilesendconfig_id)
                .then(function (response) {
                    this.exportfileconfigs = response.data;
                }.bind(this));
        },
        getArtikelColumns: function () {
            axios.get('/admin/getartikelcolumns')
                .then(function (response) {
                    this.artikelcolumns = response.data;
                }.bind(this));
        },
        addNewRow() {
            this.exportfileconfigs.push({
                id: 0,
                pexportfilesendconfig_id: this.platformexportfilesendconfig_id,
                exportColumnOrder: this.exportfileconfigs.length + 1,
                exportColumnTitel: null,
                exportColumnType: 'TEXT',
                artikelColumnNr: null,
                artikelColumnName: null,
                description: null,
                LastAction: 1
            });
        },
        deleteRow(index, exportfileconfig) {
            var idx = this.exportfileconfigs.indexOf(exportfileconfig);
            console.log(idx, index);
            if (idx > -1) {
                this.exportfileconfigs.splice(idx, 1);
                if (exportfileconfig.id > 0) {
                    axios.delete('/admin/platformexportfileconfig/' + exportfileconfig.id
                    ).then(response => {
                        console.log(response);
                        this.showMsgBoxOne(response, false);
                    }).catch(response => {
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                }
            }
        },

        getText(value) {
            let obj = this.artikelcolumns.find(o => o.value === value);
            //console.log('isim: '+ obj);
        },
        submit() {
            const _this = this;
            var isnotvalidated = false;
            for (let i = 0; i < this.exportfileconfigs.length; i++) {
                console.log('Titel: ' + this.exportfileconfigs[i].exportColumnTitel)
                if (this.exportfileconfigs[i].exportColumnTitel === null || this.exportfileconfigs[i].artikelColumnName === null) {
                    console.error('Fehlt');
                    isnotvalidated = true;
                    break;
                }
            }
            if (isnotvalidated) {
                this.showError("Pflichtfelder ausfühlen!");
                return;
            }
            axios.post(this.url,
                this.exportfileconfigs,
            ).then(response => {
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
                console.log(response);
            }).finally(response => {
                _this.finally(response);
            });
        },
        showMsgBoxOne(callback, change) {
            if (callback.data.data.code > 200) {
                this.$bvModal.msgBoxOk(callback.data.data.message, {
                    title: 'FEHLER',
                    headerBgVariant: 'danger',
                    headerTextVariant: 'light',
                    titleVariant: 'danger',
                    //bodyBgVariant: 'warning',
                    bodyTextVariant: 'danger',
                    size: 'lg',
                    okVariant: 'warning'
                });
                return;
            }
            //this.$bvModal.msgBoxOk(callback.data)
            this.$bvModal.msgBoxOk(callback.data.data.message, {
                title: 'MELDUNG',
                headerBgVariant: 'success',
                headerTextVariant: 'light',
                titleVariant: 'success',
                //bodyBgVariant: 'warning',
                bodyTextVariant: 'success',
                size: 'lg',
                okVariant: 'success'
            })
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + "/admin/showplatformexportfileconfigs/" + this.platformexportfilesendconfig_id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        showError(msg) {
            this.$bvModal.msgBoxOk(msg, {
                title: 'FEHLER',
                headerBgVariant: 'danger',
                headerTextVariant: 'light',
                titleVariant: 'danger',
                //bodyBgVariant: 'warning',
                bodyTextVariant: 'danger',
                size: 'lg',
                okVariant: 'warning'
            });

        },
        save(item) {
            var _this = this;
            axios.post(this.url,
                [item],
            ).then(response => {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Daten wurden gesichert'
                this.getPlatformExportfileConfigs();
            }).catch(response => {
                _this.catch(response);
                console.log(response);
            }).finally(response => {
                _this.finally(response);
            });
        },
        cancel() {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Abgebrochen'
        },
        open() {
            this.snack = true
            this.snackColor = 'info'
            this.snackText = 'Dialog wurde angezeigt'
        },
        close() {
            //console.log('Dialog wurde geschlossen')
        },

        //edit 
        editItem(item) {
            this.editedIndex = this.exportfileconfigs.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.exportfileconfigs.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            const _this = this;
            axios.delete('/admin/platformexportfileconfig/' + this.editedItem.id
            ).then(response => {
                console.log(response);
                this.showMsgBoxOne(response, false);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
            this.exportfileconfigs.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        closeNew() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        saveNew() {
            if (this.editedIndex > -1) {
                this.save(this.editedItem)
                Object.assign(this.exportfileconfigs[this.editedIndex], this.editedItem)
            } else {
                this.editedItem.exportColumnOrder = this.exportfileconfigs.length + 1;
                if (this.editedItem.exportColumnTitel == null || this.editedItem.exportColumnType == null || this.editedItem.artikelColumnName == null) {

                    return;
                }
                this.save(this.editedItem)
                this.exportfileconfigs.push(this.editedItem)
            }
            this.closeNew()
        },

    },
    directives: {
        sortableDataTable: {
            bind(el, binding, vnode) {
                const options = {
                    animation: 150,
                    onUpdate: function (event) {
                        vnode.child.$emit('sorted', event)
                    }
                }
                Sortable.create(el.getElementsByTagName('tbody')[0], options)
            }
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Neue Spalte hinzufügen' : 'Spalte bearbeiten'
        },
    },
};
</script>
