<template>
    <div class="row">
        
        <b-modal id="modal-warengruppe" :header-bg-variant="headerBgVariant" :header-text-variant="headerTextVariant"
            ref="modal" :title="modalname" @show="resetModal" @hidden="resetModal" @ok="handleOk" ok-title-html="Speichern"
            cancel-title="Abbrechen" :ok-disabled="disableOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Warengruppe" label-for="wg-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Warengruppe ist pflicht">
                    <b-form-input id="wg-input" v-model="warengruppe.number" type="number" min="0" value="0" step="1"
                        :state="numberState" required></b-form-input>
                </b-form-group>
                <b-form-group label="Bezeichnung" label-for="name-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Bezeichnung ist pflicht">
                    <b-form-input id="name-input" v-model="warengruppe.name" :state="nameState" required></b-form-input>
                </b-form-group>
                <b-form-group label="Typ" label-for="type-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Typ ist pflicht">
                    <b-form-select id="type-input" v-model="warengruppe.type" :options="typeoptions" value-field="name"
                        text-field="name" disabled-field="notEnabled" :state="typeState" required></b-form-select>
                </b-form-group>
                <b-form-group label="Beschreibung" label-for="description-input" label-cols="8" label-cols-lg="4">
                    <b-form-input id="description-input" v-model="warengruppe.description"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped warengruppen-table" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Warengruppe</th>
                        <th>Bezeichnung</th>
                        <th>Typ</th>
                        <th>Beschreibung</th>
                        <th>Erstellt am</th>
                        <th>Aktualisiert am</th>
                        <th>Status</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {

    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/warengruppen'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        modalname: "Neue Warengruppe hinzufügen",
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        disableOk: false,
        enableSubmit: false,
        warengruppe: {
            id: 0,
            number: null,
            name: null,
            type: 'Reifen',
            description: null,
        },
        typeoptions: [],
    }),

    created() {
        this.getShippingPackagingTypes();
        this.getWarengruppen('/admin/getwarengruppen');
    },
    mounted() {
        window.warengruppe = this;
    },
    methods: {
        getShippingPackagingTypes: function () {
            axios.get('/admin/getshippingpackagingtypes')
                .then(function (response) {
                    this.typeoptions = response.data;
                }.bind(this));
        },
        async getWarengruppen(address) {
            $(document).ready(function () {
                var table = $('.warengruppen-table').DataTable({
                    processing: true,
                    serverSide: true,
                    searching: true,
                    searchable: true,
                    responsive: true,
                    select: true,
                    pageLength: 50,
                    orderable: true,
                    language: {
                        url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                    },
                    ajax: address,
                    columns: [
                        { data: 'id', name: 'id' },
                        { data: 'number', name: 'number' },
                        { data: 'name', name: 'name' },
                        { data: 'type', name: 'type' },
                        { data: 'description', name: 'description' },
                        { data: 'created_at', name: 'created_at' },
                        { data: 'updated_at', name: 'updated_at' },
                        { data: 'status', name: 'status' },
                        { data: 'action', name: 'action' },
                    ],
                    order: [[4, 'asc']],
                });
            });
        },
        getEditWarengruppe(edit_id) {
            console.log("id: " + edit_id);
            axios.get('/admin/warengruppen/' + edit_id + '/edit')
                .then(function (response) {
                    this.$bvModal.show('modal-warengruppe');
                    this.modalname = "Warengruppe bearbeiten"
                    this.warengruppe = response.data;
                    this.headerBgVariant = 'primary';
                    console.log(this.warengruppe);
                }.bind(this));
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        $('.warengruppen-table').DataTable().ajax.reload(null, false);
                        // window.location = location.protocol + "//" + location.hostname + "/admin/warengruppen/getwarengruppe/" + this.editdata.id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        resetModal() {
            this.headerBgVariant = 'success';
            this.modalname = "Neue Warengruppe hinzufügen";
            this.warengruppe.id = 0;
            this.warengruppe.number = null;
            this.warengruppe.name = null;
            this.warengruppe.type = null;
            this.warengruppe.description = null;
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            const _this = this;
            if (this.warengruppe.id > 0) {
                axios.put(this.url + '/' + this.warengruppe.id,
                    this.warengruppe,
                ).then(response => {
                    // _this.callback(response);
                    console.log(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.warengruppe,
                ).then(response => {
                    // _this.callback(response);
                    console.log(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }

            // Hide the modal manually
            this.$nextTick(() => {
                this.resetModal();
                this.$bvModal.hide('modal-warengruppe')
            })
        },

        checkFormValidity() {
            if (this.warengruppe.number == null || this.warengruppe.number == "" || this.warengruppe.name == null || this.warengruppe.name == ""
                || this.warengruppe.type == null || this.warengruppe.type == "") {
                this.disableOk = true;
            } else {
                this.disableOk = false;
            }
        }
    },
    watch: {

    },
    computed: {
        numberState() {
            this.checkFormValidity();
            if (this.warengruppe.number == null || this.warengruppe.number == "") {
                return false;
            }
            return true;
        },
        nameState() {
            this.checkFormValidity();
            if (this.warengruppe.name == null || this.warengruppe.name == "") {
                return false;
            }

            return true;
        },
        typeState() {
            this.checkFormValidity();
            return (this.warengruppe.type == null || this.warengruppe.type == "") ? false : true;
        }
    },
};
</script>
