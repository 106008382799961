<template>
    <v-app>
        <v-data-table :headers="headers" :items="platformsupplierresalepriceconfigs" :items-per-page="itemsPerPage"
            class="platformsupplierresalepriceconfigs-table" :server-items-length="totalplatformsupplierresalepriceconfigs" :loading="loading"
            :options.sync="options" locale="de" :footer-props="footerProps" multi-sort>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Konfiguration der Lieferantenwiederverkaufspreise der Plattformvorlage {{ editdata.name
                    }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="editdata.name" label="Plattform" disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select v-model="editedItem.suppliertemplate_id" :items="supplierstocktemplates"
                                                label="Lieferantenvorlage" item-text="templateName" item-value="id"></v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch v-model="editedItem.lastAction"
                                                :label="`${editedItem.lastAction > 0 ? 'aktiviert' : 'deaktiviert'}`"
                                                color="green"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="600px">
                        <v-card>
                            <v-app-bar dark color="warning">
                                <v-card-title>
                                    <span class="text-h5" color="red">Warnung</span>
                                </v-card-title>
                            </v-app-bar>

                            <v-card-text class="text-h6">Sind Sie sicher, dass Sie diesen Wiederverkaufspreis des Lieferantes
                                {{ editdata.name }} löschen möchten?</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" text @click="closeDelete">Nein</v-btn>
                                <v-btn color="red darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>

            <template v-slot:header.suppliertstocktemplatename="{ header }">
                {{ header.text }}
                <v-menu v-model="suppliertstocktemplatenamefilter" offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small :color="suppliertstocktemplatename ? 'primary' : ''">
                                mdi-filter
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="background-color: white; width: 280px">
                        <v-text-field v-model="suppliertstocktemplatename" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                            :autofocus="true"></v-text-field>
                        <v-btn text @click="suppliertstocktemplatenamefilter = false">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                        <v-btn @click="suppliertstocktemplatename = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                            <v-icon dark>
                                mdi-filter-off
                            </v-icon>
                        </v-btn>
                        <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                            <v-icon dark>
                                mdi-database-search
                            </v-icon>
                        </v-btn>
                    </div>
                </v-menu>
            </template>
            <template v-slot:header.description="{ header }">
                    {{ header.text }}
                    <v-menu v-model="descriptionfilter" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="description ? 'primary' : ''">
                                    mdi-filter
                                </v-icon>
                            </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                            <v-text-field v-model="description" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                                :autofocus="true"></v-text-field>
                            <v-btn text @click="descriptionfilter = false">
                                <v-icon dark>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <v-btn @click="description = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                <v-icon dark>
                                    mdi-filter-off
                                </v-icon>
                            </v-btn>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                    mdi-database-search
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-menu>
                </template>
            <template v-slot:item.lastAction="{ item }">
                <v-switch v-model="item.lastAction" color="green" disabled label=""></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformsupplierresaleconfigs'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        disableOk: false,
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogWarning: false,
        supplierstocktemplates: [],
        platformtemplates: [],
        platformsupplierresalepriceconfigs: [],
        totalplatformsupplierresalepriceconfigs: 0,
        itemsPerPage: 50,
        loading: true,
        conditions: [],
        filters: [],
        options: {
            page: 1,
            itemsPerPage: 50,
            // sortBy: 'DESC',
            sortDesc: [false],
            mustSort: false,
            multiSort: false,
            sort: '',
            filters: null
        },
        suppliertstocktemplatename: '',
        suppliertstocktemplatenamefilter: false,
        description: '',
        descriptionfilter: false,
        editedItem: {
            id: 0,
            platformtemplate_id: null,
            suppliertemplate_id: null,
            platformtemplatename: null,
            suppliertstocktemplatename: null,
            description: null,
            lastAction: 1,
        },
        defaultItem: {
           platformtemplate_id: null,
            suppliertemplate_id: null,
            platformtemplatename: null,
            suppliertstocktemplatename: null,
            description: null,
            lastAction: 1,
        },
        headers: [
            { text: 'id', value: 'id' },
            { text: 'Lieferantenvorlage-Id', value: 'suppliertemplate_id' },
            { text: 'Lieferantenvorlage', value: 'suppliertstocktemplatename' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'lastAction' },
            { text: 'Erstellt am', value: 'created_at' },
            { text: 'Aktualisiert am', value: 'updated_at' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        footerProps: {
            'items-per-page-options': [50, 500, 1000, -1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
        }
    }),
    created() {
        this.options.platformtemplate_id = this.editdata.id;
        this.editedItem.platformtemplate_id = this.editdata.id;
        this.defaultItem.platformtemplate_id = this.editdata.id;
        this.editedItem.platformtemplatename = this.editdata.name;
        this.defaultItem.platformtemplatename = this.editdata.name;
        
        this.filters = new Array();
        this.getSupplierStockTemplates();
    },
    mounted() {
        window.platformsupplierresalepriceconfigs = this;
    },
    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/platforms/"+ this.editdata.platforms_id+"/platformtemplates";
        },
        async clearFilter() {
            this.filter();
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },
        editItem(item) {
            this.editedItem = item;
            this.dialog = true
        },

        deleteItem(item) {
            this.editedItem = item;
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            axios.delete(this.url + '/' + this.editedItem.id)
                .then(response => {
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },
        getSupplierStockTemplates: function () {
            axios.get('/admin/getallsupplierstocktemplates')
                .then(function (response) {
                    this.supplierstocktemplates = response.data;
                }.bind(this));
        },
        getSupplierStockTemplateName() {
            const _this = this;
            var result = _.find(_this.supplierstocktemplates, function (item) { return item.id === _this.editedItem.suppliertemplate_id; });
            console.log(result);
            console.log(result.templateName);
            return result.templateName;
        },
        async getPlatformSupplierPriceConfigs() {
            this.loading = true
            this.options.sort = this.options.sortBy[0];
            axios.get('/admin/getplatformsupplierresalepriceconfigs/', { params: this.options })
                .then(function (response) {
                    this.platformsupplierresalepriceconfigs = response.data.data;
                    this.totalplatformsupplierresalepriceconfigs = response.data.total
                    this.itemsPerPage = response.data.per_page
                    this.loading = false
                }.bind(this));
        },
        getColor(value) {
            if (value > 0) return "red"
            else return "green"
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getPlatformSupplierPriceConfigs();
                        //window.location = location.protocol + "//" + location.hostname + '/admin/platforms/'+this.editdata.id+'/platformsupplierresalepriceconfigs';
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            //this.nameState = valid
            return valid
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        filter() {
            this.filters.length = 0;
            if (this.suppliertstocktemplatename) {
                this.filters.push(new Array('suppliertstocktemplatename', 'LIKE', '%'+this.suppliertstocktemplatename+'%'));
            }
            if (this.description) {
                this.filters.push(new Array('description', 'LIKE', '%' + this.description + '%'));
            }
            this.options.filters = this.filters;
            this.getPlatformSupplierPriceConfigs();
        },

        save() {
            const _this = this;
            _this.editedItem.suppliertstocktemplatename = _this.getSupplierStockTemplateName();
            console.log(_this.editedItem);
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }

            // Hide the modal manually
            this.$nextTick(() => {
                // this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        options(newValue, oldValue) {
            let cont = false
            if (oldValue.page !== newValue.page) cont = true
            if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
            if (oldValue.sortBy !== newValue.sortBy) cont = true
            if (oldValue.sortDesc !== newValue.sortDesc) cont = true
            if (cont) {
                this.getPlatformSupplierPriceConfigs();
            }
        },
    },
    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Lieferantenwiederverkaufspreis hinzufügen' : 'Lieferantenwiederverkaufspreis bearbeiten'
        },
    },
};
</script>
