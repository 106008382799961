var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-row',[_c('v-data-table',{staticClass:"ownofferexceededs-table",attrs:{"headers":_vm.headers,"items":_vm.ownofferexceededs,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalOwnofferexceededs,"loading":_vm.loading,"options":_vm.options,"locale":"de","footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.ARANR",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.aranr ? 'primary' : ''}},[_vm._v("\n                        mdi-filter\n                    ")])],1)]}}],null,true),model:{value:(_vm.aranrfilter),callback:function ($$v) {_vm.aranrfilter=$$v},expression:"aranrfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.aranr),callback:function ($$v) {_vm.aranr=$$v},expression:"aranr"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.aranrfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-close\n                    ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.aranr = '',_vm.filter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-filter-off\n                    ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-database-search\n                    ")])],1)],1)])]}},{key:"header.ARWG",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arwg ? 'primary' : ''}},[_vm._v("\n                        mdi-filter\n                    ")])],1)]}}],null,true),model:{value:(_vm.arwgfilter),callback:function ($$v) {_vm.arwgfilter=$$v},expression:"arwgfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arwg),callback:function ($$v) {_vm.arwg=$$v},expression:"arwg"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arwgfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-close\n                    ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arwg = '',_vm.filter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-filter-off\n                    ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-database-search\n                    ")])],1)],1)])]}},{key:"header.AREAN",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arean ? 'primary' : ''}},[_vm._v("\n                        mdi-filter\n                    ")])],1)]}}],null,true),model:{value:(_vm.areanfilter),callback:function ($$v) {_vm.areanfilter=$$v},expression:"areanfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arean),callback:function ($$v) {_vm.arean=$$v},expression:"arean"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.areanfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-close\n                    ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arean = '',_vm.filter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-filter-off\n                    ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-database-search\n                    ")])],1)],1)])]}},{key:"header.ARHE",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arhe ? 'primary' : ''}},[_vm._v("\n                        mdi-filter\n                    ")])],1)]}}],null,true),model:{value:(_vm.arhefilter),callback:function ($$v) {_vm.arhefilter=$$v},expression:"arhefilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arhe),callback:function ($$v) {_vm.arhe=$$v},expression:"arhe"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arhefilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-close\n                    ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arhe = '',_vm.filter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-filter-off\n                    ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-database-search\n                    ")])],1)],1)])]}},{key:"header.ARAINR",fn:function(ref){
var header = ref.header;
return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arainr ? 'primary' : ''}},[_vm._v("\n                        mdi-filter\n                    ")])],1)]}}],null,true),model:{value:(_vm.arainrfilter),callback:function ($$v) {_vm.arainrfilter=$$v},expression:"arainrfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},model:{value:(_vm.arainr),callback:function ($$v) {_vm.arainr=$$v},expression:"arainr"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arainrfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-close\n                    ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arainr = '',_vm.filter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-filter-off\n                    ")])],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                    mdi-database-search\n                    ")])],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }