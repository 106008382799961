<template>
    <v-app>
        <v-data-table :headers="headers" :items="ownofferrandomstocks" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Zufallsbestände</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-form v-model="isFormValid">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.stock"
                                             type="number"
                                             min="1"
                                             step="1"
                                             label="Bestand"
                                             :rules="[requiredRule, numericRule, numberRule]"
                                             >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.random_number_from" 
                                            type="number"
                                            min="1"
                                            step="1"
                                            label="Zufallszahl von"
                                            :rules="[requiredRule, numericRule, numberRule]"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.random_number_to" 
                                            type="number"
                                            min="1"
                                            step="1"
                                            :rules="[requiredRule, numericRule, numberRule]"
                                            label="Zufallszahl bis"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch v-model="editedItem.lastAction"
                                                :label="`Zufallszahl ${editedItem.lastAction > 0 ? 'aktiviert' : 'deaktiviert'}`"
                                                color="green"></v-switch>
                                        </v-col>
                                    </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn :disabled="!isFormValid" color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Zufallszahl löschen
                                möchten?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.lastAction="{ item }">
                <v-switch v-model="item.lastAction" color="green" disabled label=""></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/ownofferrandomstocks'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: () => ({
        isFormValid:false,
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        numberRule: val => {
            if (val < 0) return 'Bitte geben Sie eine positive Zahl ein!'
            return true
        },

        headers: [
            { text: 'Id', align: 'start', sortable: true, value: 'id'},
            { text: 'Bestand', value: 'stock', sortable: true },
            { text: 'Zufallszahl von', value: 'random_number_from' },
            { text: 'Zufallszahl bis', value: 'random_number_to' },
            { text: 'Status', value: 'lastAction' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,
        ownofferrandomstocks: [],
        editedItem: {
            id: 0,
            stock: null,
            random_number_from: null,
            random_number_to: null,
            lastAction: 1,
        },
        defaultItem: {
            id: 0,
            stock: null,
            random_number_from: null,
            random_number_to: null,
            lastAction: 1,
        },
    }),

    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Zufallszahlen hinzufügen' : 'Zufallszahlen bearbeiten'
        },
        requiredRule() {
            return value => !!value || 'Pflichtfeld!'
        },
        numericRule() {
            return value => /^\d+$/.test(value) || 'Die Eingabe muss eine positive Zahl sein!'
        }
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.getOwnOfferRandomStocks();
    },

    methods: {
        async getOwnOfferRandomStocks() {
            axios.get('/admin/getownofferrandomstocks')
                .then(function (response) {
                    this.ownofferrandomstocks = response.data;
                }.bind(this));
        },

        editItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialog = true
                }.bind(this));
        },

        deleteItem(item) {
            var _this = this;
            axios.get(_this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    _this.editedItem = response.data;
                    _this.dialogDelete = true
                }.bind(_this));
        },

        deleteItemConfirm() {
            var _this = this;
            axios.delete(_this.url + '/' + _this.editedItem.id)
                .then(response => {
                    _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete()
        },

        close() {
            const _this = this;
            _this.dialog = false
            _this.$nextTick(() => {
                _this.editedItem = _this.defaultItem;
            })
        },

        closeDelete() {
            const _this = this;
            _this.dialogDelete = false
            _this.$nextTick(() => {
                _this.editedItem = _this.defaultItem;
            })
        },

        save() {
            const _this = this;
            if (this.editedItem.id > 0) {
                axios.put(_this.url + '/' + _this.editedItem.id,
                    _this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(_this.url,
                    _this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            // Hide the modal manually
            _this.$nextTick(() => {
                // this.resetModal();
                _this.$bvModal.hide('modal-prevent-closing')
            })
            _this.close()
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        //$('.suppliers-table').DataTable().ajax.reload();
                        this.getOwnOfferRandomStocks();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
    },
}
</script>